<template>
  <div class="body">

    <el-tabs v-model="activeName" @tab-click="handletab" class="tabscontent" :before-leave="changeHeader">
      <el-tab-pane label="单件换装" name="first">
        <div class="selectdiv">
          <div>
            <span style="color: red">*</span>
            <span>请选择着装部位</span>
          </div>
          <div class="buweidiv" :class="{'containDisable': koutuLoading}">
            <div class="buwei" :class="{'divDisable': koutuLoading}" @click="handlebanxing('连衣裙')" @mouseenter="enterItem('连衣裙')" @mouseleave="leaveItem" >
              <div class="buweiborder" :class="{'buweiselected' : unitType === '连衣裙' || unitType1 === '连衣裙'}">
                <div style="text-align: center">连衣裙</div>
                <div class="buweiimg">
                  <img v-if="unitType === '连衣裙' || unitType1 === '连衣裙'" src="@/assets/lyq2.png" />
                  <img v-else src="@/assets/lyq1.png" />
                </div>
              </div>
            </div>
            <div class="buwei" :class="{'divDisable': koutuLoading}" @click="handlebanxing('上装')" @mouseenter="enterItem('上装')" @mouseleave="leaveItem">
              <div class="buweiborder" :class="{'buweiselected' : unitType === '上装'|| unitType1 === '上装'}">
                <div style="text-align: center">上装</div>
                <div class="buweiimg">
                  <img v-if="unitType === '上装' || unitType1 === '上装'" src="@/assets/sz2.png" />
                  <img v-else src="@/assets/sz1.png" />
                </div>
              </div>
            </div>
            <div class="buwei" :class="{'divDisable': koutuLoading}" @click="handlebanxing('下装')" @mouseenter="enterItem('下装')" @mouseleave="leaveItem">
              <div class="buweiborder" :class="{'buweiselected' : unitType === '下装'|| unitType1 === '下装'}">
                <div style="text-align: center">下装</div>
                <div class="buweiimg">
                  <img v-if="unitType === '下装' || unitType1 === '下装'" src="@/assets/xz2.png" />
                  <img v-else src="@/assets/xz1.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <CustomerUpload
          name="0"
          text="上传平铺图"
          demand="要求:上传服装正面平铺图，平整无遮挡、无折叠"
          ref="uploadRef0"
          :actionUrl="actUrl"
          :username="username"
          :originalUrl="originalUrl1"
          :cutdownUrl="cutdownUrl1"
          :unitType="unitType"
          :paidui="paidui"
          @fileSuccess="chengong"
          @uploading="uploading"
        />
        <!-- 选择模特 -->
        <el-popover placement="right" popper-class="popverdiv" width="726" trigger="manual" v-model="imgMoreVisible1">
          <AIMoteMore v-if="imgMoreVisible1" @close="handleCloseMore" @childSelected="childSelected"
            :imgList="imgList1" :title="moreTitle" @selectClothesType="selectClothesType" @scrollLoad="scrollLoad"
          />
          <div class="selectmodelclass1" slot="reference" @click="openbtn">
            <div class="iconcontain" :style="{ backgroundColor: moteurl1 === '' ? '#efefef' : 'transparent' }">
              <img v-if="moteurl1 == ''" src="@/assets/iconbg.png" />
              <img v-else :src="moteurl1" style="height: auto; width: 100%; border-radius: 5px" />
            </div>
            <div class="xuanzetext">
              <span class="xzmt">选择模特</span>
              <i class="el-icon-arrow-right" style="font-size: 22px; float: right" />
            </div>
          </div>
        </el-popover>
        <el-upload
          class="upload-demo"
          :action="urlAction3"
          :data="{ username: username }"
          :show-file-list="false"
          :before-upload="beforeUpload"
          :on-success="chengong1"
        >
          <div class="selectmodelclass2" @click="handleCloseMore">
            <div class="iconcontain" :style="{ backgroundColor: moteurl2 === '' ? '#efefef' : 'transparent' }">
              <img v-if="moteurl2 == ''" src="@/assets/iconbg.png" />
              <img v-else :src="moteurl2" style="height: auto; width: 100%; border-radius: 5px" />
            </div>
            <div class="uploadmotetext">
              <span class="xzmt">上传自己的模特</span>
              <i class="el-icon-arrow-right" style="font-size: 22px; float: right" />
            </div>
          </div>
        </el-upload>
      </el-tab-pane>
      <el-tab-pane label="多件换装" name="second">
        <CustomerUpload
          name="1"
          text="上传上装平铺图"
          demand="要求:上传服装正面平铺图，平整无遮挡、无折叠"
          ref="uploadRef1"
          :actionUrl="actUrl"
          :username="username"
          :originalUrl="originalUrl2"
          :cutdownUrl="cutdownUrl2"
          unitType="上装"
          @fileSuccess="chengong"
        />
        <CustomerUpload
          name="2"
          text="上传下装平铺图"
          demand="要求:上传服装正面平铺图，平整无遮挡、无折叠"
          ref="uploadRef2"
          :actionUrl="actUrl"
          :username="username"
          :originalUrl="originalUrl3"
          :cutdownUrl="cutdownUrl3"
          unitType="下装"
          @fileSuccess="chengong"
        />
        <!-- 选择模特 -->
        <el-popover placement="right" popper-class="popverdiv" width="724" trigger="manual" v-model="imgMoreVisible2">
          <AIMoteMore v-if="imgMoreVisible2" @close="handleCloseMore" @childSelected="childSelected"
            :imgList="imgList1" :title="moreTitle" @selectClothesType="selectClothesType" @scrollLoad="scrollLoad"
          />
          <!-- <div style="text-align: center">
            <el-button type="primary" @click="loadingdata1()">显示更多</el-button>
          </div> -->
          <div class="selectmodelclass1" slot="reference" @click="openbtn">
            <div class="iconcontain" :style="{ backgroundColor: moteurl3 === '' ? '#efefef' : 'transparent' }">
              <img v-if="moteurl3 == ''" src="@/assets/iconbg.png" />
              <img v-else :src="moteurl3" style="height: auto; width: 100%; border-radius: 5px" />
            </div>
            <div class="xuanzetext">
              <span class="xzmt">选择模特</span>
              <i class="el-icon-arrow-right" style="font-size: 22px; float: right" />
            </div>
          </div>
        </el-popover>
        <el-upload
          class="upload-demo"
          :action="urlAction3"
          :data="{ username: username }"
          :show-file-list="false"
          :before-upload="beforeUpload"
          :on-success="chengong1"
        >
          <div class="selectmodelclass2" @click="handleCloseMore">
            <div class="iconcontain" :style="{ backgroundColor: moteurl4 === '' ? '#efefef' : 'transparent' }">
              <img v-if="moteurl4 == ''" src="@/assets/iconbg.png" />
              <img v-else :src="moteurl4" style="height: auto; width: 100%; border-radius: 5px" />
            </div>
            <div class="uploadmotetext">
              <span class="xzmt">上传自己的模特</span>
              <i class="el-icon-arrow-right" style="font-size: 22px; float: right" />
            </div>
          </div>
        </el-upload>
      </el-tab-pane>
    </el-tabs>

    <!-- 立即生成 -->
    <template>
      <div class="generate-footer">
        <div class="xiaohaodiv">
          <span>{{ $t('xiaohao') }}:10</span>
          <img src="@/assets/jinbi.png" class="jinbi_icon" />
        </div>
        <el-button slot="reference" class="generatebtn" type="primary" @click="handleHuanyi">{{ $t('lijishengchen') }}</el-button>
      </div>
    </template>
  </div>
</template>

<script>
import apiClient from '@/services/api';
import CustomerUpload from './CustomerUpload.vue';
import MoteMore from './MoteMore.vue';
import AIMoteMore from './AIMoteMore.vue';

export default {
  props: ['historyBack','paidui'],
  components: { CustomerUpload, MoteMore, AIMoteMore },
  data() {
    return {
      clothesType: '全部',
      imgList1: [[]],
      moreTitle: {
        bigTitle: '',
        remark: '',
        name: []
      },
      imgMoreVisible1: false,
      imgMoreVisible2: false,
      models: [],
      page: 1,
      limit: 30,
      loading: false,
      cutdownUrl3: '',
      originalUrl3: '',
      cutdownUrl2: '',
      originalUrl2: '',
      cutdownUrl1: '',  //原图1
      originalUrl1: '', //保留区图1
      moteurl3: '',   //多件换装
      moteurl4: this.historyBack.models2 || '',
      moteurl1: '',   //单件换装
      moteurl2: this.historyBack.models2 || '',
      urlAction3: window._CONFIG['domianURL']+'upload3',
      username: localStorage.username,
      activeName: 'first',
      unitType: '',
      clothesOptions: [
        { label: "上装", value: "上装"},
        { label: "下装", value: "下装"},
        { label: "连体衣", value: "连体衣"},
      ],
      garea: [
        {
          show: this.$t('yazhou'),
          zh: "亚洲",
          en: "Asia",
        },
        {
          show: this.$t('ouzhou'),
          zh: "欧洲",
          en: "Europe",
        },
        {
          show: this.$t('feizhou'),
          zh: "非洲",
          en: "Africa",
        },
        {
          show: this.$t('meizhou'),
          zh: "美洲",
          en: "America",
        },
        {
          show: this.$t('dayangzhou'),
          zh: "大洋洲",
          en: "Oceania",
        },
      ],
      dialogVisible1: false,
      darea: '全部',
      dsex: '全部',
      actUrl:window._CONFIG['domianURL']+'upload',
      //单件换装原图临时存储
      original1Temp: {
        lyq: '',
        sz: '',
        xz: '',
      },
      //单件换装保留图临时存储
      cutdown1Temp: {
        lyq: '',
        sz: '',
        xz: '',
      },
      koutuLoading: false,
      unitType1:null,
    }
  },
  watch: {
    historyBack(val) {
      if (!val || Object.keys(val).length === 0) return;
      this.activeName = val.type ? (val.type.includes('单件') ? 'first' : 'second') : 'first';
      this.unitType = val.banxing?val.banxing:val.unitType?val.unitType:'';
      // 上传组件回显
      this.originalUrl1 = val.img1;
      this.cutdownUrl1 = val.img1k;
      this.originalUrl2 = val.img2;
      this.cutdownUrl2 = val.img2k;
      this.originalUrl3 = val.img3;
      this.cutdownUrl3 = val.img3k;
      // 模特组件回显
      if (this.activeName === 'first') {
        this.moteurl1 = val.posture || '';
        this.moteurl2 = val.models2 || '';
        this.moteurl3 = '';
        this.moteurl4 = '';
      }
      if (this.activeName === 'second') {
        this.moteurl1 = '';
        this.moteurl2 = '';
        this.moteurl3 = val.posture || '';
        this.moteurl4 = val.models2 || '';
      }

      if(val.type.includes('单件')){
        if (val.banxing === '连衣裙') {
          this.original1Temp = {
            lyq: val.img1,
            sz: '',
            xz: '',
          };
          this.cutdown1Temp = {
            lyq: val.img1k,
            sz: '',
            xz: '',
          };
        }
        if (val.banxing === '上装') {
          this.original1Temp = {
            lyq: '',
            sz: val.img1,
            xz: '',
          };
          this.cutdown1Temp = {
            lyq: '',
            sz: val.img1k,
            xz: '',
          };
        }
        if (val.banxing === '下装') {
          this.original1Temp = {
            lyq: '',
            sz: '',
            xz: val.img1,
          };
          this.cutdown1Temp = {
            lyq: '',
            sz: '',
            xz: val.img1k,
          };
        }
      }


    }
  },
  created() {

  },
  mounted() {
    let screenWidth = screen.width; // 获取屏幕的宽度
    let screenHeight = screen.height; // 获取屏幕的高度
    console.log('Screen Width: ' + screenWidth + ', Screen Height: ' + screenHeight);

    this.loadingdata1();
  },
  methods: {
    enterItem(val) {
      this.unitType1 = val;
    },
    leaveItem() {
      this.unitType1 = null;
    },
    handlebanxing(val) {
      // if(this.paidui){
      //   this.$message.warning('排队中~请等待2秒再继续操作');
      //   return  false;
      // }

      this.unitType = val;
      if (val === '连衣裙') {
        this.originalUrl1 = this.original1Temp.lyq;
        this.cutdownUrl1 = this.cutdown1Temp.lyq;
      }
      if (val === '上装') {
        this.originalUrl1 = this.original1Temp.sz;
        this.cutdownUrl1 = this.cutdown1Temp.sz;
      }
      if (val === '下装') {
        this.originalUrl1 = this.original1Temp.xz;
        this.cutdownUrl1 = this.cutdown1Temp.xz;
      }
      this.$refs.uploadRef0.triggerUpload();
    },
    scrollLoad() {
      this.loadingdata1();
    },
    handleHuanyi() {
      if (this.activeName === 'first') {
        this.huanyi1();
      }
      if (this.activeName === 'second') {
        this.huanyi();
      }
    },
    handletab(tab, event) {
      if(this.paidui){
        return  false;
      }
      this.handleCloseMore();
      this.activeName = tab.name
    },
    changeHeader(){
      var p = new Promise((resolve, reject) => {
        if(this.paidui){
          this.$message.warning('排队中~请等待2秒再继续操作');
          return reject();
        }
        resolve();
      });
      return p;
    },
    beforeUpload(file) {
      if(this.paidui){
        this.$message.warning('排队中~请等待2秒再继续操作');
        return  false;
      }
      const isJPGorPNG = file.type === 'image/jpeg' || file.type === 'image/png';
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isJPGorPNG) {
        this.$message.error('图片大小小于5MB，分辨率大于400*400，格式支持JPG、PNG  格式');
        return false;
      }

      if (!isLt5M) {
        this.$message.error('图片大小小于5MB，分辨率大于400*400，格式支持JPG、PNG  格式');
        return false;
      }

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => {
          const img = new Image();
          img.src = event.target.result;
          img.onload = () => {
            if (img.width >= 400 && img.height >= 400) {

              if(this.activeName === 'first'){
                this.moteurl2=img.src;
              }else{
                this.moteurl4=img.src;
              }

              resolve(true);
            } else {
              this.$message.error('图片大小小于5MB，分辨率大于400*400，格式支持JPG、PNG  格式');
              reject(false);
            }
          };
        };
      });
    },
    uploading(value) {
      this.koutuLoading = value;
    },
    chengong(epath, bpath, name) {
      // epath原图路径、bpath保留图路径
      if (name === '0') {
        this.originalUrl1 = epath;
        this.cutdownUrl1 = bpath;
        this.koutuLoading = false;
        if (this.unitType === '连衣裙') {
          this.original1Temp.lyq = epath;
          this.cutdown1Temp.lyq = bpath;
        }
        if (this.unitType === '上装') {
          this.original1Temp.sz = epath;
          this.cutdown1Temp.sz = bpath;
        }
        if (this.unitType === '下装') {
          this.original1Temp.xz = epath;
          this.cutdown1Temp.xz = bpath;
        }
      }
      if (name === '1') {
        this.originalUrl2 = epath;
        this.cutdownUrl2 = bpath;
      }
      if (name === '2') {
        this.originalUrl3 = epath;
        this.cutdownUrl3 = bpath;
      }
    },
    async chengong1(response) {
      var mypath1 = response.filePath
      var mypath = mypath1.replace('output', '')
      var epathF = window._CONFIG['domianURL'] + mypath

      const response3 = await apiClient.uploadImageToOSS(epathF, '/huanyi/mote/');
      var epath = response3.url+window._CONFIG['dialogThumbnail'];

      if (this.activeName === 'first') {
        this.moteurl2 = epath
        this.moteurl1 = '';
      }
      if (this.activeName === 'second') {
        this.moteurl4 = epath
        this.moteurl3 = '';
      }
    },
    openbtn() {
      if(this.paidui){
        this.$message.warning('排队中~请等待2秒再继续操作');
        return  false;
      }
      this.moreTitle = {
        bigTitle: this.$t('hailiangmoteku'),
        remark: '',
        name: [''],
        qvisible: true,
      }
      if (this.activeName === 'first') {
        this.imgMoreVisible1 = true;
      }
      if (this.activeName === 'second') {
        this.imgMoreVisible2 = true;
      }
    },
    childSelected(value) {
      if (this.activeName === 'first') {
        this.moteurl1 = value;
        this.moteurl2 = '';
      }
      if (this.activeName === 'second') {
        this.moteurl3 = value;
        this.moteurl4 = '';
      }
    },
    handleCloseMore() {
      this.selectClothesType('全部');
      this.imgMoreVisible1 = false;
      this.imgMoreVisible2 = false;
    },
    selectClothesType(value) {
      this.clothesType = value;
      this.page = 1;
      this.imgList1[0] = [];
      this.loadingdata1();
    },
    async loadingdata1() {
      const response = await apiClient.get(`/getmodels66`, {
        params: {
          page: this.page,
          limit: this.limit,
          continent: '',
          gender: '',
          leixing: '青年',
          dsex: '全部',
          darea: '全部',
          fushi: this.clothesType,
        },
        headers: {
          'x-access-token': localStorage.token
        },
      });
      const mylist = response.data.models;
      // this.imgList1[0] = []
      const tempArr = [];
      for (let i = 0; i < mylist.length; i++) {
        let item = mylist[i];
        let path = window._CONFIG['domianURL'];
        let contentpaht = item.image_path;
        let path1;
        if(contentpaht.indexOf('http')!=-1){
          path1 = contentpaht+window._CONFIG['dialogThumbnail'];
          item.image_path = contentpaht;
        } else {
          contentpaht = contentpaht.replace("./output", "");
          path1 = path + contentpaht;
          item.image_path = path1;
        }
        tempArr.push(path1);
      }
      let isDuplicate = tempArr.every(item => this.imgList1[0].includes(item));
      if (!isDuplicate) {
        this.$set(this.imgList1, 0, [...this.imgList1[0], ...tempArr]);
      }
      this.page += 1;
    },

    //转化缩略图
    convertThumbnail(arr,config){
      const thumbnailSuffix=window._CONFIG[config] || '';
      const newArr=arr.map(item=>{
        return item+ thumbnailSuffix
      })
      return newArr;
    },

    //单件生成
    async huanyi1() {
      if (this.unitType == '') {
        this.$message.error('请选择着装部位');
        return;
      }
      const moteurl = this.moteurl2 !== '' ? this.moteurl2 : this.moteurl1
      // 校验
      const childValue1 = this.$refs.uploadRef0.getChildshowOriginal();
      if (childValue1 == '') {
        this.$message.error('请上传服饰');
        return;
      }
      const childValue2 = this.$refs.uploadRef0.getChildCutdown();
      if (childValue2 == '') {
        this.$message.error('请等待抠图完成');
        return;
      }
      if (moteurl == '') {
        this.$message.error('请选择模特');
        return;
      }
      const obj = {
        type: 'danjian',
        img1: this.originalUrl1,
        models: moteurl,
      }
      const response = await apiClient.post(`/checkGenerating`, obj);
      if (response.data.data.length !== 0) {
        this.$message.warning('任务正在生成中，请稍后');
        return;
      }
      const value = {
        type: '单件',
        unitType: this.unitType,
        img1: this.originalUrl1,
        img1k: this.cutdownUrl1,
        moteurl: moteurl,
        posture: this.moteurl1,
        models2: this.moteurl2,
      }
      this.$emit('generate1', value);
    },
    //多件生成
    async huanyi() {
      const moteurl = this.moteurl4 !== '' ? this.moteurl4 : this.moteurl3;
      // 校验
      const schildValue1 = this.$refs.uploadRef1.getChildshowOriginal();
      if (schildValue1 == '') {
        this.$message.error('请上传上装');
        return;
      }
      const schildValue2 = this.$refs.uploadRef1.getChildCutdown();
      if (schildValue2 == '') {
        this.$message.error('请等待抠图完成');
        return;
      }
      const xchildValue1 = this.$refs.uploadRef2.getChildshowOriginal();
      if (xchildValue1 == '') {
        this.$message.error('请上传下装');
        return;
      }
      const xchildValue2 = this.$refs.uploadRef2.getChildCutdown();
      if (xchildValue2 == '') {
        this.$message.error('请等待抠图完成');
        return;
      }
      if (moteurl == '') {
        this.$message.error('请选择模特');
        return;
      }
      const obj = {
        type: 'duojian',
        img2: this.originalUrl2,
        img3: this.originalUrl3,
        models: moteurl,
      }
      const response = await apiClient.post(`/checkGenerating`, obj);
      if (response.data.data.length !== 0) {
        this.$message.warning('任务正在生成中，请稍后');
        return;
      }
      const value = {
        type: '多件',
        img2: this.originalUrl2,  // 多件上装原图
        img3: this.originalUrl3, // 多件下装原图
        img2k: this.cutdownUrl2, // 多件上装保留图
        img3k: this.cutdownUrl3, // 多件下装保留图
        moteurl: moteurl,
        posture: this.moteurl3,
        models2: this.moteurl4,
      }
      this.$emit('generate', value);
    },
    // selectModel1(index) {
    //   for (var i = 0; i < this.models.length; i++) {
    //     this.models[i].selected = '0'
    //   }
    //   this.models[index].selected = '1'
    //   if (this.activeName === 'first') {
    //     this.moteurl1 = this.models[index].image_path
    //   }
    //   if (this.activeName === 'second') {
    //     this.moteurl3 = this.models[index].image_path
    //   }
    //   this.dialogVisible1 = false
    // },
  }
}
</script>

<style scoped>
.body {
  color: #303133;
}
.tabscontent /deep/ .el-tabs__nav-wrap::after {
  height: 1px;
}
.tabscontent {
  margin-bottom: 100px;
}
.selectdiv {
  width: 100%;
  font-size: 14px;
}
.buweidiv {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 5px 0 15px 0;
  padding: 0 15px;
}
.buwei {
  flex: 1;
  margin: 0 7px;
  border-radius: 5px;
  cursor: pointer;
}
.buweitext {
  margin: 5px 5px 0 5px;
  text-align: center;
  border-radius: 5px;
  background: #fff;
}
.buweiimg {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  margin-top: 7px
}
.buweiimg /deep/ img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.buweiborder {
  height: 80px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 2px solid #2352d8 ;
  padding-top: 5px
}
.buweiselected {
  /* border-radius: 5px;
  border: 2px solid #2352d8; */
  background: linear-gradient(19deg, #3E93F0, #A835F2);
  border: none;
  color: #fff;
}
.containDisable {
  cursor: not-allowed;
}
.divDisable {
  pointer-events: none;
}
.banxingselect /deep/ .el-input__inner {
  font-size: 12px;
}
.selectmodelclass1 {
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  border-radius: 5px;
  cursor: pointer;
}
.selectmodelclass1:hover {
  background: rgba(101, 126, 185, 0.08);
}
.xzmt {
  margin-left: 30px;
}
.iconcontain {
  height: 100px;
  width: 80px;
  background-color: #efefef;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;
}
.xuanzetext {
  width: calc(100% - 80px);
  text-align: left;
  font-size: 14px;
  color: #303133;
}
.model-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  margin-top: 20px;
  padding: 20px;
  overflow-y: auto;
  height: 80vh;
  scrollbar-width: none;
  z-index: 99999999;
}
.model-gallery::-webkit-scrollbar {
  display: none;
}
.model-card {
  width: 200px;
  text-align: center;
}
.model-card img {
  width: 100%;
  border-radius: 10px;
}
.generate-footer {
  width: 290px;
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  height: 100px;
}
.xiaohaodiv {
  display: flex;
  align-items: center;
}
.jinbi_icon {
  width: 33px;
  height: auto;
  margin-left: 10px;
}
.generatebtn {
  width: 160px;
  height: 48px;
  border-radius: 10px;
  background: linear-gradient(0deg, #A835F2, #3E93F0);
  color: #fff;
}
.generatebtn:hover {
  background: linear-gradient(0deg, #A835F2, #3E93F0);
  box-shadow: 0 0 0 1000px rgba(255, 255, 255, 0.2) inset !important;
}
.upload-demo {
  height: 100px;
  font-size: 14px;
  color: #303133;
  margin-top: 10px;
  border-radius: 5px;
}
.upload-demo /deep/ .el-upload {
  width: 100%;
  text-align: left;
}
.selectmodelclass2 {
  display: flex;
  align-items: center;
}
.selectmodelclass2:hover {
  background: rgba(101, 126, 185, 0.08);
}
.uploadmotetext {
  width: calc(100% - 80px);
}
</style>
