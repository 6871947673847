import axios from 'axios';
import OSS from 'ali-oss';

// 创建一个 axios 实例，并设置基础 URL
const apiClient = axios.create({
  baseURL: window._CONFIG['domianURL'],
  headers: {
    'Content-Type': 'application/json'
  }
});

// 添加拦截器处理 403 和 500 状态码
apiClient.interceptors.response.use(
  response => response,
  error => {
    // if (error.response && (error.response.status === 403 || error.response.status === 500)) {
        if (error.response && (error.response.status === 403)) {

            // 执行退出系统操作
      localStorage.setItem('token', '');
      window.location = '/';
    }
    return Promise.reject(error);
  }
);

// 添加 uploadImageToOSS 方法
apiClient.uploadImageToOSS = async function(imageUrl, lujing) {
    try {
        // Step 1: Download the image
        const response = await fetch(imageUrl);
        if (!response.ok) {
            throw new Error(`Failed to fetch image: ${response.statusText}`);
        }
        const blob = await response.blob();

        // Step 2: Create a unique filename
        const timestamp = Date.now();
        const filename = `${timestamp}.png`;

        // Determine the directory path
        const mulu = lujing || '';

        // Step 3: Initialize OSS client
        // You need to configure your OSS credentials here
        const client = new OSS({
            region: 'oss-cn-shanghai',
            accessKeyId: 'LTAI5tDd6jPMo9s6U68u5JPp',
            accessKeySecret: 'oq4XuczPf7iOYMSg5HmrpHXhDBAnY2',
            bucket: 'huanyingyichu'
        });

        // Step 4: Upload the image to OSS
        const result = await client.put(mulu + filename, blob);
        console.log('Upload successful:', result.url);

        return {
            url: result.url
        };
    } catch (err) {
        console.error('Upload failed:', err);
        throw err;
    }
};



export default apiClient;

