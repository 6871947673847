<template>
  <div class="container" v-if="allResourcesLoaded">
    <el-dialog :visible.sync="imgPreviewVisible" width="80%" :modal-append-to-body="true" @close="closePreview"
      class="previewdialog" style="text-align: center">
      <span slot="title">
        <span style="margin-right: 20px">任务ID-{{historyBack.id}}</span>
        <span style="color: #808080">{{historyBack.create_time}}</span>
      </span>
      <div style="height: 100%; width: 100%; display: flex">
        <div class="yulanmote" v-if="currentMenu!='4'&&currentMenu!='8'&&currentMenu!='10'&&currentMenu!='11'">
          <div v-if="currentMenu === '1'" class="smalltag">
            <!-- 标签 -->
            <el-tag v-if="historyBack.posture && historyBack.posture !== ''" style="height: 20px; line-height: 20px">AI模特</el-tag>
            <el-tag v-if="historyBack.models2 && historyBack.models2 !== ''" style="height: 20px; line-height: 20px">我的模特</el-tag>
          </div>
          <div v-if="currentMenu === '2'" class="smalltag">
            <el-tag style="height: 20px; line-height: 20px">AI模特</el-tag>
          </div>
          <div v-if="currentMenu === '3'" class="smalltag">
            <el-tag v-if="historyBack.models && historyBack.models !== ''" style="height: 20px; line-height: 20px">AI模特</el-tag>
            <el-tag v-if="!historyBack.models && historyBack.changjing !== ''" style="height: 20px; line-height: 20px">AI场景</el-tag>
          </div>
          <div v-if="currentMenu === '6'" class="smalltag">
            <el-tag  style="height: 20px; line-height: 20px">我的模特</el-tag>
          </div>
          <!-- 图片 -->
          <div v-if="currentMenu === '1'">
            <img v-if="historyBack.posture && historyBack.posture !== ''" :src="getOriginalUrl(historyBack.posture)" />
            <img v-if="historyBack.models2 && historyBack.models2 !== ''" :src="getOriginalUrl(historyBack.models2)" />
          </div>
          <img v-if="currentMenu === '2'" :src="getOriginalUrl(historyBack.models)" />
          <div v-if="currentMenu === '3'">
            <img v-if="historyBack.models && historyBack.models !== ''" :src="getOriginalUrl(historyBack.models)" />
            <img v-if="!historyBack.models && historyBack.changjing !== ''" :src="getOriginalUrl(historyBack.changjing)" />
          </div>
          <div v-if="currentMenu === '6'">
            <img v-if="historyBack.models && historyBack.models !== ''" :src="getOriginalUrl(historyBack.models)" />
          </div>
        </div>
        <div :class="currentMenu!='4'&&currentMenu!='8'&&currentMenu!='10'&&currentMenu!='11'?'yulanyuantu':'yulanyuantu1'">
          <div class="smalltag">
            <el-tag style="height: 20px; line-height: 20px">原图</el-tag>
          </div>
          <div v-if="historyBack.type && historyBack.type.includes('多件')" class="yulanduojian">
            <div class="duojian1">
              <img :src="getOriginalUrl(historyBack.img2)" />
            </div>
            <div class="duojian2">
              <img :src="getOriginalUrl(historyBack.img3)" />
            </div>
          </div>
          <div v-else class="danjian1">
            <img :src="getOriginalUrl(historyBack.img1)" />
          </div>
        </div>
        <div class="yulanshengtu">
          <div ref="panzoomElement" class="previewdiv">
            <img :src="vpath1" />
            <div class="dialog_loading" v-if="isLoadingXiaoguo && imgPreviewVisible">
              <div>
                  <i class="el-icon-loading" style="font-size: 22px"></i>
                  <div style="margin-top: 5px">{{ loadingText }}</div>
                </div>
            </div>
          </div>
          <div class="tagdiv">
            <el-tag style="height: 20px; line-height: 20px">生成图</el-tag>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer" v-if="!isLoadingXiaoguo">
        <div
          style="display: flex; align-items: center; justify-content: space-around; background:#EFF1F6; border-radius: 5px 0 0 5px; line-height: 1; padding: 1px 0">
          <div class="yulanIconDiv" :class="{'selecticon': iconSelected === '修脸'}" v-if="Number(currentMenu) <= '7' &&currentMenu != '4'" @click="lianbuxiufubd" @mouseenter="iconEnter('修脸')" @mouseleave="iconSelected = null">
            <img src="@/assets/lian11.png" style="height:20px; width:20px; margin-right:5px" />
            <span>{{ $t('xiulian') }}</span>
          </div>
          <div class="yulanIconDiv" :class="{'selecticon': iconSelected === '修手'}" v-if="Number(currentMenu) <= '7' &&currentMenu != '4'" @click="shoubuxiufubd" @mouseenter="iconEnter('修手')" @mouseleave="iconSelected = null">
            <img src="@/assets/shou11.png" style="height:20px; width:20px; margin-right:5px" />
            <span>修手</span>
          </div>
          <div class="yulanIconDiv" :class="{'selecticon': iconSelected === '扩图'}" @click="kuotubd" @mouseenter="iconEnter('扩图')" @mouseleave="iconSelected = null">
            <img src="@/assets/kuotu2.png" style="height:20px; width:20px; margin-right:5px" />
            <span>{{ $t('kuotu') }}</span>
          </div>
          <div class="yulanIconDiv" :class="{'selecticon': iconSelected === '换装'}" v-if="Number(currentMenu) === 5" @click="gotohuanzhuang" @mouseenter="iconEnter('换装')" @mouseleave="iconSelected = null">
            <img src="@/assets/gohuanzhuang.png" style="height:23px; width:23px; margin-right:5px" />
            <span>用模特</span>
          </div>
        </div>
        <el-button type="primary" class="uploadbtn" @click="downloadImage">
          <img src="@/assets/xiazai2.png" style="height: 12px; width: 15px" />
          {{ $t('xiazaitupian') }}
        </el-button>
        <div class="sizeicon">
          <img src="@/assets/reset3.png" style="height: 25px; width: 25px; cursor: pointer;" @click="resetZoom" />
          <i class="el-icon-minus" style="font-size: 25px; cursor: pointer;" @click="zoomOut"></i>
          <span>{{ scalePercent }}%</span>
          <i class="el-icon-plus" style="font-size: 25px; cursor: pointer;" @click="zoomIn"></i>
        </div>
      </span>
    </el-dialog>
    <el-dialog :title="$t('tupianyulan')" :visible.sync="imgPreviewVisible2" width="80%" :modal-append-to-body="true" @close="closePreview"
      class="previewdialog" style="text-align: center">
      <div style="height: 100%; width: 100%">
        <div ref="panzoomElement2" class="previewdiv">
          <img :src="vpath1" />
          <div class="dialog_loading" v-if="isLoadingXiaoguo && imgPreviewVisible2">
            <div>
                <i class="el-icon-loading" style="font-size: 22px"></i>
                <div style="margin-top: 5px">{{ loadingText }}</div>
              </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer" v-if="!isLoadingXiaoguo">
        <div
          style="display: flex; align-items: center; justify-content: space-around; background:#EFF1F6; border-radius: 5px 0 0 5px; line-height: 1; padding: 1px 0">
          <div class="yulanIconDiv" :class="{'selecticon': iconSelected === '修脸'}" v-if="Number(currentMenu) <= '7' && currentMenu!='4'" @click="lianbuxiufubd" @mouseenter="iconEnter('修脸')" @mouseleave="iconSelected = null">
            <img src="@/assets/lian11.png" style="height:20px; width:20px; margin-right:5px" />
            <span>{{ $t('xiulian') }}</span>
          </div>
          <div class="yulanIconDiv" :class="{'selecticon': iconSelected === '修手'}" v-if="Number(currentMenu) <= '7' && currentMenu!='4'" @click="shoubuxiufubd" @mouseenter="iconEnter('修手')" @mouseleave="iconSelected = null">
            <img src="@/assets/shou11.png" style="height:20px; width:20px; margin-right:5px" />
            <span>修手</span>
          </div>
          <div class="yulanIconDiv" :class="{'selecticon': iconSelected === '扩图'}" @click="kuotubd" @mouseenter="iconEnter('扩图')" @mouseleave="iconSelected = null">
            <img src="@/assets/kuotu2.png" style="height:20px; width:20px; margin-right:5px" />
            <span>{{ $t('kuotu') }}</span>
          </div>
          <div class="yulanIconDiv" :class="{'selecticon': iconSelected === '换装'}" v-if="Number(currentMenu) === 5" @click="gotohuanzhuang" @mouseenter="iconEnter('换装')" @mouseleave="iconSelected = null">
            <img src="@/assets/gohuanzhuang.png" style="height:23px; width:23px; margin-right:5px" />
            <span>用模特</span>
          </div>
        </div>
        <el-button type="primary" class="uploadbtn" @click="downloadImage">
          <img src="@/assets/xiazai2.png" style="height: 12px; width: 15px" />
          {{ $t('xiazaitupian') }}
        </el-button>
        <div class="sizeicon">
          <img src="@/assets/reset3.png" style="height: 25px; width: 25px; cursor: pointer;" @click="resetZoom" />
          <i class="el-icon-minus" style="font-size: 25px; cursor: pointer;" @click="zoomOut"></i>
          <span>{{ scalePercent }}%</span>
          <i class="el-icon-plus" style="font-size: 25px; cursor: pointer;" @click="zoomIn"></i>
        </div>
      </span>
    </el-dialog>
    <el-dialog title="历史任务" :visible.sync="allHistoryVisible" width="82%" :modal-append-to-body="true"
      class="historydialog" style="text-align: center">
      <el-row v-for="(group, groupIndex) in groupedData" :key="groupIndex">
        <el-col v-for="(item, index) in group" :key="index" class="dialogcol">
          <div
            class="carddiv"
            :class="{'lishiselected': (saveHistoryIndex === item.id || saveHistoryIndex1 === item.id)}"
            @click="handleHistory(item)"
            @mouseenter="enterItem(item)"
            @mouseleave="leaveItem"
          >
            <div style="text-align: left; color: #000; margin-bottom: 5px">任务ID-{{item.id}}</div>
            <div style="text-align: left; color: #808080">{{item.create_time}}</div>
            <el-image
                v-if="item.is_complete=='失败'"
                class="lishidialogimg"
                :src="item.module=='2'||item.module=='4'||item.module=='6'||item.module=='11'?shibaiImage1:shibaiImage"
            >
            </el-image>
            <el-image
                v-else
              class="lishidialogimg"
              :src="item.imgJieguo"
            >
              <div slot="placeholder" class="imgloading">
                <img :src="placeholderImage" style="width: 100%; height: auto" />
              </div>
              <div slot="error" @click="handleHistory(item)" style="height: 100%">
                <!-- <i class="el-icon-loading" style="margin-top: 40px"></i>
                <div style="font-size: 14px; color: #303313">生成中...</div> -->
                <img :src="errorImage" style="width: 100%; height: auto" />
              </div>
            </el-image>
          </div>
          <div class="dialogdel" @click="delHistory(item)">
            <i class="el-icon-delete" style="font-size: 22px; color: #fff" />
          </div>
        </el-col>
      </el-row>
      <el-pagination
        slot="footer"
        background
        layout="prev, pager, next"
        :total="historyAll.length"
        :current-page="historyCurrentPage"
        :page-size="historyPageSize"
        @current-change="currentChange"
        style="text-align: center"
      >
      </el-pagination>
    </el-dialog>
    <div style="width: 130px;">
      <el-menu :default-active="currentMenu" @select="selectMenu" class="el-menu-vertical-demo" ref="cdmenu">

        <el-menu-item index="1">
          <img src="@/assets/aiIcon1.png" class="menuicon" style="width: 22px; margin-left: 3px" />
          <span slot="title">{{ $t('aihuanzhuang') }}</span>
        </el-menu-item>
        <el-menu-item index="2">
          <img src="@/assets/rentaiIcon1.png" class="menuicon" style="width: 22px; margin-left: 3px" />
          <span slot="title">{{ $t('rentaitu') }}</span>
        </el-menu-item>
        <el-menu-item index="3">
          <img src="@/assets/banlv1.png" class="menuicon" />
          <span slot="title">换脸换背景</span>
        </el-menu-item>
        <el-menu-item index="6">
          <img src="@/assets/xieziIcon1.png" class="menuicon" style="width: 20px;" />
          <span slot="title">{{ $t('xiezi') }}</span>
        </el-menu-item>
        <el-menu-item index="4">
          <img src="@/assets/shangpinIcon1.png" class="menuicon" />
          <span slot="title">{{ $t('shangpintu') }}</span>
        </el-menu-item>
        <el-menu-item index="5">
          <img src="@/assets/moteIcon1.png" class="menuicon" />
          <span slot="title">{{ $t('motexunlian') }}</span>
        </el-menu-item>
<!--        <el-menu-item index="7">-->
<!--          &lt;!&ndash; <i class="el-icon-menu"></i> &ndash;&gt;-->
<!--          <img src="@/assets/lianbu1.png" class="menuicon" style="width: 20px" />-->
<!--          <span slot="title">{{ $t('lianbuxunlian') }}</span>-->
<!--        </el-menu-item>-->
        <el-menu-item index="8">
          <!-- <img src="@/assets/tongkuan.png" class="menuicon" style="max-width:120px" /> -->
          <img src="@/assets/gaikuan1.png" class="menuicon" />
          <span slot="title">{{ $t('fushigaikuan') }}</span>
        </el-menu-item>
        <el-menu-item index="9">
          <!-- <img src="@/assets/081.png" class="menuicon" style="max-width:120px" /> -->
          <img src="@/assets/wenshengkuan.png" class="menuicon" />
          <span slot="title">{{ $t('wenshengkuan') }}</span>
        </el-menu-item>
        <el-menu-item index="10">
          <!-- <img src="@/assets/huaxing.png" class="menuicon" style="max-width:120px" /> -->
          <img src="@/assets/huaxingIcon1.png" class="menuicon" style="width: 22px; margin-left: 3px" />
          <span slot="title">{{ $t('huaxing') }}</span>
        </el-menu-item>
        <el-menu-item index="11">
          <!-- <img src="@/assets/fengyi.png" class="menuicon" style="max-width:200px" /> -->
          <img src="@/assets/xiangao.png" class="menuicon" style="width: 20px" />
          <span slot="title">{{ $t('xiangao') }}</span>
        </el-menu-item>
      </el-menu>
    </div>
    <div v-if="currentMenu === '1'" class="box">
      <AIHuanzhuang ref="aiHuanzhuang" @generate1="huianyi1" @generate="huianyi" :historyBack="historyBack" :paidui="paidui" />
    </div>
    <!-- 人台图 -->
    <div v-if="currentMenu === '2'" class="box">
      <Rentaitu ref="rentaitu" :imgList3="imgList3" :imgList4="imgList4" :imgList5="imgList5" :historyBack="historyBack" :paidui="paidui" @AIGenerate="AIGenerate" />
    </div>
    <!-- 真人图 -->
    <div v-if="currentMenu === '3'" class="box">
      <!-- <ZhenRenTu ref="zhenrentu" :imgList3="imgList3" :imgList4="imgList4" :imgList5="imgList5" :historyBack="historyBack" :paidui="paidui" @AIGenerate="AIGenerate" /> -->
      <ChangeFace ref="zhenrentu" :imgList3="imgList3" :imgList4="imgList4" :imgList5="imgList5" :historyBack="historyBack" :paidui="paidui" @AIGenerate="huanlianhuanbeijing" @AIGenerate1="AIGenerateHuanlian" @AIGenerate2="AIGenerateHuanbeijing" />
    </div>
    <!-- 商品图 -->
    <div v-if="currentMenu === '4'" class="box">
      <ShangPinTu ref="shangpintu" :cjImgList1="cjImgList1" :cjImgList2="cjImgList2" :historyBack="historyBack" :paidui="paidui" @AIGenerate="AIGenerate" />
    </div>
    <!-- 模特训练 -->
    <div v-if="currentMenu === '5'" class="box">
      <ModelTraining :historyBack="historyBack" :paidui="paidui" @AIGenerate1="AIGenerate1" />
    </div>
    <!-- 鞋子上脚 -->
    <div v-if="currentMenu === '6'" class="box">
      <Shoes ref="shoes" @AIGenerate="AIGenerate" :historyBack="historyBack" :paidui="paidui" />
    </div>
    <!-- 脸部训练 -->
    <div v-if="currentMenu === '7'" class="box">
      <FaceTraining @AIGenerate2="AIGenerate2" />
    </div>
    <!-- 服饰改款 -->
    <div v-if="currentMenu === '8'" class="box">
      <ClothingRedesign ref="clothingredesign" :colorlist="colorlist" :kuanxinglist="kuanxinglist" :sexlist="sexlist" :mianliaolist="mianliaolist" :historyBack="historyBack" :paidui="paidui" @AIGenerate3="AIGenerate3" />
    </div>
    <!-- 文生款 -->
    <div v-if="currentMenu === '9'" class="box">
      <TextGenerated ref="textgenerated" :colorlist="colorlist" :kuanxinglist="kuanxinglist" :sexlist="sexlist" :mianliaolist="mianliaolist" :historyBack="historyBack" :paidui="paidui" @AIGenerate4="AIGenerate4" />
    </div>
    <!-- 花型衍生 -->
    <div v-if="currentMenu === '10'" class="box">
      <HuaXingYanSheng :historyBack="historyBack" :paidui="paidui" @AIGenerate5="AIGenerate5" />
    </div>
    <!-- 线稿生款 -->
    <div v-if="currentMenu === '11'" class="box">
      <XianGao ref="xiangao" :colorlist="colorlist" :kuanxinglist="kuanxinglist" :sexlist="sexlist" :mianliaolist="mianliaolist" :historyBack="historyBack" :paidui="paidui" @AIGenerate6="AIGenerate6" />
    </div>
    <!-- 生成结果 -->
    <div style="flex-grow: 1;text-align: center; background-color: #eef1f7" @click="handleEmpty">
      <template>
        <div class="zhanshiqu">
          <div class="xiaoguodiv">
            <div style="text-align: left; margin-bottom: 10px; font-size: 14px; color: #303133">
              生成结果
              <span style="color: #999999;font-size: 12px; margin-left: 10px">{{jieguoTime}}</span>
              <span style="color: #f56c6c;font-size: 12px; margin-left: 10px">{{jieguoRemark}}</span>
            </div>
            <div class="xiaoguotu">
              <!-- 加载图标 -->
              <div class="loading_overlay" v-if="isLoadingXiaoguo">
                <div>
                  <i class="el-icon-loading"></i>
                  <div style="margin-top: 5px">{{ loadingText }}</div>
                </div>
              </div>

              <div v-show="vpath1 === '' && !isLoadingXiaoguo" class="image_container">
                <div>
                  <img src="@/assets/xiaoguobg.png" style="width: 240px; height: auto" />
                  <div>{{ $t('gaopinzhi') }}</div>
                </div>
              </div>
              <!-- 生成后的效果 -->
              <div class="image_container">
                <img style="height: 100%; width: 100%; object-fit: contain; border-radius: 10px" :src="vpath1" v-if="vpath1 != ''" />
                <div class="overlay" v-show="vpath1 != ''">
                  <div class="zoomindiv" @click="zoomImage">
                    <i class="el-icon-zoom-in" style="font-size: 30px; color: #fff;"></i>
                  </div>
                  <div class="botton_icon">
                    <img src="@/assets/lian22.png" v-if="Number(currentMenu) <= '7'&&currentMenu != '4'"
                      style="height:30px; width: 30px; margin: 0 7px" @click="lianbuxiufubd" />
                    <img src="@/assets/shou22.png" v-if="Number(currentMenu) <= '7'&&currentMenu != '4'"
                      style="height:26px; width: 26px; margin: 0 7px" @click="shoubuxiufubd" />
                    <!-- <img src="@/assets/icon2.png" style="height:30px; width: 30px; margin: 0 7px" @click="kuotubd" /> -->
                    <img src="@/assets/kuotu1.png" style="height:26px; width: 26px; margin: 0 7px" @click="kuotubd" />
                    <img src="@/assets/xiazai2.png" style="height: 23px; width: 25px; margin: 0 7px" @click="downloadImage" />
                    <!-- <i class="el-icon-download" @click="downloadImage"
                      style="font-size: 30px; cursor: pointer; color: #fff; margin: 0 7px"></i> -->
                    <!-- <img src="@/assets/rentai.png" v-if="currentMenu == '7'"
                      style="height:30px; width: 30px; margin: 0 15px" @click="gotorentai" />
                    <img src="@/assets/zhenren.png" v-if="currentMenu == '7'"
                      style="height:30px; width: 30px; margin: 0 15px" @click="gotozhenrentu" /> -->
                    <img src="@/assets/gohuanzhuang2.png" v-if="currentMenu == '5'"
                      style="height:28px; width: 28px; margin: 0 7px" @click="gotohuanzhuang" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="lishicontain" v-if="historyList.length !== 0">
            <div style="display: flex; justify-content: left; align-items: center; margin-bottom: 10px">
              <img src="@/assets/lishiIcon.png" style="height: 16px; width: auto; margin-right: 5px" />
              <span style="font-size: 14px; color: #303133">历史任务</span>
            </div>
            <div class="lishitu">
              <div v-for="(item, index) in historyList" :key="index" class="lishidiv">
                <el-image
                    v-if="item.is_complete=='失败'"
                    :src="shibaiImage2"
                    class="lishiimg"
                    :class="{'lishiselected': (saveHistoryIndex === item.id || saveHistoryIndex1 === item.id)}"
                    @click="handleHistory(item)"
                    @mouseenter="enterItem(item)"
                    @mouseleave="leaveItem"
                    :lazy="true"
                >
                </el-image>
                <el-image
                    v-else
                  :src="item.imgJieguo"
                  class="lishiimg"
                  :class="{'lishiselected': (saveHistoryIndex === item.id || saveHistoryIndex1 === item.id)}"
                  @click="handleHistory(item)"
                  @mouseenter="enterItem(item)"
                  @mouseleave="leaveItem"
                  :lazy="true"
                >
                  <div slot="placeholder" class="imgloading">
                    <img src="@/assets/loading11.gif" style="width: 100%; height: auto" />
                  </div>
                  <div slot="error" class="image-slot" @click="handleHistory(item)">
                    <i class="el-icon-loading" style="margin-top: 40px"></i>
                    <div style="font-size: 14px; color: #303313">生成中...</div>
                  </div>
                </el-image>
                <div class="lishiimgtext" @click="delHistory(item)">
                  <i class="el-icon-delete" style="color: #fff" />
                </div>
              </div>
            </div>
            <div class="showAll" >
              <el-button @click="handleAllHistory" style="width: 135px; border-radius: 0; border: 0"  v-if="historyAll.length > 10">查看全部</el-button>
            </div>
          </div>
        </div>
      </template>
    </div>

    <PricingModal :visible.sync="showModal" />

  </div>
</template>

<script>
import ChangeFace from './ChangeFace.vue';
import TextGenerated from './TextGenerated.vue';
import FaceTraining from './FaceTraining.vue';
import ModelTraining from './ModelTraining.vue';
import XianGao from './XianGao.vue';
import HuaXingYanSheng from './HuaXingYanSheng.vue';
import ClothingRedesign from './ClothingRedesign.vue';
import Shoes from './Shoes.vue';
import ShangPinTu from './ShangPinTu.vue';
import ZhenRenTu from './ZhenRenTu.vue';
import Rentaitu from './Rentaitu.vue';
import AIHuanzhuang from './AIHuanzhuang.vue';
import Panzoom from '@panzoom/panzoom';
import apiClient from '@/services/api'; // 假设你有一个 apiClient 处理请求
import axios from 'axios';
import PricingModal from './PricingModal.vue';
import ImageMore from './ImageMore.vue';
import { eventBus } from '@/main';

export default {
  name: 'HorizontalContainers',
  props: ['menuIndex'],
  components: {
    ChangeFace,
    PricingModal,
    ImageMore,
    AIHuanzhuang,
    Rentaitu,
    ZhenRenTu,
    ShangPinTu,
    Shoes,
    ClothingRedesign,
    HuaXingYanSheng,
    XianGao,
    ModelTraining,
    FaceTraining,
    TextGenerated,
  },
  data() {
    return {
      iconSelected: null,
      isGenerateChangeTask: false,
      historyDialogData: [],
      historyCurrentPage: 1,
      historyPageSize: 10,
      allHistoryVisible: false,
      historyAll: [],
      xiangaoCount: 0,
      huaxingCount: 0,
      wenshengCount: 0,
      gaikuanCount: 0,
      moteCount: 0,
      xieziCount: 0,
      shangpinCount: 0,
      zhenrenCount: 0,
      rentaiCount: 0,
      huanyiCount: 0,
      huanyi1Count: 0,
      paidui:false,
      isGenerateChangeMenu: false, // 生成时候切换菜单
      saveHistoryIndex1: null,
      saveHistoryIndex: null,
      historyBack: {},
      historyList: [],
      mianliaolist: [
        this.$t('pige'),
        this.$t('quanmao'),
        this.$t('pimaoyiti'),
        this.$t('diaopi'),
        this.$t('kelirong'),
        this.$t('jipirong'),
        this.$t('maorong'),
        this.$t('picao'),
        this.$t('niuzaimianliao'),
        this.$t('leisi'),
        this.$t('maonimianliao'),
        this.$t('dengxinrong'),
        this.$t('mianmamianliao'),
        this.$t('sichoumianliao'),
        this.$t('zhengzhimianliao'),
        this.$t('fanghugongneng'),
        this.$t('zhengzhuangmianliao'),
        this.$t('leisimianliao'),
        this.$t('mianfangmianliao'),
        this.$t('mafangmianliao'),
        this.$t('xuefang'),
        this.$t('shizhuanghuaxianmianliao'),
        this.$t('yundonghuaxianmianliao'),
        this.$t('hunfangmianliao'),
        this.$t('sezhitiaogemianliao'),
        this.$t('cuhua'),
        this.$t('zhenzhimaonimiaoliao'),
        this.$t('jingfangmianliao'),
        this.$t('sirongmianliao'),
        this.$t('yinhuamianliao'),
        this.$t('tihuamianliao'),
        this.$t('litizhuangshimianliao'),
        this.$t('teshumianliao'),
        this.$t('yangrong'),
        this.$t('mahaimao')
      ],
      sexlist: [this.$t('nvzhuang'), this.$t('nanzhuang'), this.$t('tongzhuang')],
      kuanxinglist: [
        [
          this.$t('shangyi'),
          this.$t('tixu'),
          this.$t('weiyi'),
          this.$t('chenshan'),
          this.$t('taoshan'),
          this.$t('kaishan'),
          this.$t('xiaokaishan'),
          this.$t('poloshan'),
          this.$t('beixin'),
          this.$t('dadishan'),
          this.$t('diaodai'),
          this.$t('moxiong'),
        ],
        [
          this.$t('mianyurong'),
          this.$t('mianyurongjiake'),
          this.$t('zhongchangmianyurong'),
          this.$t('yurongmajia'),
          this.$t('qingbaoyurong'),
          this.$t('yurongku'),
          this.$t('huaxuefu'),
        ],
        [
          this.$t('waitao'),
          this.$t('dayi'),
          this.$t('jiake'),
          this.$t('pijiake'),
          this.$t('xizhuang'),
          this.$t('fengyi'),
          this.$t('pifengyi'),
          this.$t('fangshaifu'),
          this.$t('paikefu'),
          this.$t('chongfengyi'),
          this.$t('majia'),
          this.$t('doupeng'),
        ],
        [
          this.$t('qun'),
          this.$t('lianyiqun'),
          this.$t('banshenqun'),
          this.$t('beidaiqun'),
        ],
        [
          this.$t('ku'),
          this.$t('zhongchangku'),
          this.$t('duankuo'),
          this.$t('weiku'),
          this.$t('liantiku'),
          this.$t('dadiku'),
          this.$t('beidaiku'),
          this.$t('yujiaoku'),
          this.$t('piku'),
        ],
        [
          this.$t('taozhuang'),
          this.$t('xizhuangtaozhuang'),
          this.$t('yundongtaozhuang'),
          this.$t('weiyitaozhuang'),
          this.$t('shishangtaozhuang'),
          this.$t('qinzizhuang'),
          this.$t('xueyuanfengtaozhuang'),
        ],
        [
          this.$t('jiajuzhuang'),
          this.$t('jiajufu'),
          this.$t('shuiyitaozhuang'),
          this.$t('shuiqun'),
          this.$t('shuipao'),
          this.$t('shuiku'),
          this.$t('shuiyidiaodai'),
          this.$t('shuiyi'),
          this.$t('yunfuzhuang'),
        ],
        [
          this.$t('neiyi'),
          this.$t('wenxiong'),
          this.$t('neiku'),
          this.$t('wenxiongtaozhuang'),
          this.$t('baonuanxilie'),
          this.$t('sushenyi'),
        ],
        [
          this.$t('hunsha'),
          this.$t('Azixinghunsha'),
          this.$t('yuweihunsha'),
          this.$t('zhishenhunsha'),
        ],
        [
          this.$t('lifu'),
          this.$t('xiaolifu'),
          this.$t('wanlifu'),
          this.$t('qipao'),
          this.$t('xiuhefu'),
        ],
        [
          this.$t('yongzhuang'),
          this.$t('liantiyongyi'),
          this.$t('fentiyongyi'),
          this.$t('shatanqun'),
          this.$t('bijini'),
          this.$t('shatanku'),
          this.$t('yongku'),
          this.$t('chonglangyongyi'),
        ],
        [
          this.$t('yingerfu'),
          this.$t('baopiyi'),
          this.$t('papafu'),
        ],
        [
          this.$t('fushipin'),
          this.$t('maozi'),
          this.$t('yifumao'),
          this.$t('bangqiumao'),
          this.$t('zhengzhimao'),
          this.$t('shishangmao'),
          this.$t('zheyangmao'),
          this.$t('weijin'),
          this.$t('sijin'),
          this.$t('zhengzhiweijin'),
          this.$t('shoutao'),
          this.$t('pishoutao'),
          this.$t('zhengzhishoutao'),
          this.$t('shishangshoutao'),
          this.$t('wazi'),
          this.$t('duanwa'),
          this.$t('tongwa'),
          this.$t('siwa'),
          this.$t('liankuwa'),
          this.$t('lingdai'),
          this.$t('lingjie'),
          this.$t('yaodai'),
        ],
        [
          this.$t('yingtongyongpin'),
          this.$t('lihe'),
          this.$t('koushuidou'),
          this.$t('baobaowa'),
          this.$t('yingermao'),
          this.$t('shuidai'),
        ],
        [
          this.$t('chongwuyongpin'),
          this.$t('chongwufushi'),
          this.$t('chongwuzhoubian')
        ]
      ],
      colorlist: [
        this.$t('qianfense'),
        this.$t('fenhongse'),
        this.$t('meihongse'),
        this.$t('hongse'),
        this.$t('anhongse'),
        this.$t('mise'),
        this.$t('tuose'),
        this.$t('huangse'),
        this.$t('jinse'),
        this.$t('lise'),
        this.$t('kafeise'),
        this.$t('ganlanlv'),
        this.$t('lvse'),
        this.$t('molvse'),
        this.$t('qianlanse'),
        this.$t('lanse'),
        this.$t('shenlanse'),
        this.$t('qianzise'),
        this.$t('zise'),
        this.$t('shenzise'),
        this.$t('baise'),
        this.$t('qianhuise'),
        this.$t('zhonghuise'),
        this.$t('shenhuise'),
        this.$t('heise'),
      ],
      panzoomInstance: null,
      scalePercent: 100,
      isLoadingXiaoguo: false,
      loadingText: '',
      imgPreviewVisible: false,
      imgPreviewVisible2: false,
      //监测人台图
      prompts: '',
      hairstyles: [
        "A stunning young lady with flowing brown hair and a radiant smile.",
        "Female, long, curly hair.",
        "Female, long curly hair.",
        `Female, long straight hair.`,
        "Long, straight hair, with a thick braid, looks chic and sophisticated.",
        "For women, comb their hair into a bun.",
        "Short hair, shaggy, looks modern and sophisticated.",
        "Female, short straight hair, ear length.",
        "Female, short straight hair, chin length.",
        `Female, short hair to shoulder length.`,
        `The man has a neatly styled modern haircut, with side parting and the top slicked back smoothly.`,
        `Male short hair, shaggy, center part, no bangs curly.`,
        `Male, short hair, curly hair, no bangs.`,
        `Male, Korean male hairstyle.`,
        `Male, male buzz cut, very short,brush cut.`
      ],
      changjinglist: [
        'Studio Shoot', 'Street Shoot', 'Beach', 'White Studio Shoot', 'Black Studio Shoot',
        'Gray Studio Shoot', 'European Street Shoot', 'Japanese Street Shoot', 'Chinese Street Shoot',
        'Forest', 'Mountain', 'Cherry Blossoms', 'Park', 'Fashion Show', 'Farm', 'Lawn', 'Party',
        'Swimming Pool', 'Gym', 'Library', 'Space', 'Dock', 'Home', 'Church', 'Amusement Park',
        'Colorful', 'Snowy Scene', 'Subway', 'Garage', 'Stairs', 'Graffiti', 'Street', 'Platform',
        'European Bedroom', 'Instagram Bedroom'
      ],

      biaoqinglist: ['Poker face','smile','shy','joyful','guffaw', 'aloof', 'anger'],
      defaulttx: ['girl1', 'girl2', 'girl3', 'boy1', 'boy2', 'boy3'],
      cjImgList2: [
        [
          {
            url: window._CONFIG['ossUrl']+'/changjing/cj21.jpg'+window._CONFIG['dialogThumbnail'],
            name: this.$t('xianhua')
          },
          {
            url: window._CONFIG['ossUrl']+'/changjing/cj22.jpg'+window._CONFIG['dialogThumbnail'],
            name: this.$t('chunjie')
          },
          {
            url: window._CONFIG['ossUrl']+'/changjing/cj23.jpg'+window._CONFIG['dialogThumbnail'],
            name: this.$t('meihua')
          },
          {
            url: window._CONFIG['ossUrl']+'/changjing/cj24.jpg'+window._CONFIG['dialogThumbnail'],
            name: this.$t('sichou')
          },
          {
            url: window._CONFIG['ossUrl']+'/changjing/cj25.jpg'+window._CONFIG['dialogThumbnail'],
            name: this.$t('luori')
          },
          {
            url: window._CONFIG['ossUrl']+'/changjing/cj26.jpg'+window._CONFIG['dialogThumbnail'],
            name: this.$t('shanlin')
          }
        ],
      ],
      cjImgList1: [
        [
          {
            url: window._CONFIG['ossUrl']+'/changjing/cj11.jpg'+window._CONFIG['dialogThumbnail'],
            name: this.$t('xuedi')
          },
          {
            url: window._CONFIG['ossUrl']+'/changjing/cj12.jpg'+window._CONFIG['dialogThumbnail'],
            name: this.$t('kafeiting')
          },
          {
            url: window._CONFIG['ossUrl']+'/changjing/cj13.jpg'+window._CONFIG['dialogThumbnail'],
            name: this.$t('bingchuan')
          },
          {
            url: window._CONFIG['ossUrl']+'/changjing/cj14.jpg'+window._CONFIG['dialogThumbnail'],
            name: this.$t('liantian')
          },
          {
            url: window._CONFIG['ossUrl']+'/changjing/cj15.jpg'+window._CONFIG['dialogThumbnail'],
            name: this.$t('haishui')
          },
          {
            url: window._CONFIG['ossUrl']+'/changjing/cj16.jpg'+window._CONFIG['dialogThumbnail'],
            name: this.$t('haitan')
          }
        ],
      ],
      imgMoreVisible5: false,
      saveChangjing: '',
      imgList5: [
        [
          {
            url: window._CONFIG['ossUrl']+'/changjing/pengpai.jpg'+window._CONFIG['dialogThumbnail'],
            name: this.$t('pengpai')
          },
          {
            url: window._CONFIG['ossUrl']+'/changjing/jiepai.jpg'+window._CONFIG['dialogThumbnail'],
            name: this.$t('jiepai')
          },
          {
            url: window._CONFIG['ossUrl']+'/changjing/haitan.jpg'+window._CONFIG['dialogThumbnail'],
            name: this.$t('haitan')
          },
          {
            url: window._CONFIG['ossUrl']+'/changjing/baisepengpai.jpg'+window._CONFIG['dialogThumbnail'],
            name: this.$t('baisepeng')
          },
          {
            url: window._CONFIG['ossUrl']+'/changjing/heisepengpai.jpg'+window._CONFIG['dialogThumbnail'],
            name: this.$t('heisepeng')
          },
          {
            url: window._CONFIG['ossUrl']+'/changjing/huisepengpai.jpg'+window._CONFIG['dialogThumbnail'],
            name: this.$t('huisepeng')
          },
          {
            url: window._CONFIG['ossUrl']+'/changjing/oushi.jpg'+window._CONFIG['dialogThumbnail'],
            name: this.$t('oushijiepai')
          },
          {
            url: window._CONFIG['ossUrl']+'/changjing/rishi.jpg'+window._CONFIG['dialogThumbnail'],
            name: this.$t('rishijiepai')
          },
          {
            url: window._CONFIG['ossUrl']+'/changjing/zhongshi.jpg'+window._CONFIG['dialogThumbnail'],
            name: this.$t('zhongshijiepai')
          },
          {
            url: window._CONFIG['ossUrl']+'/changjing/senlin.jpg'+window._CONFIG['dialogThumbnail'],
            name: this.$t('senlin')
          },
          {
            url: window._CONFIG['ossUrl']+'/changjing/gaoshan.jpg'+window._CONFIG['dialogThumbnail'],
            name: this.$t('gaoshan')
          },
          {
            url: window._CONFIG['ossUrl']+'/changjing/yinghua.jpg'+window._CONFIG['dialogThumbnail'],
            name: this.$t('yinghua')
          },
          {
            url: window._CONFIG['ossUrl']+'/changjing/gongyuan.jpg'+window._CONFIG['dialogThumbnail'],
            name: this.$t('gongyuan')
          },
          {
            url: window._CONFIG['ossUrl']+'/changjing/xiuchang.jpg'+window._CONFIG['dialogThumbnail'],
            name: this.$t('xiuchang')
          },
          {
            url: window._CONFIG['ossUrl']+'/changjing/nongchang.jpg'+window._CONFIG['dialogThumbnail'],
            name: this.$t('nongchang')
          },
          {
            url: window._CONFIG['ossUrl']+'/changjing/caoping.jpg'+window._CONFIG['dialogThumbnail'],
            name: this.$t('caoping')
          },
          {
            url: window._CONFIG['ossUrl']+'/changjing/juhui.jpg'+window._CONFIG['dialogThumbnail'],
            name: this.$t('juhui')
          },
          {
            url: window._CONFIG['ossUrl']+'/changjing/yongchi.jpg'+window._CONFIG['dialogThumbnail'],
            name: this.$t('yongchi')
          },
          {
            url: window._CONFIG['ossUrl']+'/changjing/jianshenfang.jpg'+window._CONFIG['dialogThumbnail'],
            name: this.$t('jianshenfang')
          },
          {
            url: window._CONFIG['ossUrl']+'/changjing/tushuguan.jpg'+window._CONFIG['dialogThumbnail'],
            name: this.$t('tushuguan')
          },
          {
            url: window._CONFIG['ossUrl']+'/changjing/taikong.jpg'+window._CONFIG['dialogThumbnail'],
            name: this.$t('taikong')
          },
          {
            url: window._CONFIG['ossUrl']+'/changjing/matou.jpg'+window._CONFIG['dialogThumbnail'],
            name: this.$t('matou')
          },
          {
            url: window._CONFIG['ossUrl']+'/changjing/jujia.jpg'+window._CONFIG['dialogThumbnail'],
            name: this.$t('jujia')
          },
          {
            url: window._CONFIG['ossUrl']+'/changjing/jiaotang.jpg'+window._CONFIG['dialogThumbnail'],
            name: this.$t('jiaotang')
          },
          {
            url: window._CONFIG['ossUrl']+'/changjing/youleyuan.jpg'+window._CONFIG['dialogThumbnail'],
            name: this.$t('youleyuan')
          },
          {
            url: window._CONFIG['ossUrl']+'/changjing/duocai.jpg'+window._CONFIG['dialogThumbnail'],
            name: this.$t('duocai')
          },
          {
            url: window._CONFIG['ossUrl']+'/changjing/xuejing.jpg'+window._CONFIG['dialogThumbnail'],
            name: this.$t('xuejing')
          },
          {
            url: window._CONFIG['ossUrl']+'/changjing/ditie.jpg'+window._CONFIG['dialogThumbnail'],
            name: this.$t('ditie')
          },
          {
            url: window._CONFIG['ossUrl']+'/changjing/cheku.jpg'+window._CONFIG['dialogThumbnail'],
            name: this.$t('cheku')
          },
          {
            url: window._CONFIG['ossUrl']+'/changjing/louti.jpg'+window._CONFIG['dialogThumbnail'],
            name: this.$t('louti')
          },
          {
            url: window._CONFIG['ossUrl']+'/changjing/tuya.jpg'+window._CONFIG['dialogThumbnail'],
            name: this.$t('tuya')
          },
          {
            url: window._CONFIG['ossUrl']+'/changjing/jietou.jpg'+window._CONFIG['dialogThumbnail'],
            name: this.$t('jietou')
          },
          {
            url: window._CONFIG['ossUrl']+'/changjing/zhantai.jpg'+window._CONFIG['dialogThumbnail'],
            name: this.$t('zhantai')
          },
          {
            url: window._CONFIG['ossUrl']+'/changjing/oushiwoshi.jpg'+window._CONFIG['dialogThumbnail'],
            name: this.$t('oufengwoshi')
          },
          {
            url: window._CONFIG['ossUrl']+'/changjing/inswoshi.jpg'+window._CONFIG['dialogThumbnail'],
            name: this.$t('inswoshi')
          }
        ]
      ]
      ,
      imgMoreVisible4: false,
      saveBiaoqing: '',
      imgList4: [
        [
          {
            url: window._CONFIG['ossUrl'] + '/biaoqing1.jpg'+window._CONFIG['dialogThumbnail'],
            name: this.$t('wubiaoqing')
          },
          {
            url: window._CONFIG['ossUrl'] + '/biaoqing2.jpg'+window._CONFIG['dialogThumbnail'],
            name: this.$t('weixiao')
          },
          {
            url: window._CONFIG['ossUrl'] + '/biaoqing3.jpg'+window._CONFIG['dialogThumbnail'],
            name: this.$t('haixiu')
          },
          {
            url: window._CONFIG['ossUrl'] + '/biaoqing4.jpg'+window._CONFIG['dialogThumbnail'],
            name: this.$t('xiyue')
          },
          {
            url: window._CONFIG['ossUrl'] + '/biaoqing5.jpg'+window._CONFIG['dialogThumbnail'],
            name: this.$t('daxiao')
          },
          {
            url: window._CONFIG['ossUrl'] + '/biaoqing6.jpg'+window._CONFIG['dialogThumbnail'],
            name: this.$t('gaoleng')
          },
          {
            url: window._CONFIG['ossUrl'] + '/biaoqing7.jpg'+window._CONFIG['dialogThumbnail'],
            name: this.$t('shengqi')
          },

        ],
      ],
      imgList3: [
        [
          window._CONFIG['ossUrl'] + '/faxing1.jpg'+window._CONFIG['dialogThumbnail'],
          window._CONFIG['ossUrl'] + '/faxing2.jpg'+window._CONFIG['dialogThumbnail'],
          window._CONFIG['ossUrl'] + '/faxing3.jpg'+window._CONFIG['dialogThumbnail'],
          window._CONFIG['ossUrl'] + '/faxing4.jpg'+window._CONFIG['dialogThumbnail'],
          window._CONFIG['ossUrl'] + '/faxing5.jpg'+window._CONFIG['dialogThumbnail'],
          window._CONFIG['ossUrl'] + '/faxing6.jpg'+window._CONFIG['dialogThumbnail'],
          window._CONFIG['ossUrl'] + '/faxing7.jpg'+window._CONFIG['dialogThumbnail'],
          window._CONFIG['ossUrl'] + '/faxing8.jpg'+window._CONFIG['dialogThumbnail'],
          window._CONFIG['ossUrl'] + '/faxing9.jpg'+window._CONFIG['dialogThumbnail'],
          window._CONFIG['ossUrl'] + '/faxing10.jpg'+window._CONFIG['dialogThumbnail'],
          window._CONFIG['ossUrl'] + '/faxing11.png'+window._CONFIG['dialogThumbnail'],
          window._CONFIG['ossUrl'] + '/faxing12.jpg'+window._CONFIG['dialogThumbnail'],
          window._CONFIG['ossUrl'] + '/faxing13.jpg'+window._CONFIG['dialogThumbnail'],
          window._CONFIG['ossUrl'] + '/faxing14.jpg'+window._CONFIG['dialogThumbnail'],
          window._CONFIG['ossUrl'] + '/faxing15.jpg'+window._CONFIG['dialogThumbnail'],
        ],
      ],
      currentMenu: '1',
      allResourcesLoaded: false,
      showModal: false,
      value: '',
      myloading: '',
      vpath1: '',
      vpath: '',
      page: 1,
      limit: 10,
      username: localStorage.username,
      url1: window._CONFIG['domianURL']+'gd/00055_00.jpg',
      furl1: window._CONFIG['domianURL']+'gd/00055_00.jpg',
      url2: window._CONFIG['domianURL']+'gd/00126_00.jpg',
      url3: window._CONFIG['domianURL']+'gd/00151_00.jpg',
      url4: window._CONFIG['domianURL']+'gd/00470_00.jpg',
      lurl1: window._CONFIG['domianURL']+'wj/051515_1.jpg',
      lurl2: window._CONFIG['domianURL']+'wj/051517_1.jpg',
      lurl3: window._CONFIG['domianURL']+'wj/051827_1.jpg',
      lurl4: window._CONFIG['domianURL']+'wj/051946_1.jpg',
      surl1: window._CONFIG['domianURL']+'1719969857625.png',
      surl2: window._CONFIG['domianURL']+'1719812281699.png',
      surl3: window._CONFIG['domianURL']+'1719812878787.png',
      moteurl: window._CONFIG['domianURL']+'moren/weishangchuan.png',
      yurl1: window._CONFIG['domianURL']+'1719812281699.png',
      dpath: '',
      lturl1: window._CONFIG['domianURL']+'lianti/053742_1.jpg',
      lturl2: window._CONFIG['domianURL']+'lianti/053744_1.jpg',
      lturl3: window._CONFIG['domianURL']+'lianti/053786_1.jpg',
      lturl4: window._CONFIG['domianURL']+'lianti/053790_1.jpg',
      urlAction:window._CONFIG['domianURL']+'upload1',
      urlAction1:window._CONFIG['domianURL']+'upload',
      urlAction3:window._CONFIG['domianURL']+'upload3',
      jieguoTime:'',
      jieguoRemark:'',
      hasDingshi:false,
      max_id:'',
      select_id:'',
      xiulianPath:'',
      placeholderImage: window._CONFIG['ossUrl']+'/loading22.png',
      errorImage: window._CONFIG['ossUrl']+'/loading22.png',
      shibaiImage: window._CONFIG['ossUrl']+'/shibai5.png',
      shibaiImage1: window._CONFIG['ossUrl']+'/shibai3.png',
      shibaiImage2: window._CONFIG['ossUrl']+'/shibai6.png',
      panduanList:[],//每个模块中，待判断是否执行成功的
      timers:[],//定时器id
    };
  },
  computed: {
    groupedData() {
      const groupSize = 5;
      const result = [];
      for (let i = 0; i < this.historyDialogData.length; i += groupSize) {
        result.push(this.historyDialogData.slice(i, i + groupSize));
      }
      return result;
    },
  },
  async mounted() {
    if (this.menuIndex) {
      this.selectMenu(this.menuIndex)
    } else {
      const historyList = await this.getHistoryList(this.currentMenu);
      this.historyList = historyList;
      this.dingshi();
    }
    this.myloading = this.$loading({
      lock: true,
      text: "正在加载中请稍等~",
      // spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 0.2)"
    });

    this.$nextTick(() => {
      this.checkAllResourcesLoaded();
    });

  },
  beforeDestroy() {


  },
  methods: {
    closePreview() {
      this.scalePercent = 100;
      this.panzoomInstance.reset();
      this.updateScalePercent();
      if (this.panzoomInstance) {
        this.panzoomInstance.destroy();
        this.panzoomInstance = null;
      }
    },
    iconEnter(val) {
      this.iconSelected = val;
    },
    currentChange(current) {
      this.historyCurrentPage = current;
      this.historyDialogList(this.currentMenu);
    },
    handleAllHistory() {

      if(this.currentMenu==2||this.currentMenu==4||this.currentMenu==6||this.currentMenu==11){
        this.placeholderImage = window._CONFIG['ossUrl']+'/loading11.gif';
        this.errorImage = window._CONFIG['ossUrl']+'/loading11.gif';
      }else{
        this.placeholderImage = window._CONFIG['ossUrl']+'/loading22.png';
        this.errorImage = window._CONFIG['ossUrl']+'/loading22.png';
      }

      this.allHistoryVisible = true;
      if (this.isLoadingXiaoguo) {
        this.isGenerateChangeTask = true;
      }
      this.historyCurrentPage = 1;
      this.historyDialogList(this.currentMenu);
    },
    handleEmpty() {
      if (this.currentMenu === '1') {
        this.$refs.aiHuanzhuang.handleCloseMore();
      }
      if (this.currentMenu === '2') {
        this.$refs.rentaitu.handleCloseMore();
      }
      if (this.currentMenu === '3') {
        this.$refs.zhenrentu.handleCloseMore();
      }
      if (this.currentMenu === '4') {
        this.$refs.shangpintu.handleCloseMore();
      }
      if (this.currentMenu === '6') {
        this.$refs.shoes.handleCloseMore();
      }
      if (this.currentMenu === '8') {
        this.$refs.clothingredesign.handleCloseMore();
      }
      if (this.currentMenu === '9') {
        this.$refs.textgenerated.handleCloseMore();
      }
      if (this.currentMenu === '11') {
        this.$refs.xiangao.handleCloseMore();
      }
    },
    delHistory(value) {
      this.$confirm('一旦删除将无法复原，请再次确认', '您确定要删除吗', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        try {
          const response = await apiClient.get(`/delHistory?id=${value.id}`);
          if (response.status === 200) {
            // 刷新历史列表
            this.historyList = await this.getHistoryList(this.currentMenu);
            if (this.historyAll.length % 10 === 0 && this.historyCurrentPage === Math.ceil((this.historyAll.length + 1) / 10)) {
              this.historyCurrentPage -= 1;
            }
            this.historyDialogList(this.currentMenu);
            // 清空回显
            if (this.vpath1 === value.imgJieguo.split('?')[0]) {
              this.vpath1 = '';
              this.historyBack = {
                type: '',
                banxing: '',
                img1: '',
                img1k: '',
                img2: '',
                img2k: '',
                img3: '',
                img3k: '',
                yuantuxiangsi:'',
                prompt:'',
              };
              this.jieguoTime=''
              this.jieguoRemark=''
            }
            this.$message.success('删除成功');
            this.isLoadingXiaoguo = false;
          }
        } catch (error) {
          this.$message.error('删除失败');
        }
      }).catch(() => {

      });
    },
    async delHistoryNoPermission(value) {
      const response = await apiClient.get(`/delHistory?id=${value.id}`);
      this.historyList = await this.getHistoryList(this.currentMenu);
    },
    async hideHistory(value) {
      const response = await apiClient.get(`/hideHistory?id=${value.id}&remark=${value.remark}`);
      this.historyList = await this.getHistoryList(this.currentMenu);
    },
    async handleHistory(value,type) {
      //type有值时  代表扩图或者修脸、修手等自动跳转
      if(!type){
        if(this.paidui){
          this.$message.warning('排队中~请等待2秒再继续操作');
          return  false;
        }
        this.isGenerateChangeTask = true;
      }
      if (this.allHistoryVisible) {
        this.allHistoryVisible = false;
      }
      // const showimg = await this.getHistoryList(this.currentMenu, value.id);
      const showimg = this.historyAll.find(it => it.id === value.id);
      this.select_id=value.id;
      if(!showimg) return false;
      if(showimg.is_running=='1'){
        this.vpath1 ='';
        this.jieguoTime ='';
        this.jieguoRemark='';
        this.isLoadingXiaoguo = true;
        this.loadingText = this.$t('aituxingloading');
      }else{
        this.vpath1 = showimg.imgJieguo;
        this.jieguoTime = showimg.create_time;
        if(showimg.is_complete=='失败'){
          if(showimg.remark){
            this.jieguoRemark='（生成失败:'+showimg.remark+'）';
          }else{
            this.jieguoRemark='（生成失败）';
          }
        }else{
          this.jieguoRemark='';
        }

        this.isLoadingXiaoguo = false;
      }
      console.log('historyBack', showimg)
      this.historyBack = showimg;
      // 选中历史图标增加边框
      this.saveHistoryIndex1 = value.id;

    },
    enterItem(value) {
      this.saveHistoryIndex = value.id;
    },
    leaveItem() {
      this.saveHistoryIndex = null;
    },
    //转化缩略图
    convertThumbnail(arr,config){
      const thumbnailSuffix=window._CONFIG[config] || '';
      const newArr=arr.map(item=>{
        return {
          ...item,
          imgJieguo:item.imgJieguo + thumbnailSuffix
        }
      })
      return newArr;
    },
    async getHistoryList(module, id) {
      const url = id ? `getHistory?module=${module}&id=${id}` : `getHistory?module=${module}&user=${localStorage.username}`
      const res = await apiClient.get(url);
      this.historyAll = res.data.data;
      const historyShow = res.data.data.slice(0, 10);
      const thumbnailList=this.convertThumbnail(historyShow,'thumbnail');
      return thumbnailList;
    },
    async historyDialogList(module, id) {
      const url = id ? `getHistory?module=${module}&id=${id}&page_no=${this.historyCurrentPage}&page_size=${this.historyPageSize}`
        : `getHistory?module=${module}&user=${localStorage.username}&page_no=${this.historyCurrentPage}&page_size=${this.historyPageSize}`;
      const res = await apiClient.get(url);
      const thumbnailList=this.convertThumbnail(res.data.data,'dialogThumbnail');
      // this.historyAll = res.data.data;
      // const historyShow = res.data.data.slice(0, 10);
      // this.historyDialogData = historyShow;
      // this.historyDialogData = res.data.data;
      this.historyDialogData = thumbnailList;
    },
    zoomIn() {
      console.log(789);
      if (this.panzoomInstance) {
        this.panzoomInstance.zoomIn();
        this.updateScalePercent(); // 更新缩放百分比
      }
    },
    zoomOut() {
      console.log(456);
      if (this.panzoomInstance) {
        this.panzoomInstance.zoomOut();
        this.updateScalePercent(); // 更新缩放百分比
      }
    },
    resetZoom() {
      console.log(123);
      if (this.panzoomInstance) {
        this.panzoomInstance.reset();
        this.updateScalePercent(); // 更新缩放百分比
      }
    },
    updateScalePercent() {
      // 获取当前的缩放比例
      const currentScale = this.panzoomInstance.getScale();
      // 计算百分比，并四舍五入保留两位小数
      this.scalePercent = Math.round(currentScale * 100);
    },
    async kuotubd() {
      if (this.imgPreviewVisible || this.imgPreviewVisible2) {
        this.resetZoom();
        this.panzoomInstance.setOptions({
          disablePan: true,
          disableZoom: true,
        });
      }
      var that = this
      this.paidui = true;
      this.isGenerateChangeMenu = false;
      this.isGenerateChangeTask = false;
      this.paidui = true;
      try {
        var tresponse1 = await apiClient.get('/getcishu', {
          params: {
            username: localStorage.username,
          }
        });
        var train = tresponse1.data.data[0].userCoins
        train = parseInt(train)
        if (train < 3) {
          this.$message.error("您的换装套餐已经用完~")
          this.showModal = true
          return false
        }
        this.isLoadingXiaoguo = true;
        this.loadingText = 'AI图形正在生成中，请耐心等待1-2分钟~';
        var value = JSON.parse(JSON.stringify(that.historyBack));
        value.imgJieguo_change = this.vpath1;
        value.client_id = '';
        value.id = '';
        that.vpath1 = '';
        value.fuwuqi = 'comfytask8288kuotu'
        value.ip = '8288'
        value.module = this.currentMenu;
        await that.insertHistory(value);
        setTimeout(() => {
          value.id = that.max_id;
        }, 2000)
        // that.handleHistory({id: this.max_id}, '1')
        // var t1 = setInterval(async function () {
        //   try {
        //     var tresponse1 = await apiClient.get('/getHistory', {
        //       params: {
        //         id: value.id ? value.id : that.max_id,
        //       },
        //     });
        //     if(tresponse1.data.data.length==0){
        //       clearInterval(t1);
        //       return  false;
        //     }
        //     var data = tresponse1.data.data[0];
        //     if (data.imgJieguo != '' && data.is_complete == '完成') {
        //       clearInterval(t1)
        //       value.imgJieguo = data.imgJieguo;
        //       if (value.module === that.currentMenu && !that.isGenerateChangeMenu && !that.isGenerateChangeTask&&value.id==that.select_id) {
        //         that.vpath1 = data.imgJieguo
        //       }
        //       that.historyBack.imgJieguo = data.imgJieguo;
        //       that.koujian('7', value)
        //       if (value.module === that.currentMenu&&value.id==that.select_id) {
        //         that.isLoadingXiaoguo = false;
        //       }
        //
        //       if (that.imgPreviewVisible || that.imgPreviewVisible2) {
        //         that.panzoomInstance.setOptions({
        //           disablePan: false,
        //           disableZoom: false,
        //         });
        //       }
        //
        //       if (!that.vpath1 && value.id == that.select_id) {
        //         that.vpath1 = data.imgJieguo
        //         that.jieguoTime = data.create_time;
        //       }
        //     } else if (data.is_complete == '失败') {
        //       that.failMessage(data,value);
        //       clearInterval(t1)
        //     }
        //   } catch (e) {
        //     console.log(e);
        //     that.errorMessage(value);
        //     clearInterval(t1)
        //   }
        //
        // }, 8000)
      } catch (e) {
        this.wrongMessage();
      }

    },
    async shoubuxiufubd() {
      if (this.imgPreviewVisible || this.imgPreviewVisible2) {
        this.resetZoom();
        this.panzoomInstance.setOptions({
          disablePan: true,
          disableZoom: true,
        });
      }
      var that = this
      this.paidui=true;
      this.isGenerateChangeMenu = false;
      this.isGenerateChangeTask = false;

      try {
        var tresponse1 = await apiClient.get('/getcishu', {
          params: {
            username: localStorage.username,
          }
        });
        var train = tresponse1.data.data[0].userCoins
        train = parseInt(train)
        if (train < 3) {
          this.$message.error("您的换装套餐已经用完~")
          this.showModal = true
          return false
        }
        this.isLoadingXiaoguo = true;
        this.loadingText = 'AI图形正在生成中，请耐心等待1-2分钟~';
        var value=JSON.parse(JSON.stringify(that.historyBack));

        value.imgJieguo_change=this.vpath1;
        value.client_id='';
        this.vpath1 = '';

        value.fuwuqi='comfytask8288xiushou'
        value.ip='107_8288';
        value.id='';
        value.module=this.currentMenu;
        await that.insertHistory(value);
        setTimeout(()=>{
          value.id=that.max_id;
        },2000)
        // that.handleHistory({id:this.max_id},'1')


        // var t1 = setInterval(async function () {
        //   try {
        //     var tresponse1 = await apiClient.get('/getHistory', {
        //       params: {
        //         id: value.id ? value.id : that.max_id,
        //       },
        //     });
        //     if(tresponse1.data.data.length==0){
        //       clearInterval(t1);
        //       return  false;
        //     }
        //     var data = tresponse1.data.data[0];
        //     if (data.imgJieguo != '' && data.is_complete == '完成') {
        //       clearInterval(t1)
        //       value.imgJieguo=data.imgJieguo;
        //       if (value.module===that.currentMenu&&!that.isGenerateChangeMenu && !that.isGenerateChangeTask&&value.id==that.select_id) {
        //         that.vpath1 = data.imgJieguo
        //       }
        //       that.historyBack.imgJieguo = data.imgJieguo;
        //       that.koujian('7',value)
        //
        //     if (that.imgPreviewVisible || that.imgPreviewVisible2) {
        //         that.panzoomInstance.setOptions({
        //           disablePan: false,
        //           disableZoom: false,
        //         });
        //       }
        //       if (value.module===that.currentMenu&&value.id==that.select_id) {
        //         that.isLoadingXiaoguo = false;
        //       }
        //       if(!that.vpath1&&value.id==that.select_id){
        //         that.vpath1 = data.imgJieguo
        //         const showimg = that.historyAll.find(it => it.id === value.id);
        //         that.jieguoTime = showimg.create_time;
        //       }
        //     } else if (data.is_complete == '失败') {
        //       that.failMessage(data,value);
        //       clearInterval(t1)
        //     }
        //   }catch (e) {
        //     that.errorMessage(value);
        //     clearInterval(t1)
        //   }
        // }, 8000)
      }catch (e) {
        this.wrongMessage();
      }
    },
    async lianbuxiufubd() {
      if (this.imgPreviewVisible || this.imgPreviewVisible2) {
        this.resetZoom();
        this.panzoomInstance.setOptions({
          disablePan: true,
          disableZoom: true,
        });
      }
      var that = this
      this.paidui = true;
      this.isGenerateChangeMenu = false;
      this.isGenerateChangeTask = false;
      try {
        var tresponse1 = await apiClient.get('/getcishu', {
          params: {
            username: localStorage.username,
          }
        });
        var train = tresponse1.data.data[0].userCoins
        train = parseInt(train)
        if (train < 3) {
          this.$message.error("您的换装套餐已经用完~")
          this.showModal = true
          return false
        }
        this.isLoadingXiaoguo = true;
        this.loadingText = 'AI图形正在生成中，请耐心等待1-2分钟~';
        var value = JSON.parse(JSON.stringify(that.historyBack));
        value.imgJieguo_change = this.vpath1;
        value.client_id = '';
        value.id = '';
        this.vpath1 = '';

        value.fuwuqi = 'comfytask8288xiulian'
        value.ip = '107_8188';
        value.module = this.currentMenu;
        await that.insertHistory(value);
        setTimeout(() => {
          value.id = that.max_id;
        }, 2000)
        // that.handleHistory({id: this.max_id}, '1')


        // var t1 = setInterval(async function () {
        //   try {
        //     var tresponse1 = await apiClient.get('/getHistory', {
        //       params: {
        //         id: value.id ? value.id : that.max_id,
        //       },
        //     });
        //     if(tresponse1.data.data.length==0){
        //       clearInterval(t1);
        //       return  false;
        //     }
        //     var data = tresponse1.data.data[0];
        //     if (data.imgJieguo != '' && data.is_complete == '完成') {
        //       clearInterval(t1)
        //       value.imgJieguo = data.imgJieguo;
        //       if (value.module === that.currentMenu && !that.isGenerateChangeMenu && !that.isGenerateChangeTask&&value.id==that.select_id) {
        //         that.vpath1 = data.imgJieguo
        //       }
        //       that.historyBack.imgJieguo = data.imgJieguo;
        //       that.koujian('7', value)
        //
        //       if (that.imgPreviewVisible || that.imgPreviewVisible2) {
        //         that.panzoomInstance.setOptions({
        //           disablePan: false,
        //           disableZoom: false,
        //         });
        //       }
        //
        //       if (value.module === that.currentMenu&&value.id==that.select_id) {
        //         that.isLoadingXiaoguo = false;
        //
        //       }
        //       if (!that.vpath1 && value.id == that.select_id) {
        //         that.vpath1 = data.imgJieguo
        //         const showimg = that.historyAll.find(it => it.id === value.id);
        //         that.jieguoTime = showimg.create_time;
        //       }
        //     } else if (data.is_complete == '失败') {
        //       that.failMessage(data,value);
        //       clearInterval(t1)
        //     }
        //   } catch (e) {
        //     that.errorMessage(value);
        //     clearInterval(t1)
        //   }
        // }, 8000)
      } catch (e) {
        this.wrongMessage();
      }

    },
    async AIGenerate2(val) {
      var that = this
      this.isGenerateChangeMenu = false;
      this.isGenerateChangeTask = false;
      var tresponse1 = await apiClient.get('/getcishu', {
        params: {
          username: localStorage.username,
        }
      });
      var train = tresponse1.data.data[0].userCoins
      train = parseInt(train)
      if (train < 2) {
        this.$message.error(this.$t('taocanyiyongwan'))
        this.showModal = true
        return false
      }
      this.isLoadingXiaoguo = true;
      this.loadingText = this.$t('aituxingloading')
      try {
        const response = await apiClient.get('/generateModel4', {
          params: {
            continent: val.value,
            gender: val.value1,
            ageRange: val.value2,
            expression: val.value3,
            prompts: val.prompts,
          },
          headers: {
            'x-access-token': localStorage.token
          }
        });
        var taskId = response.data.taskId
        var t1 = setInterval(async function () {
          var tresponse1 = await apiClient.get('/gettask', {
            params: {
              taskId: taskId,
            }
          });
          var status12 = tresponse1.data.status12.task_status
          if (status12 == 'FAILED') {
            clearInterval(t1)
            that.isLoadingXiaoguo = false;
            that.$message.error(that.$t('motetushibai'))
          }
          if (status12 == 'SUCCEEDED') {
            clearInterval(t1)
            var imgurl1 = tresponse1.data.status12.results[0].url
            var response2 = await apiClient.get('/downloadmyurl1', {
              params: {
                url2: imgurl1,
              }
            })
            var path2 = response2.data.outputPath
            var vpath = window._CONFIG['domianURL'] + path2.replace('./output/', '')
            that.dpath = vpath
            var tresponse1 = await apiClient.get('/inserrlist', {
              params: {
                resulturl: that.dpath,
                username: localStorage.username,
              }
            });
            clearInterval(t1)
            if (!that.isGenerateChangeMenu && !that.isGenerateChangeTask) {
              that.vpath1 = that.dpath
            }
            that.koujian('4')
            that.isLoadingXiaoguo = false;
          }
        }, 8000)
      } catch (error) {
        console.error('Error generating model:', error);
      }
    },
    async AIGenerate4(value) {
      try {
        this.select_id='';
        this.paidui = true;
        // 防止过快点击过次生成
        this.wenshengCount += 1;
        if (this.wenshengCount !== 1) {
          this.$message.warning('任务正在生成中，请稍后');
          return;
        }
        var that = this
        this.vpath1 = ''
        this.saveHistoryIndex1 = null;
        this.isGenerateChangeMenu = false;
        this.isGenerateChangeTask = false;
        var tresponse1 = await apiClient.get('/getcishu', {
          params: {
            username: localStorage.username,
          }
        });
        var train = tresponse1.data.data[0].userCoins
        train = parseInt(train)
        if (train < 4) {
          this.$message.error(this.$t('taocanyiyongwan'))
          this.showModal = true
          return false
        }
        this.isLoadingXiaoguo = true;
        this.loadingText = this.$t('aituxingloading');
        const requestData = {
          promptText: value.prompt,
          color: value.yanse,
          categoryAndName: value.danpinpinlei,
          gender: value.xingbie,
          fabric: value.mianfuliao,
        };
        value.ali_content = JSON.stringify(requestData);
        value.module = this.currentMenu;
        value.ip = 'ali'
        that.insertHistory(value);
        setTimeout(() => {
          value.id = that.max_id;
        }, 2000)
        // var t1 = setInterval(async function () {
        //   try {
        //     var tresponse1 = await apiClient.get('/getHistory', {
        //       params: {
        //         id: value.id ? value.id : that.max_id,
        //       },
        //     });
        //     if (tresponse1.data.data.length == 0) {
        //       clearInterval(t1);
        //       return false;
        //     }
        //     var data = tresponse1.data.data[0];
        //     if (data.imgJieguo != '' && data.is_complete == '完成') {
        //       clearInterval(t1)
        //       value.imgJieguo = data.imgJieguo;
        //       if (value.module == that.currentMenu && !that.isGenerateChangeMenu && !that.isGenerateChangeTask && value.id == that.select_id) {
        //         that.vpath1 = data.imgJieguo
        //         that.historyBack = value;
        //       }
        //       that.koujian('2', value)
        //       if (value.module == that.currentMenu && value.id == that.select_id) {
        //         that.isLoadingXiaoguo = false;
        //       }
        //
        //       if (!that.vpath1 && value.id == that.select_id) {
        //         that.vpath1 = data.imgJieguo
        //         that.historyBack = value;
        //         const showimg = that.historyAll.find(it => it.id === value.id);
        //         that.jieguoTime = showimg.create_time;
        //       }
        //     } else if (data.is_complete == '失败') {
        //       that.failMessage(data,value);
        //       clearInterval(t1)
        //     }
        //   } catch (error) {
        //     console.log(error);
        //     that.errorMessage(value);
        //     clearInterval(t1)
        //   }
        // }, 8000)
      } catch (e) {
        this.wenshengCount=0;
        this.wrongMessage();
      }
    },
    async AIGenerate3(value) {
      try {
        this.select_id='';
        this.paidui = true;
        // 防止过快点击过次生成
        this.gaikuanCount += 1;
        if (this.gaikuanCount !== 1) {
          this.$message.warning('任务正在生成中，请稍后');
          return;
        }
        var that = this
        this.vpath1 = ''
        this.saveHistoryIndex1 = null;
        this.isGenerateChangeMenu = false;
        this.isGenerateChangeTask = false;
        var tresponse1 = await apiClient.get('/getcishu', {
          params: {
            username: localStorage.username,
          }
        });
        var train = tresponse1.data.data[0].userCoins
        train = parseInt(train)
        if (train < 4) {
          this.$message.error(this.$t('taocanyiyongwan'))
          this.showModal = true
          return false
        }
        this.isLoadingXiaoguo = true;
        this.loadingText = this.$t('aituxingloading');
        const requestData = {
          promptText: value.prompt,
          color: value.yanse,
          categoryAndName: value.danpinpinlei,
          gender: value.xingbie,
          fabric: value.mianfuliao,
          Strength: value.yuantuxiangsi,
          InputImage: this.getOriginalUrl(value.img1),
        };
        value.ali_content=JSON.stringify(requestData);
        value.module=this.currentMenu;
        value.ip='ali'
        that.insertHistory(value);

        setTimeout(()=>{
          value.id=that.max_id;
        },2000)
        // var t1 = setInterval(async function () {
        //   try {
        //     var tresponse1 = await apiClient.get('/getHistory', {
        //       params: {
        //         id: value.id ? value.id : that.max_id,
        //       },
        //     });
        //     if(tresponse1.data.data.length==0){
        //       clearInterval(t1);
        //       return  false;
        //     }
        //     var data = tresponse1.data.data[0];
        //     if (data.imgJieguo != '' && data.is_complete == '完成') {
        //       clearInterval(t1)
        //       value.imgJieguo = data.imgJieguo;
        //       if (value.module==that.currentMenu&&!that.isGenerateChangeMenu && !that.isGenerateChangeTask&&value.id==that.select_id) {
        //         that.vpath1 = data.imgJieguo
        //         that.historyBack = value;
        //       }
        //       that.koujian('2', value)
        //       if (value.module==that.currentMenu&&value.id==that.select_id){
        //         that.isLoadingXiaoguo = false;
        //       }
        //
        //       if(!that.vpath1&&value.id==that.select_id){
        //         that.vpath1 = data.imgJieguo
        //         that.historyBack = value;
        //         const showimg = that.historyAll.find(it => it.id === value.id);
        //         that.jieguoTime = showimg.create_time;
        //       }
        //     } else if (data.is_complete == '失败') {
        //       that.failMessage(data,value);
        //       clearInterval(t1)
        //     }
        //   } catch (error) {
        //     console.log(error);
        //     that.errorMessage(value);
        //     clearInterval(t1)
        //   }
        // }, 8000)
      }catch (e) {
        this.gaikuanCount=0;
        this.wrongMessage();
      }
    },
    async AIGenerate6(value) {
      try {
        this.select_id='';
        this.paidui = true;
        // 防止过快点击过次生成
        this.xiangaoCount += 1;
        if (this.xiangaoCount !== 1) {
          this.$message.warning('任务正在生成中，请稍后');
          return;
        }
        var that = this
        this.vpath1 = ''
        this.saveHistoryIndex1 = null;
        this.isGenerateChangeMenu = false;
        this.isGenerateChangeTask = false;
        var tresponse1 = await apiClient.get('/getcishu', {
          params: {
            username: localStorage.username,
          }
        });
        var train = tresponse1.data.data[0].userCoins
        train = parseInt(train)
        if (train < 4) {
          this.$message.error(this.$t('taocanyiyongwan'))
          this.showModal = true
          return false
        }
        this.isLoadingXiaoguo = true;
        this.loadingText = this.$t('aituxingloading');
        const requestData = {
          promptText: value.prompt,
          color: value.yanse,
          categoryAndName: value.danpinpinlei,
          gender: value.xingbie,
          fabric: value.mianfuliao,
          Strength: value.yuantuxiangsi,
          InputImage: this.getOriginalUrl(value.img1),
        };
        value.ali_content = JSON.stringify(requestData);
        value.module = this.currentMenu;
        value.ip = 'tencent'
        that.insertHistory(value);
        setTimeout(() => {
          value.id = that.max_id;
        }, 2000)
        // var t1 = setInterval(async function () {
        //   try {
        //     var tresponse1 = await apiClient.get('/getHistory', {
        //       params: {
        //         id: value.id ? value.id : that.max_id,
        //       },
        //     });
        //     if (tresponse1.data.data.length == 0) {
        //       clearInterval(t1);
        //       return false;
        //     }
        //     var data = tresponse1.data.data[0];
        //     if (data.imgJieguo != '' && data.is_complete == '完成') {
        //       clearInterval(t1)
        //       value.imgJieguo = data.imgJieguo;
        //       if (value.module == that.currentMenu && !that.isGenerateChangeMenu && !that.isGenerateChangeTask && value.id == that.select_id) {
        //         that.vpath1 = data.imgJieguo
        //         that.historyBack = value;
        //       }
        //       that.koujian('2', value)
        //       if (value.module == that.currentMenu && value.id == that.select_id) {
        //         that.isLoadingXiaoguo = false;
        //       }
        //
        //       if (!that.vpath1 && value.id == that.select_id) {
        //         that.vpath1 = data.imgJieguo
        //         that.historyBack = value;
        //         const showimg = that.historyAll.find(it => it.id === value.id);
        //         that.jieguoTime = showimg.create_time;
        //       }
        //     } else if (data.is_complete == '失败') {
        //       that.failMessage(data,value);
        //       clearInterval(t1)
        //     }
        //   } catch (error) {
        //     console.log(error);
        //     that.errorMessage(value);
        //     clearInterval(t1)
        //   }
        // }, 8000)
      } catch (e) {
        this.xiangaoCount=0;
        this.wrongMessage();
      }

    },
    async AIGenerate5(value) {
      try {
        this.select_id='';
        this.paidui = true;
        // 防止过快点击过次生成
        this.huaxingCount += 1;
        if (this.huaxingCount !== 1) {
          this.$message.warning('任务正在生成中，请稍后');
          return;
        }
        var that = this
        this.vpath1 = ''
        this.saveHistoryIndex1 = null;
        this.isGenerateChangeMenu = false;
        this.isGenerateChangeTask = false;
        var tresponse1 = await apiClient.get('/getcishu', {
          params: {
            username: localStorage.username,
          }
        });
        var train = tresponse1.data.data[0].userCoins
        train = parseInt(train)
        if (train < 4) {
          this.$message.error(this.$t('taocanyiyongwan'))
          this.showModal = true
          return false
        }
        this.isLoadingXiaoguo = true;
        this.loadingText = this.$t('aituxingloading');
        const requestData = {
          promptText: value.prompt,
          Strength: value.yuantuxiangsi,
          InputImage: this.getOriginalUrl(value.img1),
        };
        value.ali_content = JSON.stringify(requestData);
        value.module = this.currentMenu;
        value.ip = 'ali'
        that.insertHistory(value);
        setTimeout(() => {
          value.id = that.max_id;
        }, 2000)
        // var t1 = setInterval(async function () {
        //   try {
        //     var tresponse1 = await apiClient.get('/getHistory', {
        //       params: {
        //         id: value.id ? value.id : that.max_id,
        //       },
        //     });
        //     if (tresponse1.data.data.length == 0) {
        //       clearInterval(t1);
        //       return false;
        //     }
        //     var data = tresponse1.data.data[0];
        //     if (data.imgJieguo != '' && data.is_complete == '完成') {
        //       clearInterval(t1)
        //       value.imgJieguo = data.imgJieguo;
        //       if (value.module == that.currentMenu && !that.isGenerateChangeMenu && !that.isGenerateChangeTask && value.id == that.select_id) {
        //         that.vpath1 = data.imgJieguo
        //         that.historyBack = value;
        //       }
        //       that.koujian('2', value)
        //       if (value.module == that.currentMenu && value.id == that.select_id) {
        //         that.isLoadingXiaoguo = false;
        //       }
        //
        //       if (!that.vpath1 && value.id == that.select_id) {
        //         that.vpath1 = data.imgJieguo
        //         that.historyBack = value;
        //         const showimg = that.historyAll.find(it => it.id === value.id);
        //         that.jieguoTime = showimg.create_time;
        //       }
        //     } else if (data.is_complete == '失败') {
        //       that.failMessage(data,value);
        //       clearInterval(t1)
        //     }
        //   } catch (error) {
        //     console.log(error);
        //     that.errorMessage(value);
        //     clearInterval(t1)
        //   }
        // }, 8000)
      } catch (e) {
        this.huaxingCount=0;
        this.wrongMessage();
      }
    },
    async AIGenerate1(value) {
      this.select_id='';
      this.paidui = true;
      // 防止过快点击过次生成
      this.moteCount += 1;
      if (this.moteCount !== 1) {
        this.$message.warning('任务正在生成中，请稍后');
        return;
      }
      var that = this
      this.vpath1 = ''
      this.saveHistoryIndex1 = null;
      this.isGenerateChangeMenu = false;
      this.isGenerateChangeTask = false;
      var tresponse1 = await apiClient.get('/getcishu', {
        params: {
          username: localStorage.username,
        }
      });
      var train = tresponse1.data.data[0].userCoins
      train = parseInt(train)
      if (train < 2) {
        this.$message.error(this.$t('taocanyiyongwan'))
        this.showModal = true
        return false
      }
      this.isLoadingXiaoguo = true;
      this.loadingText = this.$t('aituxingloading');
      try {
        let params= {
          continent: value.diqu,
          gender: value.xingbie,
          ageRange: value.nianling,
          expression: value.xiaorong,
          prompts: value.prompt,
        }
        value.ali_content=JSON.stringify(params);

        value.ip='ali';
        value.module=this.currentMenu;
        that.insertHistory(value);
        setTimeout(()=>{
          value.id=that.max_id;
        },2000)
        // var t1 = setInterval(async function () {
        //   try {
        //     var tresponse1 = await apiClient.get('/getHistory', {
        //       params: {
        //         id: value.id ? value.id : that.max_id,
        //       },
        //     });
        //     if(tresponse1.data.data.length==0){
        //       clearInterval(t1);
        //       return  false;
        //     }
        //     var data = tresponse1.data.data[0];
        //     if (data.imgJieguo != '' && data.is_complete == '完成') {
        //       clearInterval(t1)
        //       value.imgJieguo = data.imgJieguo;
        //       if (value.module==that.currentMenu&&!that.isGenerateChangeMenu && !that.isGenerateChangeTask&&value.id==that.select_id) {
        //         that.vpath1 = data.imgJieguo
        //         that.historyBack = value;
        //       }
        //
        //       that.dpath = data.imgJieguo
        //       that.moteCount = 0;
        //       that.koujian('3',value);
        //       if (value.module==that.currentMenu&&value.id==that.select_id){
        //         that.isLoadingXiaoguo = false;
        //       }
        //
        //       if(!that.vpath1&&value.id==that.select_id){
        //         that.vpath1 = data.imgJieguo
        //         that.historyBack = value;
        //         const showimg = that.historyAll.find(it => it.id === value.id);
        //         that.jieguoTime = showimg.create_time;
        //       }
        //     } else if (data.is_complete == '失败') {
        //       that.failMessage(data,value);
        //       clearInterval(t1)
        //     }
        //   } catch (error) {
        //     console.log(error);
        //     that.errorMessage(value);
        //     clearInterval(t1)
        //   }
        // }, 8000)

      } catch (error) {
        this.moteCount=0;
        this.wrongMessage();
      }
    },
    async AIGenerate(value) {
      try {
        this.select_id='';
        var that = this
        this.saveHistoryIndex1 = null;
        this.isGenerateChangeMenu = false;
        this.isGenerateChangeTask = false;
        this.jieguoTime = '';
        this.jieguoRemark = '';
        var predefined_face_id = ''
        var face_image_url = ''
        var model_description = ''
        var background_description = ''
        this.vpath = ''
        this.vpath1 = ''
        this.handleEmpty();

        if (this.currentMenu === '2') {
          //人台图
          // 防止过快点击过次生成
          this.paidui = true;
          this.rentaiCount += 1;
          if (this.rentaiCount !== 1) {
            this.$message.warning('排队中~请等待2秒再继续操作');
            return;
          }
          var tresponse1 = await apiClient.get('/getcishu', {
            params: {
              username: localStorage.username,
            }
          });
          var train = tresponse1.data.data[0].userCoins
          train = parseInt(train)
          if (train < 4) {
            this.$message.error(this.$t('taocanyiyongwan'))
            this.showModal = true
            return false
          }
          if (value.saveMote.indexOf("http") > -1) {
            this.isLoadingXiaoguo = true;
            this.loadingText = this.$t('aituxingloading');
            face_image_url = this.getOriginalUrl(value.saveMote)
            this.isLoadingXiaoguo = false;
          } else {
            var eindex = 0
            if (value.saveMote.indexOf('p810525') > -1) {
              eindex = 0
            }
            if (value.saveMote.indexOf('p810527') > -1) {
              eindex = 1
            }
            if (value.saveMote.indexOf('p810528') > -1) {
              eindex = 2
            }
            if (value.saveMote.indexOf('p810526') > -1) {
              eindex = 3
            }
            if (value.saveMote.indexOf('p810522') > -1) {
              eindex = 4
            }
            if (value.saveMote.indexOf('p810524') > -1) {
              eindex = 5
            }
            predefined_face_id = this.defaulttx[eindex]
          }
          var eindex = 0
          if (value.saveMote.indexOf('p810525') > -1) {
            eindex = 0
          }
          if (value.saveMote.indexOf('p810527') > -1) {
            eindex = 1
          }
          if (value.saveMote.indexOf('p810528') > -1) {
            eindex = 2
          }
          if (value.saveMote.indexOf('p810526') > -1) {
            eindex = 3
          }
          if (value.saveMote.indexOf('p810522') > -1) {
            eindex = 4
          }
          if (value.saveMote.indexOf('p810524') > -1) {
            eindex = 5
          }
          model_description = this.defaulttx[eindex]
          var eindex = 0
          if (value.saveChangjing.indexOf('pengpai') > -1) {
            eindex = 0
          }
          if (value.saveChangjing.indexOf('jiepai') > -1) {
            eindex = 1
          }
          if (value.saveChangjing.indexOf('hai') > -1) {
            eindex = 2
          }
          if (value.saveChangjing.indexOf('baise') > -1) {
            eindex = 3
          }
          if (value.saveChangjing.indexOf('heise') > -1) {
            eindex = 4
          }
          if (value.saveChangjing.indexOf('huise') > -1) {
            eindex = 5
          }
          if (value.saveChangjing.indexOf('oushi') > -1) {
            eindex = 6
          }
          if (value.saveChangjing.indexOf('rishi') > -1) {
            eindex = 7
          }
          if (value.saveChangjing.indexOf('zhongshi') > -1) {
            eindex = 8
          }
          if (value.saveChangjing.indexOf('senlin') > -1) {
            eindex = 9
          }
          if (value.saveChangjing.indexOf('gaoshan') > -1) {
            eindex = 10
          }
          if (value.saveChangjing.indexOf('yinghua') > -1) {
            eindex = 11
          }
          if (value.saveChangjing.indexOf('gongyuan') > -1) {
            eindex = 12
          }
          if (value.saveChangjing.indexOf('xiuchang') > -1) {
            eindex = 13
          }
          if (value.saveChangjing.indexOf('nongchang') > -1) {
            eindex = 14
          }
          if (value.saveChangjing.indexOf('cao') > -1) {
            eindex = 15
          }
          if (value.saveChangjing.indexOf('juhui') > -1) {
            eindex = 16
          }
          if (value.saveChangjing.indexOf('yongchi') > -1) {
            eindex = 17
          }
          if (value.saveChangjing.indexOf('jianshen') > -1) {
            eindex = 18

          }
          if (value.saveChangjing.indexOf('tushu') > -1) {
            eindex = 19
          }
          if (value.saveChangjing.indexOf('taikong') > -1) {
            eindex = 20
          }
          if (value.saveChangjing.indexOf('matou') > -1) {
            eindex = 21
          }
          if (value.saveChangjing.indexOf('jujia') > -1) {
            eindex = 22
          }
          if (value.saveChangjing.indexOf('jiao') > -1) {
            eindex = 23
          }
          if (value.saveChangjing.indexOf('youle') > -1) {
            eindex = 24
          }
          if (value.saveChangjing.indexOf('duocai') > -1) {
            eindex = 25
          }
          if (value.saveChangjing.indexOf('xuejing') > -1) {
            eindex = 26
          }
          if (value.saveChangjing.indexOf('ditie') > -1) {
            eindex = 27
          }
          if (value.saveChangjing.indexOf('cheku') > -1) {
            eindex = 28
          }
          if (value.saveChangjing.indexOf('louti') > -1) {
            eindex = 29
          }
          if (value.saveChangjing.indexOf('tuiya') > -1) {
            eindex = 30
          }
          if (value.saveChangjing.indexOf('jietou') > -1) {
            eindex = 31
          }
          if (value.saveChangjing.indexOf('zhantai') > -1) {
            eindex = 32
          }
          if (value.saveChangjing.indexOf('oushiwoshi') > -1) {
            eindex = 33
          }
          if (value.saveChangjing.indexOf('inswoshi') > -1) {
            eindex = 34
          }
          var myindex = 0
          if (value.saveFaxing.indexOf('faxing1') > -1) {
            myindex = 0
          }
          if (value.saveFaxing.indexOf('faxing2') > -1) {
            myindex = 1
          }
          if (value.saveFaxing.indexOf('faxing3') > -1) {
            myindex = 2
          }
          if (value.saveFaxing.indexOf('faxing4') > -1) {
            myindex = 3
          }
          if (value.saveFaxing.indexOf('faxing5') > -1) {
            myindex = 4
          }
          if (value.saveFaxing.indexOf('faxing6') > -1) {
            myindex = 5
          }
          if (value.saveFaxing.indexOf('faxing7') > -1) {
            myindex = 6
          }
          if (value.saveFaxing.indexOf('faxing8') > -1) {
            myindex = 7
          }
          if (value.saveFaxing.indexOf('faxing9') > -1) {
            myindex = 8
          }
          if (value.saveFaxing.indexOf('faxing10') > -1) {
            myindex = 9
          }
          if (value.saveFaxing.indexOf('faxing11') > -1) {
            myindex = 10
          }
          if (value.saveFaxing.indexOf('faxing12') > -1) {
            myindex = 11
          }
          if (value.saveFaxing.indexOf('faxing13') > -1) {
            myindex = 12
          }
          if (value.saveFaxing.indexOf('faxing14') > -1) {
            myindex = 13
          }
          if (value.saveFaxing.indexOf('faxing15') > -1) {
            myindex = 14
          }
          var hairstyles = this.hairstyles[myindex]
          var eindex = 0
          if (value.saveBiaoqing.indexOf('biaoqing1') > -1) {
            eindex = 0
          }
          if (value.saveBiaoqing.indexOf('biaoqing2') > -1) {
            eindex = 1
          }
          if (value.saveBiaoqing.indexOf('biaoqing3') > -1) {
            eindex = 2
          }
          if (value.saveBiaoqing.indexOf('biaoqing4') > -1) {
            eindex = 3
          }
          if (value.saveBiaoqing.indexOf('biaoqing5') > -1) {
            eindex = 4
          }
          if (value.saveBiaoqing.indexOf('biaoqing6') > -1) {
            eindex = 5
          }
          if (value.saveBiaoqing.indexOf('biaoqing7') > -1) {
            eindex = 6
          }
          var biaoqing = this.biaoqinglist[eindex]
          this.isLoadingXiaoguo = true;
          this.loadingText = this.$t('aituxingloading');
          background_description = this.changjinglist[eindex]
          var background_image_url = '';
          if (value.saveChangjing && value.saveChangjing !== '') {
            background_image_url =this.getOriginalUrl(value.saveChangjing);
          }

          const params = {
            base_image_url: this.getOriginalUrl(value.originalUrl),
            mask_image_url: this.getOriginalUrl(value.cutdownUrl),
            predefined_face_id: predefined_face_id,
            background_image_url: background_image_url,
            face_image_url: face_image_url,
            model_description: model_description,
            background_description: background_description,
            aspect_ratio: value.radio === that.$t('yuanshi') ? '1:1' : value.radio,
            hairstyle_description: hairstyles,
            biaoqing: biaoqing
          };
          value.ali_content = JSON.stringify(params);
          value.img1 = value.originalUrl;
          value.models = value.saveMote;
          value.img1k = value.cutdownUrl;
          value.faxing = value.saveFaxing;
          value.biaoqing = value.saveBiaoqing;
          value.changjing = value.saveChangjing;
          value.bili = value.radio;
          value.module = this.currentMenu;
          value.ip = 'ali';
          that.insertHistory(value);
          setTimeout(() => {
            value.id = that.max_id;
          }, 2000)

          // var t1 = setInterval(async function () {
          //   try {
          //     var tresponse1 = await apiClient.get('/getHistory', {
          //       params: {
          //         id: value.id ? value.id : that.max_id,
          //       },
          //     });
          //     if (tresponse1.data.data.length == 0) {
          //       clearInterval(t1);
          //       return false;
          //     }
          //     var data = tresponse1.data.data[0];
          //     if (data.imgJieguo != '' && data.is_complete == '完成') {
          //       clearInterval(t1)
          //       value.imgJieguo = data.imgJieguo;
          //       if (value.module == that.currentMenu && !that.isGenerateChangeMenu && !that.isGenerateChangeTask && value.id == that.select_id) {
          //         that.vpath1 = data.imgJieguo
          //         that.historyBack = value;
          //       }
          //       that.koujian('2', value)
          //       if (value.module == that.currentMenu && value.id == that.select_id) {
          //         that.isLoadingXiaoguo = false;
          //       }
          //
          //       if (!that.vpath1 && value.id == that.select_id) {
          //         that.vpath1 = data.imgJieguo
          //         that.historyBack = value;
          //         const showimg = that.historyAll.find(it => it.id === value.id);
          //         that.jieguoTime = showimg.create_time;
          //       }
          //     } else if (data.is_complete == '失败') {
          //       that.failMessage(data, value);
          //       clearInterval(t1)
          //     }
          //   } catch (error) {
          //     console.log(error);
          //     that.errorMessage(value);
          //     clearInterval(t1)
          //   }
          // }, 8000)
        }
        if (this.currentMenu === '4') {
          // 商品图
          // 防止过快点击过次生成
          this.paidui = true;
          this.shangpinCount += 1;
          if (this.shangpinCount !== 1) {
            this.$message.warning('任务正在生成中，请稍后');
            return;
          }
          var tresponse1 = await apiClient.get('/getcishu', {
            params: {
              username: localStorage.username,
            }
          });
          var train = tresponse1.data.data[0].userCoins
          train = parseInt(train)
          if (train < 2) {
            this.$message.error(this.$t('taocanyiyongwan'))
            this.showModal = true
            return false
          }
          this.isLoadingXiaoguo = true;
          this.loadingText = this.$t('aituxingloading');
          let params = {
            baseImageUrl: this.getOriginalUrl(value.img1k),
            refImageUrl: this.getOriginalUrl(value.changjing),
            refPrompt: value.prompt,
            n: 1,
            noiseLevel: 350,
            refPromptWeight: 0.3,
            sceneType: 'COSMETIC'
          }
          value.ali_content = JSON.stringify(params);

          value.ip = 'ali';
          value.module = this.currentMenu;
          that.insertHistory(value);
          setTimeout(() => {
            value.id = that.max_id;
          }, 2000)

          // var t1 = setInterval(async function () {
          //   try {
          //     var tresponse1 = await apiClient.get('/getHistory', {
          //       params: {
          //         id: value.id ? value.id : that.max_id,
          //       },
          //     });
          //     if (tresponse1.data.data.length == 0) {
          //       clearInterval(t1);
          //       return false;
          //     }
          //     var data = tresponse1.data.data[0];
          //     if (data.imgJieguo != '' && data.is_complete == '完成') {
          //       clearInterval(t1)
          //       value.imgJieguo = data.imgJieguo;
          //       if (value.module == that.currentMenu && !that.isGenerateChangeMenu && !that.isGenerateChangeTask && value.id == that.select_id) {
          //         that.vpath1 = data.imgJieguo;
          //         that.historyBack = value;
          //       }
          //
          //       that.shangpinCount = 0;
          //       that.koujian('5', value)
          //       if (value.module == that.currentMenu && value.id == that.select_id) {
          //         that.isLoadingXiaoguo = false;
          //       }
          //
          //       if (!that.vpath1 && value.id == that.select_id) {
          //         that.vpath1 = data.imgJieguo;
          //         that.historyBack = value;
          //         const showimg = that.historyAll.find(it => it.id === value.id);
          //         that.jieguoTime = showimg.create_time;
          //       }
          //     } else if (data.is_complete == '失败') {
          //       that.failMessage(data,value);
          //       clearInterval(t1)
          //     }
          //   } catch (error) {
          //     console.log(error);
          //     that.errorMessage(value);
          //     clearInterval(t1)
          //   }
          // }, 8000)
        }
        if (this.currentMenu === '6') {
          //鞋子上脚
          this.paidui = true;
          // 防止过快点击过次生成
          this.xieziCount += 1;
          if (this.xieziCount !== 1) {
            this.$message.warning('任务正在生成中，请稍后');
            return;
          }
          var tresponse1 = await apiClient.get('/getcishu', {
            params: {
              username: localStorage.username,
            }
          });
          var train = tresponse1.data.data[0].userCoins
          train = parseInt(train)
          if (train < 2) {
            this.$message.error(this.$t('taocanyiyongwan'))
            this.showModal = true
            return false
          }
          var face_image_url = value.moteurl
          this.isLoadingXiaoguo = true;
          this.loadingText = this.$t('aituxingloading');
          const params = {
            template_image_url: this.getOriginalUrl(face_image_url),
            shoe_image_url: this.getOriginalUrl(value.img1k)
          };
          value.ali_content = JSON.stringify(params);
          value.module = this.currentMenu;
          value.ip = 'ali';
          that.insertHistory(value);
          setTimeout(() => {
            value.id = that.max_id;
          }, 2000)
          // var t1 = setInterval(async function () {
          //   try {
          //     var tresponse1 = await apiClient.get('/getHistory', {
          //       params: {
          //         id: value.id ? value.id : that.max_id,
          //       },
          //     });
          //     if (tresponse1.data.data.length == 0) {
          //       clearInterval(t1);
          //       return false;
          //     }
          //     var data = tresponse1.data.data[0];
          //     if (data.imgJieguo != '' && data.is_complete == '完成') {
          //       clearInterval(t1)
          //       value.imgJieguo = data.imgJieguo;
          //       if (value.module == that.currentMenu && !that.isGenerateChangeMenu && !that.isGenerateChangeTask && value.id == that.select_id) {
          //         that.vpath1 = data.imgJieguo
          //         that.historyBack = value;
          //       }
          //
          //       that.koujian('6', value)
          //       if (value.module == that.currentMenu && value.id == that.select_id) {
          //         that.isLoadingXiaoguo = false;
          //       }
          //
          //       if (!that.vpath1 && value.id == that.select_id) {
          //         that.vpath1 = data.imgJieguo
          //         that.historyBack = value;
          //         const showimg = that.historyAll.find(it => it.id === value.id);
          //         that.jieguoTime = showimg.create_time;
          //       }
          //     } else if (data.is_complete == '失败') {
          //       that.failMessage(data,value);
          //       clearInterval(t1)
          //     }
          //   } catch (error) {
          //     console.log(error);
          //     that.errorMessage(value);
          //     clearInterval(t1)
          //   }
          // }, 8000)
        }
      } catch (e) {
        console.log(e);
        this.rentaiCount=0;
        this.xieziCount=0;
        this.shangpinCount=0;
        this.wrongMessage();
      }
    },
    async gotohuanzhuang() {
      this.$message.warning("选择该训练结果，用作换装功能～")
      if (Number(this.currentMenu) <= 3) {
        this.imgPreviewVisible = false;
      } else {
        this.imgPreviewVisible2 = false;
      }
      this.currentMenu = '1'
      this.moteurl = this.vpath1
      this.historyBack.models2 = this.vpath1;
      this.vpath1 = '';
      const historyList = await this.getHistoryList('1');
      if(this.currentMenu=='1'){
        this.historyList = historyList;
      }
    },
    // 所有模块加完历史任务后删除此方法
    clearAllCount() {
      this.shangpinCount = 0;
      this.moteCount = 0;
      this.gaikuanCount = 0;
      this.wenshengCount = 0;
      this.huaxingCount = 0;
      this.xiangaoCount = 0;
    },
    selectMenu1(){
      if(this.paidui){
        this.$message.warning('排队中~请等待2秒再继续操作');
        this.$refs.cdmenu.activeIndex = this.currentMenu;
        return  false;
      }
    },
    async selectMenu(index) {

      if(this.paidui){
        this.$message.warning('排队中~请等待2秒再继续操作');
        this.$refs.cdmenu.activeIndex = this.currentMenu;
        return  false;
      }
      this.historyList = [];
      this.historyBack = {};
      this.jieguoTime='';
      this.jieguoRemark='';
      this.saveHistoryIndex1 = null;
      if (this.isLoadingXiaoguo) {
        this.isGenerateChangeMenu = true;
        this.clearAllCount();
      }
      this.vpath1 = ''
      this.select_id=''
      this.isLoadingXiaoguo = false
      this.moteurl = window._CONFIG['domianURL']+'moren/weishangchuan.png'
      // alert(index)

      this.currentMenu = index;
      const historyList = await this.getHistoryList(index);
      if(this.currentMenu==index){
        this.historyList = historyList;
        //过滤出生成中数据
        this.panduanList=this.historyList.filter(item => item.is_running == '1');
      }
      this.dingshi();
      this.saveHistoryIndex = null;
    },
    checkAllResourcesLoaded() {
      // 在这里可以添加更多判断条件
      // 如图片加载、异步数据请求等
      this.allResourcesLoaded = true;
      this.myloading.close()
    },
    zoomImage() {
      this.scalePercent = 100;
      if (Number(this.currentMenu) <= 3||this.currentMenu=='6'||this.currentMenu=='4'||this.currentMenu=='8'||this.currentMenu=='10'||this.currentMenu=='11') {
        this.imgPreviewVisible = true;
        setTimeout(() => {
          const panzoomElement = this.$refs.panzoomElement;
          // 初始化 Panzoom 实例
          this.panzoomInstance = Panzoom(panzoomElement, {
            maxScale: 3,
            minScale: 0.5,
          });
        }, 1000)
      } else {
        this.imgPreviewVisible2 = true;
        setTimeout(() => {
          const panzoomElement = this.$refs.panzoomElement2;
          // 初始化 Panzoom 实例
          this.panzoomInstance = Panzoom(panzoomElement, {
            maxScale: 3,
            minScale: 0.5,
          });
          // panzoomElement.addEventListener('wheel', this.panzoomInstance.zoomWithWheel);
        }, 1000)
      }

    },
    async downloadImage() {
      const response = await axios.get(this.vpath1, {
        responseType: 'blob'
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'image.png'); // Specify the file name
      document.body.appendChild(link);
      link.click();
    },
    async koujian(mystyle,value,type) {
      // const response = await apiClient.get('/insertxiaofei', {
      //   params: {
      //     userId: localStorage.username,
      //     type: 'image_change',
      //     details: 'AI换装',
      //     mystyle: mystyle
      //   },
      //   headers: {
      //     'x-access-token': localStorage.token
      //   },
      //
      // });
      eventBus.$emit('koujian');

        if(value){
          this.updateHistory(value);
        }else{
          this.updateHistory(this.historyBack);
        }




    },
    async insertHistory(history) {
      const banxing = history.unitType ? history.unitType : history.banxing;
      if(this.currentMenu=='1'){
        // 向历史中插入
        const obj = {
          module: history.module, //菜单
          img1: history.img1, //原图1
          img2: history.img2, //原图2
          img3: history.img3, //原图3
          type: history.type, //单件/多件
          models: history.moteurl, //使用的模特
          posture: history.posture, //模特库模特/姿势
          models2: history.models2, //自定义上传模特
          banxing: banxing || '', //上装/下装/连体衣
          img1k: history.img1k, //保留区1
          img2k: history.img2k, //保留区2
          img3k: history.img3k, //保留区3
          imgJieguo:'',
          imgJieguo_change:history.imgJieguo_change,
          fuwuqi:!history.fuwuqi?'1':history.fuwuqi,
          is_running:'1',
          money:'10',
          ip:history.ip,
          client_id:history.client_id,
          user:localStorage.username,
        };
        const response1 = await apiClient.post('/insertHistory', obj);
        if (response1.status === 200) {
          this.max_id=response1.data.lastID;
          this.huanyi1Count = 0;
          this.huanyiCount = 0;
          const historyList = await this.getHistoryList(this.currentMenu);
          this.historyList = historyList;
          this.dingshi();
        }
      }
      if(this.currentMenu=='2'){
        const obj = {
          module: history.module,
          img1: history.img1,
          models: history.models,
          img1k: history.img1k,
          faxing: history.faxing,
          biaoqing: history.biaoqing,
          changjing: history.changjing,
          bili: history.bili,
          imgJieguo:'',
          fuwuqi:!history.fuwuqi?'rentai':history.fuwuqi,
          ali_content:history.ali_content,
          is_running:'1',
          client_id:history.client_id,
          money:'4',
          ip:history.ip,
          user:localStorage.username,
          imgJieguo_change:history.imgJieguo_change,
        };
        const response1 = await apiClient.post('/insertHistory', obj);
        console.log(response1);
        if (response1.status === 200) {
          this.max_id=response1.data.lastID;
          this.rentaiCount = 0;
          const historyList = await this.getHistoryList(this.currentMenu);
          this.historyList = historyList;
          this.dingshi();
        }
      }
      if (this.currentMenu=='3') {
        const obj = {
          type:history.type,
          module: history.module,
          img1: history.img1,
          models: history.models,
          img1k: history.img1k,
          changjing: history.changjing,
          imgJieguo:'',
          fuwuqi:!history.fuwuqi?'huanlian':history.fuwuqi,
          is_running:'1',
          client_id:history.client_id,
          money:'4',
          ip:history.ip,
          user:localStorage.username,
          imgJieguo_change:history.imgJieguo_change,
        };
        const response1 = await apiClient.post('/insertHistory', obj);
        if (response1.status === 200) {
          this.max_id=response1.data.lastID;
          this.zhenrenCount = 0;
          const historyList = await this.getHistoryList(this.currentMenu);
          this.historyList = historyList;
          this.dingshi();
        }
      }
      if (Number(this.currentMenu) === 4) {
        const obj = {
          module: history.module,
          img1: history.img1,
          img1k: history.img1k,
          changjing: history.changjing,
          bili: history.bili,
          prompt: history.prompt,
          imgJieguo:'',
          fuwuqi:!history.fuwuqi?'shangpin':history.fuwuqi,
          is_running:'1',
          client_id:history.client_id,
          money:'2',
          ip:history.ip,
          user:localStorage.username,
          imgJieguo_change:history.imgJieguo_change,
          ali_content:history.ali_content
        };
        const response1 = await apiClient.post('/insertHistory', obj);
        if (response1.status === 200) {
          this.max_id=response1.data.lastID;
          this.shangpinCount = 0;
          const historyList = await this.getHistoryList(this.currentMenu);
          this.historyList = historyList;
          this.dingshi();
        }
      }
      if (Number(this.currentMenu) === 5) {
        const obj = {
          module: history.module,
          diqu: history.diqu,
          xingbie: history.xingbie,
          nianling: history.nianling,
          xiaorong: history.xiaorong,
          prompt: history.prompt,
          imgJieguo:'',
          fuwuqi:!history.fuwuqi?'motexunlian':history.fuwuqi,
          is_running:'1',
          client_id:history.client_id,
          money:'2',
          ip:history.ip,
          ali_content:history.ali_content,
          imgJieguo_change:history.imgJieguo_change,
          user:localStorage.username,
        };
        const response1 = await apiClient.post('/insertHistory', obj);
        if (response1.status === 200) {
          this.max_id=response1.data.lastID;
          this.moteCount = 0;
          const historyList = await this.getHistoryList(this.currentMenu);
          this.historyList = historyList;
          this.dingshi();
        }
      }
      if (Number(this.currentMenu) === 6) {
        const obj = {
          module: history.module,
          img1: history.img1,
          img1k: history.img1k,
          posture: history.posture,
          models2: history.models2,
          models: history.moteurl,
          imgJieguo:'',
          fuwuqi:!history.fuwuqi?'xiezi':history.fuwuqi,
          is_running:'1',
          client_id:history.client_id,
          money:'2',
          ip:history.ip,
          user:localStorage.username,
          imgJieguo_change:history.imgJieguo_change,
          ali_content:history.ali_content
        };
        const response1 = await apiClient.post('/insertHistory', obj);
        if (response1.status === 200) {
          this.max_id=response1.data.lastID;
          this.xieziCount = 0;
          const historyList = await this.getHistoryList(this.currentMenu);
          this.historyList = historyList;
          this.dingshi();
        }
      }
      if (Number(this.currentMenu) === 8) {
        const obj = {
          module: history.module,
          img1: history.img1,
          yanse: history.yanse,
          danpinpinlei: history.danpinpinlei,
          xingbie: history.xingbie,
          mianfuliao: history.mianfuliao,
          yuantuxiangsi: history.yuantuxiangsi,
          prompt: history.prompt,
          bili: history.bili,
          fuwuqi:!history.fuwuqi?'fushigaikuan':history.fuwuqi,
          is_running:'1',
          client_id:'',
          imgJieguo:'',
          money:'4',
          ip:history.ip,
          ali_content:history.ali_content,
          imgJieguo_change:history.imgJieguo_change,
          user:localStorage.username,
        };
        const response1 = await apiClient.post('/insertHistory', obj);
        if (response1.status === 200) {
          this.max_id=response1.data.lastID;
          this.gaikuanCount=0;
          const historyList = await this.getHistoryList(this.currentMenu);
          this.historyList = historyList;
          this.dingshi();
        }
      }
      if (Number(this.currentMenu) === 9) {
        const obj = {
          module: history.module,
          yanse: history.yanse,
          danpinpinlei: history.danpinpinlei,
          xingbie: history.xingbie,
          mianfuliao: history.mianfuliao,
          prompt: history.prompt,
          bili: history.bili,
          fuwuqi:!history.fuwuqi?'wenshengkuan':history.fuwuqi,
          is_running:'1',
          imgJieguo:'',
          client_id:'',
          money:'4',
          ip:history.ip,
          imgJieguo_change:history.imgJieguo_change,
          user:localStorage.username,
          ali_content:history.ali_content
        };
        const response1 = await apiClient.post('/insertHistory', obj);
        if (response1.status === 200) {
          this.max_id=response1.data.lastID;
          this.wenshengCount=0;
          const historyList = await this.getHistoryList(this.currentMenu);
          this.historyList = historyList;
          this.dingshi();
        }
      }
      if (Number(this.currentMenu) === 10) {
        const obj = {
          module: history.module,
          img1: history.img1,
          yuantuxiangsi: history.yuantuxiangsi,
          prompt: history.prompt,
          bili: history.bili,
          fuwuqi:!history.fuwuqi?'huaxingyansheng':history.fuwuqi,
          is_running:'1',
          client_id:'',
          imgJieguo:'',
          money:'4',
          ip:history.ip,
          imgJieguo_change:history.imgJieguo_change,
          user:localStorage.username,
          ali_content:history.ali_content
        };
        const response1 = await apiClient.post('/insertHistory', obj);
        if (response1.status === 200) {
          this.max_id=response1.data.lastID;
          this.huaxingCount=0;
          const historyList = await this.getHistoryList(this.currentMenu);
          this.historyList = historyList;
          this.dingshi();
        }
      }
      if (Number(this.currentMenu) === 11) {
        const obj = {
          module: history.module,
          img1: history.img1,
          yuantuxiangsi: history.yuantuxiangsi,
          prompt: history.prompt,
          yanse: history.yanse,
          danpinpinlei: history.danpinpinlei,
          xingbie: history.xingbie,
          mianfuliao: history.mianfuliao,
          bili: history.bili,
          imgJieguo:'',
          fuwuqi:!history.fuwuqi?'xiangao':history.fuwuqi,
          is_running:'1',
          imgJieguo_change:history.imgJieguo_change,
          client_id:'',
          money:'4',
          ip:history.ip,
          user:localStorage.username,
          ali_content:history.ali_content
        };
        const response1 = await apiClient.post('/insertHistory', obj);
        if (response1.status === 200) {
          this.max_id=response1.data.lastID;
          this.xiangaoCount=0;
          const historyList = await this.getHistoryList(this.currentMenu);
          this.historyList = historyList;
          this.dingshi();
        }
      }
      //过滤出生成中数据
      this.panduanList=this.historyList.filter(item => item.is_running == '1');
      this.handleHistory({id: this.max_id}, '1');
      this.paidui=false;
    },
    async updateHistory(history) {
      if(this.currentMenu=='1'||this.currentMenu=='2'||this.currentMenu=='3'|| this.currentMenu=='4' || this.currentMenu == '5' ||this.currentMenu == '6'
        || Number(this.currentMenu) === 8 || Number(this.currentMenu) === 9 || Number(this.currentMenu) === 10 || Number(this.currentMenu) === 11){
        // const response = await apiClient.get('/updateHistory', {
        //   params: {
        //     imgJieguo: history.imgJieguo,
        //     client_id: history.client_id
        //   },
        // });
        // if (response.status === 200) {
          const historyList = await this.getHistoryList(this.currentMenu);
          // if (!this.isGenerateChangeMenu && !this.isGenerateChangeTask) {
          //   this.handleHistory({ id: historyList[0].id })
          // }
          if (history.module==this.currentMenu&&!this.isGenerateChangeMenu && !this.isGenerateChangeTask) {
            this.handleHistory({ id: historyList[0].id })
          }
          this.historyList = historyList;
        // }
      }
    },
    async huianyi1(value) {
      try {
        this.select_id='';
        // 防止过快点击过次生成
        this.huanyi1Count += 1;
        this.paidui = true;
        if (this.huanyi1Count !== 1) {
          // this.$message.error('任务正在生成中，请稍后');
          this.$message.warning('排队中~请等待2秒再继续操作');
          return;
        }
        this.vpath1 = ''
        this.saveHistoryIndex1 = null;
        this.isGenerateChangeMenu = false;
        this.isGenerateChangeTask = false;
        this.handleEmpty();
        var that = this
        if (value.img1k.length == 0) {
          this.$message.error(this.$t('shangchuanliantiyi'))
          return false
        }

        if (value.moteurl.length == 0) {
          this.$message.error(this.$t('qingxuanzemote'))
          return false
        }
        var tresponse1 = await apiClient.get('/getcishu', {
          params: {
            username: localStorage.username,
          }
        });
        var train = tresponse1.data.data[0].userCoins
        train = parseInt(train)
        if (train < 10) {
          this.$message.error(this.$t('taocanyiyongwan'))
          this.showModal = true
          return false
        }
        this.isLoadingXiaoguo = true;
        this.loadingText = this.$t('aituxingloading');
        this.vpath = ''
        this.vpath1 = ''

        value.ip = '107_8288';
        value.module = this.currentMenu;
        this.insertHistory(value);
        setTimeout(() => {
          value.id = that.max_id;
        }, 2000)

        // var t1 = setInterval(async function () {
        //   try {
        //     var tresponse1 = await apiClient.get('/getHistory', {
        //       params: {
        //         id: value.id ? value.id : that.max_id,
        //       },
        //     });
        //     if(tresponse1.data.data.length==0){
        //       clearInterval(t1);
        //       return  false;
        //     }
        //     var data = tresponse1.data.data[0];
        //     if (data.imgJieguo != '' && data.is_complete == '完成') {
        //       clearInterval(t1)
        //       value.imgJieguo = data.imgJieguo;
        //       if (value.module == that.currentMenu && !that.isGenerateChangeMenu && !that.isGenerateChangeTask&&value.id==that.select_id) {
        //         that.vpath1 = data.imgJieguo;
        //         that.historyBack = value;
        //       }
        //       that.koujian('1', value)
        //       if (value.module === that.currentMenu&&value.id==that.select_id) {
        //         that.isLoadingXiaoguo = false;
        //       }
        //
        //       if (!that.vpath1 && value.id == that.select_id) {
        //         that.vpath1 = data.imgJieguo
        //         that.historyBack = value;
        //         const showimg = that.historyAll.find(it => it.id === value.id);
        //         that.jieguoTime = showimg.create_time;
        //       }
        //     } else if (data.is_complete == '失败') {
        //       that.failMessage(data,value);
        //       clearInterval(t1)
        //     }
        //   } catch (error) {
        //     console.log(error);
        //     that.errorMessage();
        //     clearInterval(t1)
        //   }
        // }, 8000)

      } catch (e) {
        this.huanyi1Count = 0;
        this.wrongMessage();
      }
    },
    async huianyi(value) {
      try {
        this.select_id='';
        this.paidui=true;
      // 防止过快点击过次生成
      this.huanyiCount += 1;
      if (this.huanyiCount !== 1) {
        this.$message.warning('任务正在生成中，请稍后');
        return;
      }
      var that = this
      this.vpath1 = ''
      this.saveHistoryIndex1 = null;
      this.isGenerateChangeMenu = false;
      this.isGenerateChangeTask = false;
      this.handleEmpty();
      if (value.img2k.length == 0) {
        this.$message.error(this.$t('shangchuanshangbanshen'))
        return false
      }
      if (value.img3k.length == 0) {
        this.$message.error(this.$t('shangchuanxiabanshen'))
        return false
      }
      if (value.moteurl.length == 0 || value.moteurl == null) {
        this.$message.error(this.$t('qingxuanzemote'))
        return false
      }
      var tresponse1 = await apiClient.get('/getcishu', {
        params: {
          username: localStorage.username,
        }
      });
      var train = tresponse1.data.data[0].userCoins
      train = parseInt(train)
      if (train < 10) {
        this.$message.error(this.$t('taocanyiyongwan'))
        this.showModal = true
        return false
      }
      this.isLoadingXiaoguo = true;
      this.loadingText = this.$t('aituxingloading');
      this.vpath = ''
      this.vpath1 = ''

      value.ip = '8188';
      value.module=this.currentMenu;
      that.insertHistory(value);
        setTimeout(()=>{
          value.id=that.max_id;
        },2000)

      // var t1 = setInterval(async function () {
      //   try {
      //     var tresponse1 = await apiClient.get('/getHistory', {
      //       params: {
      //         id: value.id ? value.id : that.max_id,
      //       },
      //     });
      //     if(tresponse1.data.data.length==0){
      //       clearInterval(t1);
      //       return  false;
      //     }
      //     var data = tresponse1.data.data[0];
      //     if (data.imgJieguo != '' && data.is_complete == '完成') {
      //       clearInterval(t1)
      //       value.imgJieguo=data.imgJieguo;
      //       if (value.module===that.currentMenu&&!that.isGenerateChangeMenu && !that.isGenerateChangeTask&&value.id==that.select_id) {
      //         that.vpath1 = data.imgJieguo
      //         that.historyBack = value;
      //       }
      //       that.koujian('1',value)
      //       if (value.module===that.currentMenu&&value.id==that.select_id) {
      //         that.isLoadingXiaoguo = false;
      //       }
      //
      //       if(!that.vpath1&&value.id==that.select_id){
      //         that.vpath1 = data.imgJieguo
      //         that.historyBack = value;
      //         const showimg = that.historyAll.find(it => it.id === value.id);
      //         that.jieguoTime = showimg.create_time;
      //       }
      //
      //     }else if (data.is_complete == '失败') {
      //       that.failMessage(data,value);
      //       clearInterval(t1)
      //     }
      //   }
      //   catch (error) {
      //     that.huanyiCount = 0;
      //     that.errorMessage(value);
      //     clearInterval(t1)
      //     return false;
      //   }
      // }, 8000)

      }catch (e) {
        this.huanyiCount = 0;
        this.wrongMessage();
      }
    },
    async huanlianhuanbeijing(value) {
      try {
        this.select_id='';
         // 防止过快点击过次生成
        this.paidui = true;
        this.zhenrenCount += 1;
        if (this.zhenrenCount !== 1) {
          this.$message.warning('排队中~请等待2秒再继续操作');
          return;
        }
        this.vpath = ''
        this.vpath1 = ''
        this.saveHistoryIndex1 = null;
        this.isGenerateChangeMenu = false;
        this.isGenerateChangeTask = false;
        this.handleEmpty();
        var that = this

        var tresponse1 = await apiClient.get('/getcishu', {
          params: {
            username: localStorage.username,
          }
        });
        var train = tresponse1.data.data[0].userCoins
        train = parseInt(train)
        if (train < 4) {
          this.$message.error(this.$t('taocanyiyongwan'))
          this.showModal = true
          return false
        }
        this.isLoadingXiaoguo = true;
        this.loadingText = this.$t('aituxingloading');

        value.type='换脸换背景';
        value.img1 = value.originalUrl;
        value.img1k = value.cutdownUrl;
        value.models = value.saveMote;
        value.changjing = value.saveChangjing;
        value.module = this.currentMenu;
        value.ip='107_8288';
        that.insertHistory(value);
        setTimeout(()=>{
          value.id=that.max_id;
        },2000)
        // var t1 = setInterval(async function () {
        //   try {
        //     var tresponse1 = await apiClient.get('/getHistory', {
        //       params: {
        //         id: value.id ? value.id : that.max_id,
        //       },
        //     });
        //     if(tresponse1.data.data.length==0){
        //       clearInterval(t1);
        //       return  false;
        //     }
        //     var data = tresponse1.data.data[0];
        //     if (data.imgJieguo != '' && data.is_complete == '完成') {
        //       clearInterval(t1)
        //       value.imgJieguo=data.imgJieguo;
        //       if (value.module === that.currentMenu && !that.isGenerateChangeMenu && !that.isGenerateChangeTask&&value.id==that.select_id) {
        //         that.vpath1 = data.imgJieguo;
        //       }
        //       that.historyBack.imgJieguo = data.imgJieguo;
        //       that.koujian('2',value);
        //       if (value.module===that.currentMenu&&value.id==that.select_id) {
        //         that.isLoadingXiaoguo = false;
        //       }
        //       if(!that.vpath1 && value.id == that.select_id) {
        //         that.vpath1 = data.imgJieguo
        //         const showimg = that.historyAll.find(it => it.id === value.id);
        //         that.jieguoTime = showimg.create_time;
        //       }
        //     } else if (data.is_complete == '失败') {
        //       that.failMessage(data,value);
        //       clearInterval(t1)
        //     }
        //   } catch (error) {
        //     that.errorMessage(value);
        //     clearInterval(t1)
        //   }
        // }, 8000)
      } catch (error) {
        this.zhenrenCount = 0;
        this.wrongMessage();
      }
    },
    async AIGenerateHuanlian(value) {
      this.select_id='';
      console.log('=====进入换脸')
      try {
         // 防止过快点击过次生成
        this.paidui = true;
        this.zhenrenCount += 1;
        if (this.zhenrenCount !== 1) {
          this.$message.warning('排队中~请等待2秒再继续操作');
          return;
        }
        this.vpath = ''
        this.vpath1 = ''
        this.saveHistoryIndex1 = null;
        this.isGenerateChangeMenu = false;
        this.isGenerateChangeTask = false;
        this.handleEmpty();
        var that = this

        var tresponse1 = await apiClient.get('/getcishu', {
          params: {
            username: localStorage.username,
          }
        });
        var train = tresponse1.data.data[0].userCoins
        train = parseInt(train)
        if (train < 4) {
          this.$message.error(this.$t('taocanyiyongwan'))
          this.showModal = true
          return false
        }
        this.isLoadingXiaoguo = true;
        this.loadingText = this.$t('aituxingloading');


        value.type='换脸';
        value.module = this.currentMenu;
        value.img1 = value.originalUrl;
        value.img1k = value.cutdownUrl;
        value.models = value.saveMote;
        value.ip='107_8288';
        that.insertHistory(value);
        setTimeout(()=>{
          value.id=that.max_id;
        },2000)
        // var t1 = setInterval(async function () {
        //   try {
        //     var tresponse1 = await apiClient.get('/getHistory', {
        //       params: {
        //         id: value.id ? value.id : that.max_id,
        //       },
        //     });
        //     if(tresponse1.data.data.length==0){
        //       clearInterval(t1);
        //       return  false;
        //     }
        //     var data = tresponse1.data.data[0];
        //     if (data.imgJieguo != '' && data.is_complete == '完成') {
        //       clearInterval(t1)
        //       value.imgJieguo=data.imgJieguo;
        //       if (value.module === that.currentMenu && !that.isGenerateChangeMenu && !that.isGenerateChangeTask&&value.id==that.select_id) {
        //         that.vpath1 = data.imgJieguo;
        //       }
        //       that.historyBack.imgJieguo = data.imgJieguo;
        //       that.koujian('2',value);
        //       if (value.module===that.currentMenu&&value.id==that.select_id) {
        //         that.isLoadingXiaoguo = false;
        //       }
        //       if(!that.vpath1 && value.id == that.select_id) {
        //         that.vpath1 = data.imgJieguo
        //         const showimg = that.historyAll.find(it => it.id === value.id);
        //         that.jieguoTime = showimg.create_time;
        //       }
        //     } else if (data.is_complete == '失败') {
        //       that.failMessage(data,value);
        //       clearInterval(t1)
        //     }
        //   } catch (error) {
        //     that.errorMessage(value);
        //     clearInterval(t1)
        //   }
        // }, 8000)
      } catch (error) {
        this.zhenrenCount = 0;
        this.wrongMessage();
      }
    },
    async AIGenerateHuanbeijing(value) {
      this.select_id='';
      try {
         // 防止过快点击过次生成
        this.paidui = true;
        this.zhenrenCount += 1;
        if (this.zhenrenCount !== 1) {
          this.$message.warning('排队中~请等待2秒再继续操作');
          return;
        }
        this.vpath = ''
        this.vpath1 = ''
        this.saveHistoryIndex1 = null;
        this.isGenerateChangeMenu = false;
        this.isGenerateChangeTask = false;
        this.handleEmpty();
        var that = this

        var tresponse1 = await apiClient.get('/getcishu', {
          params: {
            username: localStorage.username,
          }
        });
        var train = tresponse1.data.data[0].userCoins
        train = parseInt(train)
        if (train < 4) {
          this.$message.error(this.$t('taocanyiyongwan'))
          this.showModal = true
          return false
        }
        this.isLoadingXiaoguo = true;
        this.loadingText = this.$t('aituxingloading');



        value.type='换背景';
        value.module = this.currentMenu;
        value.img1 = value.originalUrl;
        value.img1k = value.cutdownUrl;
        value.changjing = value.saveChangjing;
        value.ip='107_8288';
        that.insertHistory(value);
        setTimeout(()=>{
          value.id=that.max_id;
        },2000)
        // var t1 = setInterval(async function () {
        //   try {
        //     var tresponse1 = await apiClient.get('/getHistory', {
        //       params: {
        //         id: value.id ? value.id : that.max_id,
        //       },
        //     });
        //     if(tresponse1.data.data.length==0){
        //       clearInterval(t1);
        //       return  false;
        //     }
        //     var data = tresponse1.data.data[0];
        //     if (data.imgJieguo != '' && data.is_complete == '完成') {
        //       clearInterval(t1)
        //       value.imgJieguo=data.imgJieguo;
        //       if (value.module === that.currentMenu && !that.isGenerateChangeMenu && !that.isGenerateChangeTask&&value.id==that.select_id) {
        //         that.vpath1 = data.imgJieguo;
        //       }
        //       that.historyBack.imgJieguo = data.imgJieguo;
        //       that.koujian('2',value);
        //       if (value.module===that.currentMenu&&value.id==that.select_id) {
        //         that.isLoadingXiaoguo = false;
        //       }
        //       if(!that.vpath1 && value.id == that.select_id) {
        //         that.vpath1 = data.imgJieguo
        //         const showimg = that.historyAll.find(it => it.id === value.id);
        //         that.jieguoTime = showimg.create_time;
        //       }
        //     } else if (data.is_complete == '失败') {
        //       that.failMessage(data,value);
        //       clearInterval(t1)
        //     }
        //   } catch (error) {
        //     that.errorMessage(value);
        //     clearInterval(t1)
        //   }
        // }, 8000)

      } catch (error) {
        this.zhenrenCount = 0;
        this.wrongMessage();
      }
    },
    async dingshi(){
      var that = this;
      this.timers.forEach(timerId => {
        clearInterval(timerId);
      });

      let t1 = setInterval(async function () {
        if (that.hasDingshi) return;
        const historyList = await that.getHistoryList(that.currentMenu);
        if (that.historyCurrentPage === 1) {
          that.historyDialogList(that.currentMenu);
        }
        that.historyList = historyList;
        eventBus.$emit('koujian');
        const hasEmptyImg = historyList.some(item => item.is_running == 1 );
        console.log(t1);
        if (!hasEmptyImg) {
          clearInterval(t1)
        }
        //判断，如果选中的任务不为执行中，清除loading
        if(that.isLoadingXiaoguo){
          const checkObjList = historyList.filter(item => item.id == that.select_id);
          if(checkObjList.length>0){
            let checkObj=checkObjList[0];
            if (checkObj.imgJieguo != '' && checkObj.is_complete == '完成') {
              const url = new URL(checkObj.imgJieguo);
              url.search = '';
              that.vpath1 = url.toString();
              that.isLoadingXiaoguo = false;
              that.jieguoTime = checkObj.create_time;
              that.historyBack = checkObj;
            } else if (checkObj.is_complete == '失败') {
              if (checkObj.remark) {
                that.$message.error("生成失败:" + checkObj.remark);
                that.jieguoRemark = '（生成失败:' + checkObj.remark + '）';
              } else {
                that.$message.error("生成失败~");
                that.jieguoRemark = '（生成失败）';
              }
              that.jieguoTime = checkObj.create_time;
              that.vpath1 = '';
              that.isLoadingXiaoguo = false;
            }
          }
        }else{
          //判断是否有失败任务
          const commonData = that.historyList.filter(item1 => that.panduanList.some(item2 => item2.id === item1.id));
          console.log(commonData);
          console.log("------------------------commonData----------------------");
          let filterData=commonData.filter(item => item.is_complete == '失败');
          that.panduanList = that.panduanList.filter(panduanItem =>
              !filterData.some(filterItem => filterItem.id === panduanItem.id)
          );
          for (const filterDatum of filterData) {
            if (filterDatum.remark) {
              that.$message.error("生成失败:" + filterDatum.remark);
            } else {
              that.$message.error("生成失败~");
            }
          }
        }
      }, 9000)
      that.timers.push(t1);
    },

    async convertImageToBase64(imageUrl) {
      try {
        // 使用 fetch 获取图片的二进制数据
        const response = await fetch(imageUrl);

        // 检查请求是否成功
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        // 将二进制数据转换为 Blob
        const blob = await response.blob();

        // 使用 FileReader 将 Blob 转换为 Base64 编码
        const reader = new FileReader();
        reader.readAsDataURL(blob);

        // 返回一个 Promise，当读取完成时解析为 Base64 数据
        return new Promise((resolve, reject) => {
          reader.onloadend = () => {
            resolve(reader.result);
          };
          reader.onerror = () => {
            reject(new Error('Failed to read the image as Base64'));
          };
        });
      } catch (error) {
        console.error('Error converting image to Base64:', error);
        return null;
      }
    },

    async xianshangImg(path,bili) {
      return new Promise((resolve, reject) => {
        apiClient.get('/upload-image-result', {
          params: {
            imageUrl: path,
            bili:bili
          },
          headers: {
            'x-access-token': localStorage.token
          }
        })
            .then(response => {
              var myurl = response.data.url;
              if (myurl) {
                resolve(myurl);
              } else {
                this.$message.error('系统异常，请联系管理人员~');
                reject(false);
              }
            })
            .catch(error => {
              this.$message.error('请求失败，请稍后重试~');
              reject(error);
            });
      });
    },


    async failMessage(data,value){
      if(data.remark){
        this.$message.error("生成失败:"+data.remark);
      }else{
        this.$message.error("生成失败~");
      }
      if(value.id == this.select_id){
        this.jieguoTime = data.create_time;
        this.vpath1 = '';
        if(data.remark){
          this.jieguoRemark='（生成失败:'+data.remark+'）';
        }else{
          this.jieguoRemark='（生成失败）';
        }
        this.isLoadingXiaoguo = false;
        this.paidui = false;
      }
      if (this.imgPreviewVisible || this.imgPreviewVisible2) {
        this.panzoomInstance.setOptions({
          disablePan: false,
          disableZoom: false,
        });
      }
      if (value.module === this.currentMenu) {
        this.historyList = await this.getHistoryList(this.currentMenu);
      }
    },

    errorMessage(value){
      this.$message.error("操作失败~");
      if(value.id == this.select_id){
        this.vpath1 = '';
        this.isLoadingXiaoguo = false;
        this.paidui = false;
      }
      if (this.imgPreviewVisible || this.imgPreviewVisible2) {
        this.panzoomInstance.setOptions({
          disablePan: false,
          disableZoom: false,
        });
      }
      this.hideHistory({id: value.id, remark: '操作失败~'});
    },

    wrongMessage(){
      this.$message.error("系统异常~");
      this.vpath1 = '';
      if (this.imgPreviewVisible || this.imgPreviewVisible2) {
        this.panzoomInstance.setOptions({
          disablePan: false,
          disableZoom: false,
        });
      }
      this.paidui = false;
      this.isLoadingXiaoguo = false;
    },
    getOriginalUrl(url) {
      if (!url) {
        return '';
      }
      const urlObj = new URL(url);
      urlObj.search = '';
      return urlObj.toString();
    },
  }
}
</script>

<style scoped>
::v-deep .el-menu-item:focus {
  background-color: transparent !important;
}
.uploadbtn {
  border-radius: 0 5px 5px 0;
  background: linear-gradient(0deg, #A835F2, #3E93F0);
  border: 0;
  padding: 13px 20px
}
.uploadbtn:hover {
  background: linear-gradient(0deg, #A835F2, #3E93F0);
  box-shadow: 0 0 0 1000px rgba(255, 255, 255, 0.2) inset !important;
}
.container {
  display: flex;
  height: calc(100vh - 62px);
  /* Full viewport height */
  background-color: white;
  /* height: calc(200vh - 500px);  */
}

.box {
  width:290px;
  height:auto;
  padding: 20px 14px 0px 20px;
  overflow-y: auto;
  scrollbar-width: thin;
}

.zhanshiqu {
  width: calc(100vw - 496px);
  display: flex;
  justify-content: center;
  padding: 20px 20px;
}

.xiaoguodiv {
  flex: 9;
}

.lishicontain {
  position: relative;
  flex: 1;
  margin-left: 20px;
}
.showAll {
  position: absolute;
  bottom: 0;
  /* left: 15px; */
  left: 50%; /* 水平居中 */
  transform: translateX(-50%);
}

.lishitu {
  height: calc(100vh - 140px);
  overflow-y: auto;
  scrollbar-width: thin;
}
.lishidiv {
  position: relative;
  display: flex;
  justify-content: center;
  margin: 10px 0;
}
.lishiimg {
  height: 100px;
  width: 100px;
  border-radius: 4px;
  box-sizing: border-box;
  border: 2px solid #f5f5f5;
}
.lishiimg :hover {
  cursor: pointer;
}
.lishiimg /deep/ .el-image__inner {
  width: 100%;
  height: auto;
}
.imgloading {
  display: flex;
  justify-content: center;
  align-items: center
}
.lishiimgtext {
  position: absolute;
  bottom: 1px;
  width: 96px;
  background: rgba(0, 0, 0, 0.4);
  opacity: 0;
  /*transition: opacity 0.3s;*/
}
.lishidiv:hover .lishiimgtext {
  opacity: 1;
}
.lishiimgtext :hover {
  cursor: pointer;
}
.lishiselected {
  border: 2px solid #2352d8 !important;
  /*padding: 1px;*/
}
.xiaoguotu {
  position: relative;
  box-sizing: border-box;
  border: 2px dashed rgba(4, 17, 51, 0.26);
  border-radius: 10px;
  width: 100%;
  height: calc(100vh - 140px);
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(4, 17, 51, 0.45);
  padding: 10px 0;
}

.scale {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 100%;
  border-radius: 0;
  border: none;
}

.el-button.is-plain:hover {
  color: #2352d8;
}

.el-button.is-plain:focus {
  border: 1px solid #2352d8;
  color: #2352d8;
}

.menuicon {
  width: 18px;
  height: auto;
  margin: 0 10px 0 5px;
}

.image_container {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(128, 128, 128, 0.5);
  /* 蒙层的半透明背景 */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  opacity: 0;
  /* 初始状态透明 */
  transition: opacity 0.3s ease;
  border-radius: 10px
}
.zoomindiv {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.botton_icon {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.botton_icon img {
  cursor: pointer;
}

.image_container:hover .overlay {
  opacity: 1;
  /* 鼠标悬停时显示蒙层 */
}
.previewdialog /deep/ .el-dialog__header {
  text-align: left;
  padding: 20px;
}
.previewdialog /deep/ .el-dialog {
  margin-top: 5vh !important;
}

.previewdialog /deep/ .el-dialog__body {
  height: 70vh !important;
  background: #EFF1F6;
  padding: 0;
}
.previewdialog /deep/ .el-dialog__footer {
  padding: 20px;
}

.previewdialog /deep/ .dialog-footer {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.yulanmote {
  flex: 2;
  background: #fff;
  margin: 20px 5px 20px 20px;
  padding: 10px;
}
.yulanmote /deep/ img {
  width: 100%;
  height: auto;
}
.yulanyuantu {
  flex: 4;
  background: #fff;
  margin: 20px 5px 20px 0;
  padding: 10px;
}
.yulanyuantu1 {
  flex: 4;
  background: #fff;
  margin: 20px 5px 20px 20px;
  padding: 10px;
}
.danjian1 {
  height: calc(100% - 40px);
}
.danjian1 /deep/ img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.yulanduojian {
  height: calc(100% - 40px);
}
.duojian1, .duojian2 {
  height: 50%;
}
.duojian1 /deep/ img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.duojian2 /deep/ img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.yulanshengtu {
  position: relative;
  flex: 6;
  background: #fff;
  margin: 20px 20px 20px 0;
}
.tagdiv {
  position: absolute;
  z-index: 99;
  top: 10px;
  left: 10px;
}
.previewdiv {
  position: relative;
  height: 100%;
  width: 100%;
}

.previewdiv img {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}
.dialog_loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #b7b8bb;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  color: white;
}

.loading_overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #b7b8bb;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  color: white;
}

.loading_overlay i {
  font-size: 50px;
}

.sizeicon {
  width: 160px;
  height: 35px;
  position: absolute;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  border-radius: 5px;
  left: 75%;
}
.yulanIconDiv {
  display: flex;
  align-items: center;
  color: #000;
  cursor: pointer;
  margin: 0 1px;
  padding: 9px 15px;
}
.historydialog /deep/ .el-dialog {
  margin-top: 5vh !important;
}
.historydialog /deep/ .el-dialog__body {
  background: #EFF1F6;
  height: 70vh;
  overflow: auto;
}
.lishidialogimg {
  padding: 10px 20px;
}
.lishidialogimg /deep/ img {
  border-radius: 5px;
}
.dialogcol {
  position: relative;
  width: 20%;
  padding: 5px
}
.carddiv {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  cursor: pointer;
  box-sizing: border-box;
  border: 2px solid #fff;
}
.dialogdel {
  position: absolute;
  bottom: 7px;
  width: calc(100% - 10px);
  background: rgba(0, 0, 0, 0.4);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s;
}
.dialogcol:hover .dialogdel {
  opacity: 1;
}
.smalltag {
  text-align: left;
  margin-bottom: 10px
}
.selecticon {
  background: #fff;
}
</style>
