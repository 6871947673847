<template>
  <div class="gaikuan">
    <!-- <span style="font-size: 14px;">{{ $t('shangchuanxiangao') }}</span> -->
    <CustomerUploadShow :text="$t('shangchuanxiangao')" demand="要求:上传清晰的线稿图" :paidui="paidui" :originalUrl="original" @uploadChange="uploadChange" />
    <div class="xiangsidiv">
      <div style="text-align: left">{{ $t('xiangsidu') }}</div>
      <el-slider class="xiangsislider" v-model="tvalue" :min='0' :max="1" :step="0.1" :disabled="paidui" show-input input-size="mini" :show-tooltip="false"></el-slider>
    </div>

    <div class="tishicidiv">
      <div style="text-align: left; margin-bottom: 10px">{{ $t('tishici') }}</div>
      <el-input v-model="prompts" type="textarea" :rows="5" :disabled="paidui" :placeholder="$t('qingshurutishici')"></el-input>
    </div>

    <div class="miaoliaodiv">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>{{ $t('xuanzebiaoqian') }}</span>
        </div>
        <el-popover placement="right" popper-class="tagpopverdiv" width="500" trigger="manual" v-model="yanseVisible">
          <div class="popverbody">
            <div class="tptitle">
              <div>颜色选择</div>
              <i class="el-icon-close" @click="handleCloseMore" style="font-size: 24px; color: #000; cursor: pointer;" />
            </div>
            <div class="tpcontent">
              <div>
                <el-button plain :class="{'selectedbtn' : mycolor === item}" v-for="(item, index) in colorlist" :key="index" style="margin: 0 0 5px 5px; width: 87px" @click="yanse(item)" >
                  {{ item }}
                </el-button>
              </div>
            </div>
          </div>
          <div class="text item" @click="handleOpen('yanse')" style="height:40px;line-height:40px;cursor:pointer;display:flex;justify-content:space-between" slot="reference">
            <div>{{ $t('yanse') }}</div>
            <div style="display: flex; align-items: center">
              <div style="color: #409eff; font-size: 12px;">{{ mycolor }}</div>
              <i class="el-icon-arrow-right" style="font-size: 20px" />
            </div>
          </div>
        </el-popover>
        <el-popover placement="right" popper-class="tagpopverdiv" width="800" trigger="manual" v-model="pinleiVisible">
          <div class="popverbody">
            <div class="tptitle">
              <div>单品品类</div>
              <i class="el-icon-close" @click="handleCloseMore" style="font-size: 24px; color: #000; cursor: pointer;" />
            </div>
            <div class="tpcontent">
              <div v-for="(kuaixing, index) in kuanxinglist" :key="index">
                <div style="margin: 10px 0 10px 0">{{kuaixing[0]}}</div>
                <el-button plain :class="{'selectedbtn' : mydanpin === item}" @click="danpin(item)" v-for="(item, i) in kuaixing.slice(1, kuaixing.length)" :key="i" style="margin: 0 0 5px 5px;width:105px">
                  {{ item }}
                </el-button>
              </div>
            </div>
          </div>
          <div class="text item" @click="handleOpen('pinlei')" style="height:40px;line-height:40px;cursor:pointer;display:flex;justify-content:space-between" slot="reference">
            <div>{{ $t('danpinpinlei') }}</div>
            <div style="display: flex; align-items: center">
              <div style="color: #409eff;font-size: 12px;">{{ mydanpin }}</div>
              <i class="el-icon-arrow-right" style="font-size: 20px" />
            </div>
          </div>
        </el-popover>
        <el-popover placement="right" popper-class="tagpopverdiv" width="400" trigger="manual" v-model="sexVisible">
          <div class="popverbody">
            <div class="tptitle">
              <div>性别</div>
              <i class="el-icon-close" @click="handleCloseMore" style="font-size: 24px; color: #000; cursor: pointer;" />
            </div>
            <div class="tpcontent">
              <el-button plain :class="{'selectedbtn' : mysex === item}" style="margin: 0 0 5px 5px" @click="sex(item)" v-for="(item, index) in sexlist" :key="index">
                {{ item }}
              </el-button>
            </div>
          </div>
          <div class="text item" @click="handleOpen('sex')" style="height:40px;line-height:40px;cursor:pointer;display:flex;justify-content:space-between" slot="reference">
            <div>{{ $t('xingbie') }}</div>
            <div style="display: flex; align-items: center">
              <div style="color: #409eff;font-size: 12px;">{{ mysex }}</div>
              <i class="el-icon-arrow-right" style="font-size: 20px" />
            </div>
          </div>
        </el-popover>
        <el-popover placement="right" popper-class="tagpopverdiv" width="800" trigger="manual" v-model="mianliaoVisible">
          <div class="popverbody">
            <div class="tptitle">
              <div>面辅料</div>
              <i class="el-icon-close" @click="handleCloseMore" style="font-size: 24px; color: #000; cursor: pointer;" />
            </div>
            <div class="tpcontent">
              <el-button plain :class="{'selectedbtn' : mymianliao === item}" style="margin: 0 0 5px 5px; width: 121px" @click="mianliao(item)" v-for="(item, index) in mianliaolist" :key="index">
                {{ item }}
              </el-button>
            </div>
          </div>
          <div class="text item" @click="handleOpen('mianliao')" style="height:40px;line-height:40px;cursor:pointer;display:flex;justify-content:space-between" slot="reference">
            <div>{{ $t('mianfuliao') }}</div>
            <div style="display: flex; align-items: center">
              <div style="color: #409eff;font-size: 12px;">{{ mymianliao }}</div>
              <i class="el-icon-arrow-right" style="font-size: 20px" />
            </div>
          </div>
        </el-popover>
      </el-card>
    </div>
    <!--  比例 -->
    <div style="text-align: left; margin-bottom: 10px">
      <div style="display: flex; align-items: center; margin: 10px 0;">
        <span style="font-size: 14px;">{{ $t('bili') }}</span>
      </div>
      <div>
        <el-row :gutter="15">
          <el-radio-group v-model="radio" size="small">
            <el-col :span="8">
              <el-button plain class="scale" :class="{ 'radioSelected': radio === $t('yuanshi') }"
                @click="selectRadio($t('yuanshi'))">{{ $t('yuanshi') }}</el-button>
            </el-col>
            <el-col :span="8">
              <el-button plain class="scale" :class="{ 'radioSelected': radio === '1:1' }"
                @click="selectRadio('1:1')">1:1</el-button>
            </el-col>
            <el-col :span="8">
              <el-button plain class="scale" :class="{ 'radioSelected': radio === '3:4' }"
                @click="selectRadio('3:4')">3:4</el-button>
            </el-col>
            <el-col :span="8">
              <el-button plain class="scale" :class="{ 'radioSelected': radio === '16:9' }"
                @click="selectRadio('16:9')">16:9</el-button>
            </el-col>
            <el-col :span="8">
              <el-button plain class="scale" :class="{ 'radioSelected': radio === '9:16' }"
                @click="selectRadio('9:16')">9:16</el-button>
            </el-col>
          </el-radio-group>
        </el-row>
      </div>
    </div>

    <div class="generatenum">
      <el-input v-model="num" type="number" :min="1" :max="4" disabled>
        <template slot="prepend">{{ $t('shengchengshuliang') }}</template>
      </el-input>
    </div>

    <template>
      <div class="generate-footer">
        <div class="xiaohaodiv">
          <span>{{ $t('xiaohao') }}:4</span>
          <img src="@/assets/jinbi.png" class="jinbi_icon" />
        </div>
        <el-button slot="reference" class="generatebtn" type="primary" @click="AIGenerate3">{{ $t('lijishengchen') }}</el-button>
      </div>
    </template>
  </div>
</template>

<script>
import CustomerUploadShow from './CustomerUploadShow.vue';
import apiClient from '@/services/api';

export default {
  props: ['colorlist', 'kuanxinglist', 'sexlist', 'mianliaolist', 'historyBack', 'paidui'],
  components: { CustomerUploadShow },
  data() {
    return {
      original: '',
      tvalue: 0,
      prompts: '',
      mycolor: '',
      mymianliao: '',
      mysex: '',
      mydanpin: '',
      radio: this.$t('yuanshi'),
      num: 1,
      yanseVisible: false,
      pinleiVisible: false,
      sexVisible: false,
      mianliaoVisible: false,
    }
  },
  watch: {
    historyBack(val) {
      if (!val) return;
      this.original = val.img1;
      this.tvalue = Number(val.yuantuxiangsi)?Number(val.yuantuxiangsi):0;
      this.prompts = val.prompt;
      this.mycolor = val.yanse;
      this.mydanpin = val.danpinpinlei;
      this.mysex = val.xingbie;
      this.mymianliao = val.mianfuliao;
      this.radio = val.bili?val.bili:this.$t('yuanshi');
    }
  },
  created() {},
  mounted() {},
  methods: {
    handleOpen(val) {
      this.handleCloseMore();
      if (val === 'yanse') {
        this.yanseVisible = true;
      }
      if (val === 'pinlei') {
        this.pinleiVisible = true;
      }
      if (val === 'sex') {
        this.sexVisible = true;
      }
      if (val === 'mianliao') {
        this.mianliaoVisible = true;
      }
    },
    handleCloseMore() {
      this.yanseVisible = false;
      this.pinleiVisible = false;
      this.sexVisible = false;
      this.mianliaoVisible = false;
    },
    async AIGenerate3() {
      if (this.original == '') {
        this.$message.error(this.$t('qingshangchuangaikuan'))
        return false
      }
      if (this.prompts == '') {
        this.$message.error(this.$t('qingshurutishici2'))
        return false
      }
      if (this.tvalue == 0) {
        this.$message.error(this.$t('gaikuanxiangsidu'))
        return false
      }
      if (this.mycolor == '') {
        this.$message.error(this.$t('qingxuanzeyanse'))
        return false
      }
      if (this.mydanpin == '') {
        this.$message.error(this.$t('qingxuanzeyifupinlei'))
        return false
      }
      if (this.mysex == '') {
        this.$message.error(this.$t('qingxuanzexingbie'))
        return false
      }
      if (this.mymianliao == '') {
        this.$message.error(this.$t('qingxuanzemianliao'))
        return false
      }
      this.handleCloseMore();
      const obj1 = {
        type: 'xiangao',
        img1: this.original,
        yuantuxiangsi: this.tvalue,
        prompt: this.prompts,
        yanse: this.mycolor,
        danpinpinlei: this.mydanpin,
        xingbie: this.mysex,
        mianfuliao: this.mymianliao,
        bili: this.radio,
      }
      const response = await apiClient.post(`/checkGenerating`, obj1);
      if (response.data.data.length !== 0) {
        this.$message.warning('任务正在生成中，请稍后');
        return;
      }
      const obj = {
        prompt: this.prompts,
        yanse: this.mycolor,
        danpinpinlei: this.mydanpin,
        xingbie: this.mysex,
        mianfuliao: this.mymianliao,
        yuantuxiangsi: this.tvalue,
        img1: this.original,
        bili: this.radio,
      }
      this.$emit('AIGenerate6', obj)
    },
    uploadChange(value) {
      this.original = value;
    },
    yanse(value) {
      if(this.paidui){
        this.$message.warning('排队中~请等待2秒再继续操作');
        return  false;
      }
      this.mycolor = value;
    },
    mianliao(value) {
      if(this.paidui){
        this.$message.warning('排队中~请等待2秒再继续操作');
        return  false;
      }
      this.mymianliao = value;
    },
    sex(value) {
      if(this.paidui){
        this.$message.warning('排队中~请等待2秒再继续操作');
        return  false;
      }
      this.mysex = value;
    },
    danpin(value) {
      if(this.paidui){
        this.$message.warning('排队中~请等待2秒再继续操作');
        return  false;
      }
      this.mydanpin = value;
    },
    selectRadio(value) {
      if(this.paidui){
        this.$message.warning('排队中~请等待2秒再继续操作');
        return  false;
      }
      this.radio = value;
    },
  },
}
</script>

<style scoped>
.gaikuan {
  text-align: center;
}
.xiangsidiv, .tishicidiv {
  font-size: 14px;
  margin-top: 20px;
}
.xiangsislider {
  padding-left: 10px;
}
.miaoliaodiv {
  margin-top: 20px;
}
.box-card {
  text-align: left;
}
.item {
  font-size: 14px;
  margin-bottom: 18px;
  border-bottom: 1px solid rgba(5, 5, 5, 0.06);
  height: 90px;
}
.scale {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 100%;
  border-radius: 0;
  border: none;
}
.radioSelected {
  border: 1px solid #2352d8;
}
.generatenum {
  margin-top: 10px;
  margin-bottom: 100px;
}
.generatenum /deep/ .el-input__inner {
  text-align: center;
}
.generate-footer {
  width: 290px;
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  height: 100px;
}
.xiaohaodiv {
  display: flex;
  align-items: center;
}
.jinbi_icon {
  width: 33px;
  height: auto;
  margin-left: 10px;
}
.generatebtn {
  width: 160px;
  height: 48px;
  border-radius: 10px;
  background: linear-gradient(0deg, #A835F2, #3E93F0);
  color: #fff;
}
.generatebtn:hover {
  background: linear-gradient(0deg, #A835F2, #3E93F0);
  box-shadow: 0 0 0 1000px rgba(255, 255, 255, 0.2) inset !important;
}
.popverbody {
  /* height: 88vh; */
  padding: 0 10px 20px 10px;
  overflow-y: auto;
  scrollbar-width: thin;
}
@media (max-height: 940px) {
  .popverbody {
    height: 86vh !important;
  }
}
@media (min-height: 941px) {
  .popverbody {
    height: 88vh !important;
  }
}
.selectedbtn {
  border: 1px solid #409eff;
  color: #409eff;
}
.tptitle {
  position: absolute;
  height: 40px;
  width: calc(100% - 57px);
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  color: #000;
  background: #fff;
}
.tpcontent {
  margin-top: 70px;
}
</style>
