<template>
  <el-header style="height:60px">
    <el-dialog
    :visible.sync="evisible"
    width="30%"
    :before-close="handleClose2"
  >
    <span slot="title">{{ $t('chongzhimima') }}</span>
    <div>
      <el-form :model="form" ref="form" label-width="100px">
        <el-form-item :label="$t('shoujihao') + '：'">
          <span>{{ maskedPhone }}</span>
        </el-form-item>

        <el-form-item :label="$t('mima') + '：'">
          <el-input type="password" v-model="form.password" :placeholder="$t('shurumima')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('querenmima') + '：'">
          <el-input type="password" v-model="form.confirmPassword" :placeholder="$t('zaicimima')"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose2">{{ $t('quxiao') }}</el-button>
      <el-button type="primary" @click="handleSubmit">{{ $t('queding') }}</el-button>
    </span>
  </el-dialog>
    <div class="header-container">
      <img src="@/assets/logo.png" alt="Logo" class="logo" @click="goHome">
      <el-menu mode="horizontal" class="menu" style="border:none" :default-active="activeMenuItem" @select="handleMenuSelect" ref="menu">
        <el-menu-item index="Home" class="navtitle" style="margin-left: 60px">{{ $t('shouye') }}</el-menu-item>
        <el-menu-item index="AIModelLibrary" class="navtitle">{{ $t('aishengtu') }}</el-menu-item>
        <el-menu-item index="ZhiNan"  class="navtitle" @click="handleClick">{{ $t('shengtuzhinan') }}</el-menu-item>
        <el-menu-item index="ZhiSuan"  class="navtitle" @click="handleClickZhisuan">{{ $t('motuzhisuan') }}</el-menu-item>
        <el-menu-item index="AboutUs" class="navtitle">{{ $t('guanyuwomen') }}</el-menu-item>
      </el-menu>
      <div class="right-section">
        <!-- <div>
          <el-dropdown @command="switchLanguage">
            <el-button type="primary">
              切换语言<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="zh">简体中文</el-dropdown-item>
              <el-dropdown-item command="en">English</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div> -->
        <div v-if="isLoggedIn" class="user-info">
          <div class="user_coins">
            <!-- {{ userCoins }} 剩余权力（次） -->
            <img src="@/assets/jinbi.png" class="jinbi_icon" />
            <div>{{ userCoins }}</div>
            <el-divider direction="vertical"></el-divider>
            <div class="buy" @click="opencelue">{{ $t('goumai') }}</div>
          </div>
          <el-dropdown>
            <div class="el-dropdown-link">
              <div>
                <!-- <img src="@/assets/hgtouxiang.png" class="avatar" /> -->
                <img src="@/assets/touxiang.png" class="avatar" />
                <img v-show="userCoins !== 0" src="@/assets/headericon.png" class="avatar_icon" />
                <!-- <el-icon class="el-icon-arrow-down"></el-icon> -->
              </div>
            </div>
            <el-dropdown-menu slot="dropdown">

<!--              <el-dropdown-item divided @click.native="openzuopin">{{ $t('zuopin') }}</el-dropdown-item>-->
              <el-dropdown-item @click.native="openorder">{{ $t('dingdan') }}</el-dropdown-item>
              <el-dropdown-item  @click.native="openxiaofei">{{ $t('xiaofei') }}</el-dropdown-item>
              <el-dropdown-item @click.native="updatepass">{{ $t('zhanghaoshezhi') }}</el-dropdown-item>
              <el-dropdown-item @click.native="opencelue">{{ $t('dingjiaceluo') }}</el-dropdown-item>

              <el-dropdown-item divided @click.native="logout">{{ $t('tuichudenglu') }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div v-else class="login-buttons">
          <el-button type="primary" class="login-button" @click="showLoginDialog">{{ $t('zhucedenglu') }}</el-button>
        </div>
      </div>
      <LoginDialog :visible.sync="loginDialogVisible" @login-success="handleLoginSuccess"  />
      <PricingModal :visible.sync="showModal" />

    </div>

  </el-header>
</template>

<script>
import LoginDialog from '@/components/LoginDialog.vue';
import apiClient from '@/services/api';
import PricingModal from './PricingModal.vue';
import { eventBus } from '@/main';

export default {
  name: 'Header',
  components: {
    LoginDialog,
    PricingModal
  },
  data() {
    return {
      showModal:false,
      visible: true, // 控制弹框显示
      maskedPhone: localStorage.username, // 假设的手机号码
      captchaSrc: 'path/to/captcha', // 验证码图片的路径
      smsCodeSent: false, // 是否已经发送验证码
      smsCodeCountdown: 60, // 验证码倒计时
      form: {
        imageCode: '',
        smsCode: '',
        password: '',
        confirmPassword: ''
      },
      evisible:false,
      myindex:"1",
      loginDialogVisible: false,
      isLoggedIn: false,
      userAvatar: '',  // 用户头像URL
      userCoins: 0,    // 用户灵币数量
      activeMenuItem: 'Home' // 默认选中的菜单项
    };
  },
  mounted(){
    eventBus.$on('goAiHuanzhuang', this.handleMenuSelect);
    eventBus.$on('goAiModel', this.handleAiModel);
    eventBus.$on('koujian', this.koujian);
    this.handleLoginSuccess()
  },
  methods: {
    switchLanguage(command) {
      this.$i18n.locale = command;
      localStorage.lang = command;
      this.handleMenuSelect('Home')
    },
    openkefu(){

    },
    opencelue(){
      this.showModal=true
    },
    async  handleSubmit(){
      if (this.form.password.length==''){
        this.$message.warning("请输入密码~")
        return false
      }

      if (this.form.confirmPassword.length==''){
        this.$message.warning("请再次输入密码~")
        return false
      }
      if (this.form.confirmPassword!=this.form.password){
        this.$message.warning("两次密码不符~")
        return false
      }
          const response = await apiClient.get('/updatepass', {
          headers: {
            'x-access-token': localStorage.token
          },
          params: {
            username: localStorage.username,
            password:this.form.password
          }
        });
        this.$message.success("密码修正成功~")
        this.evisible=false
    },

    updatepass(){
      this.evisible=true
    },
    handleClose2(){
      this.evisible=false
    },
    handleCommand(command) {
      // alert(0)
      if (command === 'myzuopin') {
        this.openzuopin();
      }
    },
    openxiaofei(){
     var index='xiaofei'
     this.handleMenuSelect(index)
    },
    openorder(){
     var index='order'
     this.handleMenuSelect(index)
    },
    openzuopin(){
     var index='zuopin'
     this.handleMenuSelect(index)
    },

    handleClick() {
      // 手动保持当前选中状态
      window.open('https://www.yuque.com/zhenxing-e5now/tnhdok?#%20');

      this.$refs.menu.activeIndex = this.activeMenuItem;
    },

    handleClickZhisuan() {
      // 手动保持当前选中状态
      window.open(window._CONFIG['zhisuandizhi']);

      this.$refs.menu.activeIndex = this.activeMenuItem;
    },

    handleMenuSelect(index) {

      if (index=='AIModelLibrary'){
        if (localStorage.token==undefined||localStorage.token==''){
          this.loginDialogVisible = true;
          return false

        }

      }
      if(index=='ZhiNan'||index=='ZhiSuan'){
        return false;
      }

      this.activeMenuItem = index;
      this.$emit('menu-select', index);
    },

    goHome() {
      this.handleMenuSelect('Home');
    },
    handleAiModel(val, index) {
      if (val == 'AIModelLibrary'){
        if (localStorage.token == undefined || localStorage.token == ''){
          this.loginDialogVisible = true;
          return false
        }
      }
      this.activeMenuItem = val;
      this.$emit('menu-select', val, index);
    },
    showLoginDialog() {
      this.loginDialogVisible = true;
    },
    async handleLoginSuccess(userInfo) {
      // alert(localStorage.token)
        if (localStorage.token!=undefined&&localStorage.token!=''&&localStorage.token!='undefined'){
          this.isLoggedIn = true;
          // this.userAvatar = userInfo.avatar;
          // this.userCoins = userInfo.coins;
          this.loginDialogVisible = false;
          var  tresponse1 = await apiClient.get('/getcishu', {
          params: {

            username: localStorage.username,

          }
        });

        this.userCoins=tresponse1.data.data[0].userCoins


          //通过username获取剩余金币数量
        }
        else {
          this.isLoggedIn = false;
          this.userAvatar = '';
          this.userCoins = 0;
        }
    },
    async koujian() {
      var  tresponse1 = await apiClient.get('/getcishu', {
        params: {
          username: localStorage.username,
        }
      });
      this.userCoins=tresponse1.data.data[0].userCoins
    },
    logout() {
      localStorage.token=''
      this.isLoggedIn = false;
      this.userAvatar = '';
      this.userCoins = 0;
      this.handleMenuSelect('Home')

    }
  }
};
</script>

<style scoped>
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo {
  margin-left: 40px;
  max-width: 75px;
  height: auto;
  cursor: pointer;
  /* margin-top:20px */
}
.menu {
  flex-grow: 1;
  margin-left: 20px;
}
.el-menu-item.is-active {
  border-bottom: 2px solid #4765FD !important;
}
.right-section {
  display: flex;
  align-items: center;
}
.login-button {
  /* background-color: #0c92fe; */
  margin-right: 40px;
}
.user-info {
  display: flex;
  align-items: center;
}
.avatar {
  height: 33px;
  width: auto;
  position: absolute;
  right: 0;
  top: -16px;
  margin-right: 40px;
}
.avatar_icon {
  position: absolute;
  right: 3px;
  top: -27px;
  margin-right: 40px;
}
.navtitle {
  font-size: 16px;
  font-weight: 400;
}
.el-dropdown-link {
  position: relative;
  display: flex;
  align-items: center;
}
.user_coins {
  height: 30px;
  width: 150px;
  margin-right: 90px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 2px solid #c40af2;
  border-radius: 30px;
}
.jinbi_icon {
  width: 33px;
  height: auto;
  margin-left: 5px;
}
.buy {
  font-weight: 500;
  color: #c40af2;
  margin-right: 10px;
  cursor: pointer;
}
</style>
