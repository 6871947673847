<template>
  <div>
    <el-upload
      ref="upload"
      class="upload_ele"
      v-show="(!original || original == '')&&(!showOriginal)"
      style="text-align: center;"
      :data="{ username: username }"
      :action="actionUrl"
      :disabled="unitType == ''"
      drag
      :show-file-list="false"
      :before-upload="beforeUpload"
      :on-success="uploadSuccess"
      :on-error="uploadError"
    >
        <div style="padding-top: 35px">
          <!-- <i class="el-icon-upload"></i> -->
          <img src="@/assets/uploadIcon.png" v-if="unitType !== ''" style="height: 25px; width: auto" />
          <img src="@/assets/uploadIcon1.png" v-if="unitType == ''" style="height: 25px; width: auto" />
          <div class="el-upload__text"><span :style="{color: unitType !== '' ? '#2A82E4' : '#606266'}">{{text}}</span></div>
          <div class="el-upload__text" style="font-size: 11px; margin-top: 10px">{{demand}}</div>
        </div>
    </el-upload>
    <!-- 原图、保留区 -->
    <div v-if="(original && original != '')||(showOriginal)" style="display: flex; justify-content: center; margin-bottom: 4px">
      <div class="selpage1">
        <div
          class="box"
          style="margin-left: 10px"
          @mouseenter="showUpload = true"
          @mouseleave="showUpload = false"
        >
          <div class="tuceng">
            <el-image class="prevtu" :src="showOriginal">
              <div slot="placeholder" class="image-slot">
                <i class="el-icon-loading" style="margin-top: 40px"></i>
              </div>
              <div slot="error" class="image-slot">
                <i class="el-icon-loading" style="margin-top: 40px"></i>
                <!-- <div style="font-size: 14px; color: #303313">加载中...</div> -->
              </div>
            </el-image>
            <div v-show="showUpload" class="prevtuyy"></div>
          </div>
          <div class="boxtext">{{ $t('yuantu') }}</div>
          <el-upload
            class="boxupload"
            v-show="showUpload"
            :data="{ username: username }"
            :action="actionUrl"
            :show-file-list="false"
            :before-upload="beforeUpload"
            :on-success="uploadSuccess">
              <el-button size="mini" icon="el-icon-upload2" style="padding: 5px 5px" v-if="!paidui">重新上传</el-button>
          </el-upload>
        </div>
        <div class="box" style="margin-right: 10px">
          <div class="tuceng">
            <el-image class="baoliutu" :src="cutdown">
              <div slot="placeholder" class="image-slot">
                <i class="el-icon-loading" style="margin-top: 40px"></i>
                <div style="font-size: 14px; color: #303313">自动扣图中...</div>
              </div>
              <div slot="error" class="image-slot">
                <i class="el-icon-loading" style="margin-top: 40px"></i>
                <div style="font-size: 14px; color: #303313">自动扣图中...</div>
              </div>
            </el-image>
          </div>
          <div class="boxtext">保留区</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from '@/services/api';

export default {
  props: ['name', 'actionUrl', 'username', 'unitType', 'originalUrl', 'cutdownUrl', 'text', 'demand','paidui'],
  data() {
    return {
      cutdown: this.cutdownUrl,
      original: this.originalUrl,
      showOriginal:this.originalUrl,
      showUpload: false,
      uploadFile:'',
      isUpload: false,
    }
  },
  watch: {
    originalUrl(value) {
      this.original = value;
      if(!this.isUpload){
        this.showOriginal = value;
      }
      this.isUpload = false;
    },
    cutdownUrl(value) {
      this.cutdown = value;
    },
    // 只有在抠图成功后此组件才回传值
    cutdown(value) {
      if (value) {
        this.callBack(this.original, this.cutdown);
      }
    }
  },
  created() {

  },
  mounted() {

  },
  methods: {
    triggerUpload() {
      const uploadRef = this.$refs.upload;
      if (uploadRef) {
        const input = uploadRef.$el.querySelector("input");
        if (input) {
          input.click();
        }
      }
    },
    getChildshowOriginal() {
      return this.showOriginal;
    },
    getChildCutdown() {
      return this.cutdown;
    },
    async uploadSuccess(response) {
      const mypath = response.filePath
      const mypath1 = mypath.replace('output', '')
      const epath = window._CONFIG['domianURL'] + mypath1

      const response2 = await apiClient.uploadImageToOSS(epath, '/huanyi/yuantu/');

      this.original = response2.url+window._CONFIG['dialogThumbnail'];
      if (this.name === '0' || this.name === '1' || this.name === '2') {
        this.koutu('23');
      }
      if (this.name === '人台图') {
        // this.koutu3('23');
        this.koutu3('23');
      }
      if (this.name === '真人图') {
        this.koutu5('23');
      }
      if (this.name === '商品图' || this.name === '鞋子上脚') {
        this.koutu4(epath);
      }
    },
    uploadError() {
      this.$message.error("图片上传失败");
      this.showOriginal='';
    },
    // AI换装的抠图
    async koutu(fuwuqi) {
      var that=this;
      try {
        var g1 = 'tops'
        if (this.unitType == '上装') {
          // 上装
          // g1 = 'tops'
          g1 = 'sweater,jacket';
        }
        if (this.unitType == '连衣裙') {
          // 连衣裙
          // g1 = 'tops,coat,skirt,pants'
          g1 = 'clothes'
        }
        if (this.unitType == '下装') {
          // 下装
          g1 = 'pants,skirt'
        }
        var headers = {
          'x-access-token': localStorage.token
        }
        var requestData = {
          "imageUrl": this.uploadFile,
          "fuwuqi":fuwuqi=='107'?'107_8188':'8288'
        }
        const responseComupload = await apiClient.post('/comupload8288', requestData, {headers});
        var qpath = responseComupload.data.name
        var requestData2 = {"img1": qpath, "prompt": g1,"fuwuqi":fuwuqi=='107'?'107_8188':'8288'}
        const responset2 = await apiClient.post('/compromptkoutu', requestData2, {headers});
        var prompt_id = responset2.data.prompt_id
        var t1 = setInterval(async function () {
          try {
            var tresponse1 = await apiClient.get('/comfytask8288', {
              params: {
                prompt_id: prompt_id,
                fuwuqi:fuwuqi=='107'?'107_8188':'8288'
              },
              headers: {
                'x-access-token': localStorage.token
              }
            });
            var data = tresponse1.data
            if(JSON.stringify(data) != '{}'){
              const specificId = prompt_id;
              const status1 = data[specificId]?.status
              if (status1.completed == true) {
                clearInterval(t1)
                const outputsForId = data[specificId]?.outputs;
                // const imagesForEight = outputsForId?.["20"]?.images;
                const imagesForEight = outputsForId?.["20"]?.images || outputsForId?.["30"]?.images;
                const filename = imagesForEight && imagesForEight.length > 0 ? imagesForEight[0].filename : null;
                var response2 = await apiClient.get('/downloadmyurl', {
                  params: {
                    url2: fuwuqi=='107'?window._CONFIG['baseUrl107_8188']+`view?filename=${filename}&subfolder=&type=temp`:window._CONFIG['baseUrl23_8288']+`view?filename=${filename}&subfolder=&type=temp` ,
                  }
                })
                var path2 = response2.data.outputPath
                var outpath = window._CONFIG['domianURL'] + path2.replace("./output", "")
                const response3 = await apiClient.uploadImageToOSS(outpath, '/huanyi/yuantu/');
                that.cutdown = response3.url+window._CONFIG['dialogThumbnail'];
              }else{
                throw new Error("抠图结果异常");
              }
            }
          } catch (e) {
            if(fuwuqi!='107'){
              clearInterval(t1)
              that.koutu('107');
              return false;
            }{
              that.$message.error("抠图失败");
              that.cutdown='';
              that.original='';
              clearInterval(t1)
              return false;
            }
          }
        }, 2000)
      } catch (e) {
        if(fuwuqi!='107'){
          this.koutu('107');
        }else{
          this.$message.error("抠图失败");
          this.cutdown='';
          this.original='';
        }
      }
    },
    // 人台图的抠图
    async koutu3(fuwuqi) {
      var that=this;
      try {
        var headers = {
          'x-access-token': localStorage.token
        }
        var requestData = {
          "imageUrl": this.uploadFile,
          "fuwuqi":fuwuqi=='107'?'107_8188':'8288'
        }
        const responseComupload = await apiClient.post('/comupload8288', requestData, {headers});
        var myurl1 = responseComupload.data.name
        // if (this.name === '人台图') this.original = myurl1
        var g1 = 'clothes,Shoes'
        var requestData2 = {"img1": myurl1, "prompt": g1,"fuwuqi":fuwuqi=='107'?'107_8188':'8288'}
        const responset2 = await apiClient.post('/compromptkoutu', requestData2, {headers});
        var prompt_id = responset2.data.prompt_id
        var t1 = setInterval(async function () {
          try {
            var tresponse1 = await apiClient.get('/comfytask8288', {
              params: {
                prompt_id: prompt_id,
                fuwuqi:fuwuqi=='107'?'107_8188':'8288'
              },
              headers: {
                'x-access-token': localStorage.token
              }
            });
            var data = tresponse1.data
            if(JSON.stringify(data) != '{}') {
              const specificId = prompt_id;
              const status1 = data[specificId]?.status
              if (status1.completed == true) {
                clearInterval(t1)
                const outputsForId = data[specificId]?.outputs;
                // const imagesForEight = outputsForId?.["21"]?.images;
                const imagesForEight = outputsForId?.["21"]?.images || outputsForId?.["30"]?.images;
                const filename = imagesForEight && imagesForEight.length > 0 ? imagesForEight[0].filename : null;
                //  alert(filename)
                var response2 = await apiClient.get('/downloadmyurl', {
                  params: {
                    url2: fuwuqi=='107'?window._CONFIG['baseUrl107_8188']+`view?filename=${filename}&subfolder=&type=temp`:window._CONFIG['baseUrl23_8288']+`view?filename=${filename}&subfolder=&type=temp`,
                  }
                })
                var outputPath = response2.data.outputPath
                var outpath = window._CONFIG['domianURL'] + outputPath.replace("./output", "");
                const response3 = await apiClient.uploadImageToOSS(outpath, '/huanyi/yuantu/');
                that.cutdown = response3.url+window._CONFIG['dialogThumbnail'];
              }else{
                throw new Error("抠图结果异常");
              }
            }
          } catch (e) {
            if(fuwuqi!='107'){
              clearInterval(t1)
              that.koutu3('107');
              return false;
            }else{
              that.$message.error("抠图失败");
              that.cutdown='';
              that.original='';
              clearInterval(t1)
              return false;
            }
          }
        }, 2000)
      }catch (e) {
        if(fuwuqi!='107'){
          this.koutu3('107');
        }else{
          this.$message.error("抠图失败");
          this.cutdown='';
          this.original='';
        }
      }
    },
    // 真人图抠图
    async koutu5(fuwuqi) {
      var that = this;
      try {
        var headers = {
          'x-access-token': localStorage.token
        }
        var requestData = {
          "imageUrl": this.uploadFile,
          "fuwuqi":fuwuqi=='107'?'107_8188':'8288'
        }
        const responseComupload = await apiClient.post('/comupload8288', requestData, {headers});
        var myurl1 = responseComupload.data.name
        var g1 = 'all'
        var requestData2 = {"img1": myurl1, "prompt": g1,"fuwuqi":fuwuqi=='107'?'107_8188':'8288'}
        const responset2 = await apiClient.post('/compromptkoutu', requestData2, {headers});
        var prompt_id = responset2.data.prompt_id
        var t1 = setInterval(async function () {
          try {
            var tresponse1 = await apiClient.get('/comfytask8288', {
              params: {
                prompt_id: prompt_id,
                fuwuqi:fuwuqi=='107'?'107_8188':'8288'
              },
              headers: {
                'x-access-token': localStorage.token
              }
            });
            var data = tresponse1.data
            if(JSON.stringify(data) != '{}'){
              const specificId = prompt_id;
              const status1 = data[specificId]?.status
              if (status1.completed == true) {
                clearInterval(t1)
                const outputsForId = data[specificId]?.outputs;
                // const imagesForEight = outputsForId?.["20"]?.images;
                const imagesForEight = outputsForId?.["20"]?.images || outputsForId?.["30"]?.images;
                const filename = imagesForEight && imagesForEight.length > 0 ? imagesForEight[0].filename : null;
                var response2 = await apiClient.get('/downloadmyurl', {
                  params: {
                    url2: fuwuqi=='107'?window._CONFIG['baseUrl107_8188']+`view?filename=${filename}&subfolder=&type=temp`:window._CONFIG['baseUrl23_8288']+`view?filename=${filename}&subfolder=&type=temp`,
                  }
                })
                var outputPath = response2.data.outputPath
                var outpath = window._CONFIG['domianURL'] + outputPath.replace("./output", "");
                const response3 = await apiClient.uploadImageToOSS(outpath, '/huanyi/yuantu/');
                that.cutdown = response3.url+window._CONFIG['dialogThumbnail'];
              }else{
                throw new Error("抠图结果异常");
              }
            }
          }catch (e) {
            if(fuwuqi!='107'){
              clearInterval(t1)
              that.koutu5('107');
              return false;
            }else{
              that.$message.error("抠图失败");
              that.cutdown='';
              that.original='';
              clearInterval(t1)
              return false;
            }
          }
        }, 2000)
      }catch (e) {
        if(fuwuqi!='107'){
          this.koutu5('107');
        }else{
          this.$message.error("抠图失败");
          this.cutdown='';
          this.original='';
        }
      }
    },

    async koutu3ali(epath) {
      this.isLoadingXiaoguo = true;
      this.loadingText = this.$t('aituxingloading');
      const response = await apiClient.get('/upload-image', {

        params: {
          imageUrl: epath
        },
        headers: {
          'x-access-token': localStorage.token
        }
      });
      var myurl1 = response.data.url
      this.base_image_url = myurl1
      // alert(this.base_image_url)
      var g1 = 'tops'
      const response1 = await apiClient.get('/segment-cloth1', {

        params: {
          imageURL: myurl1,
          clothClass: g1
        },
        headers: {
          'x-access-token': localStorage.token
        }
      });
      var outputPath = response1.data.outputPath
      var outpath = window._CONFIG['domianURL'] + outputPath.replace("./output", "")
      const response2 = await apiClient.get('/upload-image', {

        params: {
          imageUrl: outpath
        },
        headers: {
          'x-access-token': localStorage.token
        }
      });
      this.cutdown = response2.data.url
      this.isLoadingXiaoguo = false;

    },
    // 商品图的抠图
    async koutu4() {
      try {
      let url = new URL(this.original);
      url.search = '';
      let fUrl= url.toString();
      var g1 = 'tops'
      const response1 = await apiClient.get('/segment-cloth2', {
        params: {
          imageURL: fUrl,
          clothClass: g1
        },
        headers: {
          'x-access-token': localStorage.token
        }
      });
      var outputPath = response1.data.outputPath
      var outpath = window._CONFIG['domianURL'] + outputPath.replace("./output", "");
      const response2 = await apiClient.uploadImageToOSS(outpath, '/huanyi/yuantu/');
      this.cutdown = response2.url+window._CONFIG['dialogThumbnail'];
      }catch (e) {
        this.$message.error("抠图失败");
        this.cutdown='';
        this.original='';
        return false;
      }
    },
    beforeUpload(file) {
      this.isUpload = true;
      const isJPGorPNG = file.type === 'image/jpeg' || file.type === 'image/png';
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (this.name === '真人图') {
        const isValidSize = file.size / 1024 / 1024 < 5;
        if (!isJPGorPNG) {
          this.$message.error('图片大小在100KB~5MB之间，分辨率大于600*800，格式支持JPG、PNG  格式');
          return false;
        }

        if (!isValidSize) {
          this.$message.error('图片大小在100KB~5MB之间，分辨率大于600*800，格式支持JPG、PNG  格式');
          return false;
        }
      } else {
        if (!isJPGorPNG) {
          this.$message.error('图片大小小于5MB，分辨率大于400*400，格式支持JPG、PNG  格式');
          return false;
        }

        if (!isLt5M) {
          this.$message.error('图片大小小于5MB，分辨率大于400*400，格式支持JPG、PNG  格式');
          return false;
        }
      }

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => {
          const img = new Image();
          img.src = event.target.result;
          img.onload = () => {
            let minWidth, minHeight, sizeError;
            if (this.name === '真人图') {
              minWidth = 600;
              minHeight = 800;
              sizeError = '图片大小在100KB~15MB之间，分辨率大于600*800，格式支持JPG、PNG  格式';
            } else {
              minWidth = 400;
              minHeight = 400;
              sizeError = '图片大小小于5MB，分辨率大于400*400，格式支持JPG、PNG  格式';
            }
            if (img.width >= minWidth && img.height >= minHeight) {
              if (this.name === '0') {
                this.$emit('uploading', true);
              }
              this.cutdown = '';
              this.showOriginal = img.src;
              resolve(true);
            } else {
              this.$message.error(sizeError);
              reject(false);
            }
            this.uploadFile=event.target.result;
          };
        };
      });
    },
    // 回传
    callBack(epath, bpath) {
      this.$emit('fileSuccess', epath, bpath, this.name)
    }
  }
}
</script>

<style scoped>
.upload_ele /deep/.el-upload-dragger {
  width: 260px;
  height: 150px;
}
.selpage1 {
  width: 260px;
  height: 150px;
  border-radius: 6px;
  border: 1px dashed #d9d9d9;
  display: flex;
  justify-content: space-between;
}
.box {
  position: relative;
  display: flex;
  align-items: center;
}
.tuceng {
  position: relative;
}
.prevtu {
  width: 115px;
  height: 130px;
  border-radius: 10px;
  text-align: center;
  border: 1px dashed #d9d9d9;
}
.baoliutu {
  width: 115px;
  height: 130px;
  border-radius: 10px;
  text-align: center;
  border: 1px dashed #d9d9d9;
  background: url('@/assets/touming.png');
  background-size: contain;
}

.baoliutubg {
  background: url('@/assets/touming.png');
  background-size: contain;
}
.prevtu /deep/ .el-image__inner {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.baoliutu /deep/ .el-image__inner {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.boxupload {
  position: absolute;
  left: 18px;
  z-index: 9;
}
.prevtuyy {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  border-radius: 10px;
}
.boxtext {
  position: absolute;
  bottom: 12px;
  font-size: 14px;
  padding: 1px 5px;
  border-radius: 0 10px;
  text-align: center;
  color: #fff;
  background: rgba(0, 0, 0, 0.5)
}
</style>
