<template>
  <div class="changeface">
    <CustomerUpload
      name="真人图"
      text="上传真人模特图"
      demand="文件大小在100KB~15MB之间，分辨率大于600*800，图片比例为3:4效果最佳。"
      ref="uploadRef"
      :actionUrl="urlAction"
      :username="username"
      :originalUrl="originalUrl"
      :cutdownUrl="cutdownUrl"
      :paidui="paidui"
      @fileSuccess="chengong"
    />
    <div class="annotation">注：换模特、换背景支持同时更换，也可以单独更换。</div>
    <!-- 选择模特 -->
    <el-popover placement="right" popper-class="popverdiv" width="724" trigger="manual" v-model="imgMoreVisible1">
      <ImageMore v-if="imgMoreVisible1" @close="handleCloseMore" :selected="moteurl"
        @childSelected="childSelected" :imgList="imgList1" :title="moreTitle" @selectClothesType="selectClothesType" @scrollLoad="scrollLoad"
      />
      <div class="selectmodelclass" slot="reference" @click="openbtn('1')">
        <div class="iconcontain" :style="{ backgroundColor: moteurl === '' ? '#efefef' : 'transparent' }">
          <img v-if="moteurl == ''" src="@/assets/iconbg.png" />
          <img v-else :src="moteurl" style="height: auto; width: 100%; border-radius: 5px" />
        </div>
        <div class="xuanzetext">
          <span class="xzmt">选择模特</span>
          <div class="clear">
<!--            <el-button v-if="moteurl !== ''" type="text" @click.stop="handleClearMote('1')">清空</el-button>-->
            <el-link v-if="moteurl !== ''" type="primary" :underline="false" @click.stop="handleClearMote('1')" style="margin-right: 20px">清空</el-link>
            <i class="el-icon-arrow-right" style="font-size: 22px" />
          </div>
        </div>
      </div>
    </el-popover>
    <!-- 选择背景 -->
    <el-popover placement="right" popper-class="popverdiv" width="724" trigger="manual" v-model="imgMoreVisible2">
      <ImageMore v-if="imgMoreVisible2" @close="handleCloseMore" :selected="backgroundurl"
        @childSelected="childSelected" :imgList="imgList5" :title="moreTitle" @selectClothesType="selectClothesType" @scrollLoad="scrollLoad"
      />
      <div class="selectmodelclass" slot="reference" @click="openbtn('2')">
        <div class="iconcontain" :style="{ backgroundColor: backgroundurl === '' ? '#efefef' : 'transparent' }">
          <img v-if="backgroundurl == ''" src="@/assets/iconbg.png" />
          <img v-else :src="backgroundurl" style="height: auto; width: 100%; border-radius: 5px" />
        </div>
        <div class="xuanzetext">
          <span class="xzmt">选择场景</span>
          <div class="clear">
<!--            <el-button v-if="backgroundurl !== ''" type="text" @click.stop="handleClearMote('2')">清空</el-button>-->
            <el-link v-if="backgroundurl !== ''" type="primary" :underline="false" @click.stop="handleClearMote('2')" style="margin-right: 20px">清空</el-link>
            <i class="el-icon-arrow-right" style="font-size: 22px" />
          </div>
        </div>
      </div>
    </el-popover>
    <template>
      <div class="generate-footer">
        <div class="xiaohaodiv">
          <span>{{ $t('xiaohao') }}:4</span>
          <img src="@/assets/jinbi.png" class="jinbi_icon" />
        </div>
        <el-button slot="reference" class="generatebtn" type="primary" @click="AIGenerate">{{ $t('lijishengchen') }}</el-button>
      </div>
    </template>
  </div>
</template>

<script>
import ImageMore from './ImageMore.vue';
import CustomerUpload from './CustomerUpload.vue';
import apiClient from '@/services/api';

export default {
  props: ['imgList3', 'imgList4', 'imgList5', 'historyBack','paidui'],
  components: { CustomerUpload, ImageMore },
  data() {
    return {
      urlAction: window._CONFIG['domianURL']+'upload1',
      username: localStorage.username,
      originalUrl: '',
      cutdownUrl: '',
      page: 1,
      limit: 15,
      imgList1: [
        [],
      ],
      cjImgList: [],
      imgMoreVisible1: false,
      imgMoreVisible2: false,
      moreTitle: {
        bigTitle: '',
        remark: '',
        name: []
      },
      moteurl: '',
      backgroundurl: '',
      currentGroup: '',
    }
  },
  watch: {
    historyBack(val) {
      if (!val) return;
      this.originalUrl = val.img1;
      this.cutdownUrl = val.img1k;
      this.moteurl = val.models || '';
      this.backgroundurl = val.changjing || '';
    }
  },
  created() {},
  mounted() {
    this.loadingdata('全部');
  },
  methods: {
    async AIGenerate() {
      const childValue1 = this.$refs.uploadRef.getChildshowOriginal();
      if (childValue1 == '') {
        this.$message.error(this.$t('qingshangchuanzhenrentu'));
        return false
      }
      const childValue2 = this.$refs.uploadRef.getChildCutdown();
      if (childValue2 == '') {
        this.$message.error('请等待抠图完成');
        return false
      }
      if (this.moteurl === '' && this.backgroundurl === '') {
        this.$message.error('请选择更换的模特或背景');
        return false
      }
      const obj1 = {
        type: 'zhenren',
        img1: this.originalUrl,
        models: this.moteurl,
        changjing: this.backgroundurl,
      }
      // 此方法待修改
      const response = await apiClient.post(`/checkGenerating`, obj1);
      if (response.data.data.length !== 0) {
        this.$message.warning('任务正在生成中，请稍后');
        return;
      }
      const obj = {
        originalUrl: this.originalUrl,
        cutdownUrl: this.cutdownUrl,
        saveMote: this.moteurl,
        saveChangjing: this.backgroundurl,
      };
      if (this.moteurl !== '' && this.backgroundurl !== '') {
        this.$emit('AIGenerate', obj);
      }
      if (this.moteurl !== '' && this.backgroundurl === '') {
        this.$emit('AIGenerate1', obj);
      }
      if (this.backgroundurl !== '' && this.moteurl === '') {
        this.$emit('AIGenerate2', obj);
      }
    },
    handleClearMote(val) {
      if (val === '1') {
        this.moteurl = '';
      }
      if (val === '2') {
        this.backgroundurl = '';
      }
    },
    scrollLoad() {
      if (this.currentGroup === '1') {
        this.loadingdata('全部');
      }
      if (this.currentGroup === '2') {

      }
    },
    childSelected(value) {
      if (this.currentGroup === '1') {
        this.moteurl = value;
      }
      if (this.currentGroup === '2') {
        this.backgroundurl = value;
      }
    },
    selectClothesType(value) {
      if (this.currentGroup === '1') {
        this.loadingdata(value);
      }
    },
    handleCloseMore() {
      this.imgMoreVisible1 = false;
      this.imgMoreVisible2 = false;
    },
    chengong(epath, bpath) {
      this.originalUrl = epath;
      this.cutdownUrl = bpath;
    },
    openbtn(group) {
      this.currentGroup = group;
      this.handleCloseMore();
      if(this.paidui){
        this.$message.warning('排队中~请等待2秒再继续操作');
        return  false;
      }
      if (group === '1') {
        this.moreTitle = {
          bigTitle: this.$t('suijimote'),
          remark: this.$t('suijimoteshuoming'),
          name: [''],
        }
        this.imgMoreVisible1 = true;
      }
      if (group === '2') {
        this.moreTitle = {
          bigTitle: this.$t('changjing'),
          remark: '',
          name: [''],
        }
        this.imgMoreVisible2 = true;
      }
    },
    async loadingdata(value) {
      const response = await apiClient.get(`/getmodels55`, {
        params: {
          page: this.page,
          limit: this.limit,
          continent: '',
          gender: '',
          leixing: '青年',
          dsex: '全部',
          darea: '全部',
          fushi: value,
        },
        headers: {
          'x-access-token': localStorage.token
        },
      });
      const mylist = response.data.models;
      const tempArr = [];
      for (let i = 0; i < mylist.length; i++) {
        let item = mylist[i];
        let path = window._CONFIG['domianURL'];
        let contentpaht = item.image_path;
        let path1 ='';
        if(contentpaht.indexOf('http')!=-1){
          path1 = contentpaht+window._CONFIG['dialogThumbnail'];
          item.image_path = contentpaht;
        }else{
          contentpaht = contentpaht.replace("./output", "");
          path1 = path + contentpaht;
          item.image_path = path1;
        }
        tempArr.push(path1);
      }
      let isDuplicate = tempArr.every(item => this.imgList1[0].includes(item));

      if (!isDuplicate) {
        this.$set(this.imgList1, 0, [...this.imgList1[0], ...tempArr]);
      }
      this.page += 1;
    },
    //转化缩略图
    convertThumbnail(arr,config){
      const thumbnailSuffix=window._CONFIG[config] || '';
      const newArr=arr.map(item=>{
        return item+ thumbnailSuffix
      })
      return newArr;
    },
  }
}
</script>

<style scoped>
.annotation {
  font-size: 12px;
  color: #FFC300;
  padding: 10px 0 0 2px;
}
.selectmodelclass {
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  border-radius: 5px;
  cursor: pointer;
}
.selectmodelclass:hover {
  background: rgba(101, 126, 185, 0.08);
}
.iconcontain {
  height: 100px;
  width: 80px;
  background-color: #efefef;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;
}
.xuanzetext {
  height: 100%;
  width: calc(100% - 80px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #303133;
}
.xzmt {
  margin-left: 30px;
}
.clear {
  display: flex;
  align-items: center;
}
.generate-footer {
  width: 290px;
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  height: 100px;
}
.xiaohaodiv {
  display: flex;
  align-items: center;
}
.jinbi_icon {
  width: 33px;
  height: auto;
  margin-left: 10px;
}
.generatebtn {
  width: 160px;
  height: 48px;
  border-radius: 10px;
  background: linear-gradient(0deg, #A835F2, #3E93F0);
  color: #fff;
}
.generatebtn:hover {
  background: linear-gradient(0deg, #A835F2, #3E93F0);
  box-shadow: 0 0 0 1000px rgba(255, 255, 255, 0.2) inset !important;
}
</style>
