<template>
  <div class="motexunlian">
    <div class="moteinput">
      <span>{{ $t("diqu") }}：</span>
      <div>
        <el-select v-model="value" :placeholder="$t('qingxuanze')" :disabled="paidui" clearable>
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
    </div>
    <div class="moteinput">
      <span>{{ $t("xingbie") }}：</span>
      <div>
        <el-select v-model="value1" :placeholder="$t('qingxuanze')" :disabled="paidui" clearable>
          <el-option
            v-for="item in options1"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
    </div>
    <div class="moteinput">
      <span>{{ $t("nianling") }}：</span>
      <div>
        <el-select v-model="value2" :placeholder="$t('qingxuanze')" :disabled="paidui" clearable>
          <el-option
            v-for="item in options2"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
    </div>
    <div class="moteinput">
      <span>{{ $t("xiaorong") }}：</span>
      <div>
        <el-select v-model="value3" :placeholder="$t('qingxuanze')" :disabled="paidui" clearable>
          <el-option
            v-for="item in options3"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
    </div>
    <div class="generatenum">
      <el-input
        v-model="prompts"
        type="textarea"
        :rows="10"
        :maxlength="500"
        :disabled="paidui"
        :show-word-limit="true"
        :placeholder="$t('qingshurutishici')"
      ></el-input>
    </div>
    <div class="generate-footer">
      <div class="xiaohaodiv">
        <span>{{ $t('xiaohao') }}:2</span>
        <img src="@/assets/jinbi.png" class="jinbi_icon" />
      </div>
      <el-button slot="reference" class="generatebtn" type="primary" @click="AIGenerate1">{{ $t('lijishengchen') }}</el-button>
    </div>
  </div>
</template>

<script>
import apiClient from '@/services/api';

export default {
  props: ['historyBack', 'paidui'],
  data() {
    return {
      value: '',
      value1: '',
      value2: '',
      value3: '',
      prompts: '',
      options: [
        { value: "Africa", label: this.$t("feizhou") },
        { value: "Asia", label: this.$t("yazhou") },
        { value: "America", label: this.$t("meizhou") },
        { value: "Europe", label: this.$t("ouzhou") },
        { value: "Oceania", label: this.$t("dayangzhou") },
      ],
      options1: [
        { value: "female", label: this.$t("nv") },
        { value: "male", label: this.$t("nan") },
      ],
      options2: [
        { value: "0-2", label: "0-2" },
        { value: "3-5", label: "3-5" },
        { value: "6-10", label: "6-10" },
        { value: "11-15", label: "11-15" },
        { value: "16-18", label: "16-18" },
        { value: "19-25", label: "19-25" },
        { value: "26-35", label: "26-35" },
        { value: "36-45", label: "36-45" },
        { value: "46-55", label: "46-55" },
        { value: "56-65", label: "56-65" },
        { value: "66+", label: "66+" },
      ],
      options3: [
        { value: "大笑", label: this.$t("daxiao") },
        { value: "微笑", label: this.$t("weixiao") },
        { value: "害羞", label: this.$t("haixiu") },
        { value: "发怒", label: this.$t("fanu") },
        { value: "伤心", label: this.$t("shangxin") },

        { value: "调皮", label: this.$t("tiaopi") },
        { value: "眨眼", label: this.$t("zhayan") },
        { value: "无表情", label: this.$t("wubiaoqing") },
        { value: "认真", label: this.$t("renzhen") },
        { value: "腼腆", label: this.$t("miantian") },
        { value: "闭眼", label: this.$t("biyan") },
        { value: "哭", label: this.$t("ku1") },
        { value: "尴尬", label: this.$t("ganga") },
        { value: "生气", label: this.$t("shengqi") },
        { value: "恐惧", label: this.$t("kongju") },

        { value: "厌恶", label: this.$t("yanwu") },
        { value: "悠闲", label: this.$t("youxian") },
        { value: "难过", label: this.$t("nanguo") },
        { value: "惊讶", label: this.$t("jingya") },
      ],
    };
  },
  watch: {
    historyBack(val) {
      if (!val) return;
      this.value = val.diqu;
      this.value1 = val.xingbie;
      this.value2 = val.nianling;
      this.value3 = val.xiaorong;
      this.prompts = val.prompt;
    }
  },
  created() {},
  mounted() {},
  methods: {
    async AIGenerate1() {
      if (this.value.length == 0) {
        this.$message.warning(this.$t('qingxuanzediqu'));
        return false
      }
      if (this.value1.length == 0) {
        this.$message.warning(this.$t('qingxuanzexingbie'));
        return false
      }
      if (this.value2.length == 0) {
        this.$message.warning(this.$t('qingxuanzenianling'));
        return false
      }
      if (this.value3.length == 0) {
        this.$message.warning(this.$t('qingxuanzexiaorong'));
        return false
      }
      if (this.prompts.length == 0) {
        this.$message.warning(this.$t('qingshurutishici2'));
        return false
      }
      const obj1 = {
        type: 'motexunlian',
        diqu: this.value,
        xingbie: this.value1,
        nianling: this.value2,
        xiaorong: this.value3,
        prompt: this.prompts,
      }
      const response = await apiClient.post(`/checkGenerating`, obj1);
      if (response.data.data.length !== 0) {
        this.$message.warning('任务正在生成中，请稍后');
        return;
      }
      const obj = {
        diqu: this.value,
        xingbie: this.value1,
        nianling: this.value2,
        xiaorong: this.value3,
        prompt: this.prompts,
      };
      this.$emit('AIGenerate1', obj)
    },
  },
};
</script>

<style scoped>
.motexunlian {
  text-align: center;
}
.moteinput {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}
.generatenum {
  margin-top: 10px;
  margin-bottom: 100px;
}
.generatenum /deep/ .el-input__inner {
  text-align: center;
}
.generate-footer {
  width: 290px;
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  height: 100px;
}
.xiaohaodiv {
  display: flex;
  align-items: center;
}
.jinbi_icon {
  width: 33px;
  height: auto;
  margin-left: 10px;
}
.generatebtn {
  width: 160px;
  height: 48px;
  border-radius: 10px;
  /* background-color: #2352d8; */
  background: linear-gradient(0deg, #A835F2, #3E93F0);
  color: #fff;
}
.generatebtn:hover {
  background: linear-gradient(0deg, #A835F2, #3E93F0);
  box-shadow: 0 0 0 1000px rgba(255, 255, 255, 0.2) inset !important;
}
</style>
