<template>
  <div>
    <el-upload
      class="upload_ele"
      v-if="!base64Image || base64Image == ''"
      :action="urlAction"
      :data="{ username: username }"
      drag
      :show-file-list="false"
      :before-upload="beforeUpload"
      :on-success="uploadSuccess"
    >
      <div style="padding-top: 35px">
        <img src="@/assets/uploadIcon.png" style="height: 25px; width: auto" />
        <div class="el-upload__text"><em>{{text}}</em></div>
        <div class="el-upload__text" style="font-size: 11px; margin-top: 10px" v-if="demand">{{demand}}</div>
        <div class="el-upload__text" style="font-size: 11px; margin-top: 10px" v-else>要求:上传服装正面平铺图，平整无遮挡、无折叠</div>
      </div>
    </el-upload>
    <div v-if="base64Image && base64Image != ''" style="display: flex; justify-content: center">
      <div class="selpage1">
        <div
          class="box"
          @mouseenter="showUpload = true"
          @mouseleave="showUpload = false"
        >
          <div class="tuceng">
            <el-image class="prevtu" :src="base64Image">
              <div slot="error" class="image-slot">
                <i class="el-icon-loading" style="margin-top: 40px"></i>
                <!-- <div style="font-size: 14px; color: #303313">加载中...</div> -->
              </div>
            </el-image>
            <div v-show="showUpload" class="prevtuyy"></div>
          </div>
          <el-upload
            class="boxupload"
            v-show="showUpload"
            :action="urlAction"
            :data="{ username: username }"
            :show-file-list="false"
            :before-upload="beforeUpload"
            :on-success="uploadSuccess"
          >
              <el-button size="mini" icon="el-icon-upload2" style="padding: 5px 5px" v-if="!paidui">重新上传</el-button>
          </el-upload>
        </div>
      </div>
    </div>
    <!-- <img :src="base64Image" class="upload_img" v-if="base64Image != ''" style="max-width: 100%; max-height: 100%; margin-top: 10px;" /> -->
  </div>
</template>

<script>
import apiClient from '@/services/api';
export default {
  props: ['text','demand', 'originalUrl', 'paidui'],
  data() {
    return {
      urlAction: window._CONFIG['domianURL']+'upload1',
      base64Image: this.originalUrl,
      showUpload: false,
      username: localStorage.username,
    }
  },
  watch: {
    originalUrl(value) {
      this.base64Image = value;
    },
  },
  created() {},
  mounted() {},
  methods: {
   async uploadSuccess(response, file, fileList) {
      const mypath = response.filePath
      const mypath1 = mypath.replace('output', '')
      const epath = window._CONFIG['domianURL'] + mypath1

     const response2 = await apiClient.uploadImageToOSS(epath);

      this.base64Image = response2.url+window._CONFIG['dialogThumbnail'];
      this.$emit('uploadChange', response2.url+window._CONFIG['dialogThumbnail']);
      // this.convertToBase64(file.raw).then(base64 => {
      //   this.base64Image = base64;
      //   this.$emit('uploadChange', base64);
      // });
    },
    convertToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    beforeUpload(file) {
      const isJPGorPNG = file.type === 'image/jpeg' || file.type === 'image/png';
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isJPGorPNG) {
        this.$message.error('图片大小小于5MB，分辨率大于400*400，格式支持JPG、PNG  格式');
        return false;
      }

      if (!isLt5M) {
        this.$message.error('图片大小小于5MB，分辨率大于400*400，格式支持JPG、PNG  格式');
        return false;
      }

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => {
          const img = new Image();
          img.src = event.target.result;
          img.onload = () => {
            if (img.width >= 400 && img.height >= 400) {
              resolve(true);
            } else {
              this.$message.error('图片大小小于5MB，分辨率大于400*400，格式支持JPG、PNG  格式');
              reject(false);
            }
          };
        };
      });
    }
  },
}
</script>

<style scoped>
.upload_ele /deep/.el-upload-dragger {
  width: 260px;
  height: 150px;
}
.selpage1 {
  width: 260px;
  height: 150px;
  border-radius: 6px;
  border: 1px dashed #d9d9d9;
  display: flex;
  justify-content: center;
  padding: 10px;
}
.box {
  position: relative;
  display: flex;
  align-items: center;
}
.tuceng {
  position: relative;
  height: 100%;
  width: 100%;
}
.prevtu {
  height: 150px;
  width: 150px;
  border-radius: 10px;
  border: 1px dashed #d9d9d9;
}
.prevtu /deep/ .el-image__inner {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.prevtuyy {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  border-radius: 10px;
}
.boxupload {
  position: absolute;
  left: 38px;
  z-index: 9;
}
</style>
