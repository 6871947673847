<template>
  <div class="shangpintu">
    <CustomerUpload
      name="商品图"
      text="上传商品图"
      demand="要求:上传商品正面/侧面图片"
      ref="uploadRef"
      :actionUrl="urlAction"
      :username="username"
      :originalUrl="originalUrl"
      :cutdownUrl="cutdownUrl"
      :paidui="paidui"
      @fileSuccess="chengong"
    />
    <!-- 场景 -->
    <div style="text-align: left;">
      <div style="display: flex; align-items: center; margin: 10px 0;">
        <span style="font-size: 14px;">{{ $t('changjing') }}</span>
        <span style="font-size: 12px; color: gray;">{{ $t('gudingyusuiji') }}</span>
      </div>
      <!-- 固定场景 -->
      <div>
        <span style="font-size: 12px; color: gray;">{{ $t('gudingchangjing') }}</span>
        <el-row :gutter="15">
          <template v-for="(item, index) in cjImgList1[0]">
            <el-col :span="8" v-if="index < 5" :key="index" class="imgcol">
              <div class="image-wrapper" @click="handleImage('6', item.url)">
                <el-image :src="item.url" :class="{ 'selected': saveChangjing === item.url }" class="image">
                </el-image>
                <div class="imgtext">{{ item.name }}</div>
              </div>
            </el-col>
          </template>
          <el-col :span="8" class="imgcol">
            <el-popover placement="right" popper-class="popverdiv" width="724" trigger="manual" v-model="imgMoreVisible6">
              <ImageMore v-if="imgMoreVisible6" @close="handleCloseMore" :selected="currentSelected"
                @childSelected="childSelected" :imgList="cjImgList1" :title="moreTitle" />
              <div class="more" slot="reference" @click="handleMore('6')">
                <el-image class="image" :src="cjImgList1[0][5].url" style="filter: blur(5px);"></el-image>
                <div class="more-text">
                  <span>{{ $t('gengduo') }}</span>
                  <i class="el-icon-arrow-right" />
                </div>
              </div>
            </el-popover>
          </el-col>
        </el-row>
      </div>
      <!-- 随机场景 -->
      <div>
        <div style="font-size: 12px; color: gray; margin: 8px 0 6px">{{ $t('suijichangjing') }}</div>
        <el-row :gutter="15">
          <template v-for="(item, index) in cjImgList2[0]">
            <el-col :span="8" v-if="index < 5" :key="index" class="imgcol">
              <div class="image-wrapper" @click="handleImage('7', item.url)">
                <el-image :src="item.url" :class="{ 'selected': saveChangjing === item.url }" class="image">
                </el-image>
                <div class="imgtext">{{ item.name }}</div>
              </div>
            </el-col>
          </template>
          <el-col :span="8" class="imgcol">
            <el-popover placement="right" popper-class="popverdiv" width="724" trigger="manual" v-model="imgMoreVisible7">
              <ImageMore v-if="imgMoreVisible7" @close="handleCloseMore" :selected="currentSelected"
                @childSelected="childSelected" :imgList="cjImgList2" :title="moreTitle" />
              <div class="more" slot="reference" @click="handleMore('7')">
                <el-image class="image" :src="cjImgList2[0][5].url" style="filter: blur(5px);"></el-image>
                <div class="more-text">
                  <span>{{ $t('gengduo') }}</span>
                  <i class="el-icon-arrow-right" />
                </div>
              </div>
            </el-popover>
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- 比例 -->
    <div style="text-align: left; margin-bottom: 10px">
      <div style="display: flex; align-items: center; margin: 10px 0;">
        <span style="font-size: 14px;">{{ $t('bili') }}</span>
      </div>
      <div>
        <el-row :gutter="15">
          <el-radio-group v-model="radio" size="small">
            <el-col :span="8">
              <el-button plain class="scale" :class="{ 'radioSelected': radio === $t('yuanshi') }"
                @click="selectRadio($t('yuanshi'))">{{ $t('yuanshi') }}</el-button>
            </el-col>
            <el-col :span="8">
              <el-button plain class="scale" :class="{ 'radioSelected': radio === '1:1' }"
                @click="selectRadio('1:1')">1:1</el-button>
            </el-col>
            <el-col :span="8">
              <el-button plain class="scale" :class="{ 'radioSelected': radio === '3:4' }"
                @click="selectRadio('3:4')">3:4</el-button>
            </el-col>
            <el-col :span="8">
              <el-button plain class="scale" :class="{ 'radioSelected': radio === '16:9' }"
                @click="selectRadio('16:9')">16:9</el-button>
            </el-col>
            <el-col :span="8">
              <el-button plain class="scale" :class="{ 'radioSelected': radio === '9:16' }"
                @click="selectRadio('9:16')">9:16</el-button>
            </el-col>
          </el-radio-group>
        </el-row>
      </div>
    </div>
    <div class="generatenum">
      <el-input v-model="prompts" type="textarea" :rows="2" :disabled="paidui" style="width:260px" :placeholder="$t('qingshurutishici')"></el-input>
    </div>
    <template>
      <div class="generate-footer">
        <div class="xiaohaodiv">
          <span>{{ $t('xiaohao') }}:2</span>
          <img src="@/assets/jinbi.png" class="jinbi_icon" />
        </div>
        <el-button slot="reference" class="generatebtn" type="primary" @click="AIGenerate">{{ $t('lijishengchen') }}</el-button>
      </div>
    </template>
  </div>
</template>

<script>
import ImageMore from './ImageMore.vue';
import CustomerUpload from './CustomerUpload.vue';
import apiClient from '@/services/api';

export default {
  props: ['cjImgList1', 'cjImgList2', 'historyBack', 'paidui'],
  components: { CustomerUpload, ImageMore },
  data() {
    return {
      urlAction: window._CONFIG['domianURL']+'upload1',
      username: localStorage.username,
      originalUrl: '',
      cutdownUrl: '',
      currentSelected: '', // 给子组件传值目前选择的图片
      moreTitle: {
        bigTitle: '',
        remark: '',
        name: []
      },
      imgMoreVisible6: false,
      imgMoreVisible7: false,
      saveChangjing: '',
      prompts: '',
      radio: this.$t('yuanshi'),
    }
  },
  watch: {
    historyBack(val) {
      if (!val) return;
      this.originalUrl = val.img1;
      this.cutdownUrl = val.img1k;
      this.saveMote = val.models;
      this.saveFaxing = val.faxing;
      this.saveBiaoqing = val.biaoqing;
      this.saveChangjing = val.changjing;
      this.radio = val.bili?val.bili:this.$t('yuanshi');
      this.prompts = val.prompt;
    }
  },
  created() {},
  mounted() {},
  methods: {
    async AIGenerate() {
      const childValue1 = this.$refs.uploadRef.getChildshowOriginal();
      if (childValue1 == '') {
        this.$message.error('请上传商品图');
        return false
      }
      const childValue2 = this.$refs.uploadRef.getChildCutdown();
      if (childValue2 == '') {
        this.$message.error('请等待抠图完成');
        return false
      }
      if (this.saveChangjing == '') {
        this.$message.error(this.$t('changjingbuweikong'));
        return false
      }
      if (!this.prompts) {
        this.$message.error(this.$t('tishicibuweikong'));
        return false
      }
      const obj1 = {
        type: 'shangpin',
        img1: this.originalUrl,
        changjing: this.saveChangjing,
        bili: this.radio,
      }
      const response = await apiClient.post(`/checkGenerating`, obj1);
      if (response.data.data.length !== 0) {
        this.$message.warning('任务正在生成中，请稍后');
        return;
      }
      const obj = {
        img1: this.originalUrl,
        img1k: this.cutdownUrl,
        changjing: this.saveChangjing,
        prompt: this.prompts,
        bili: this.radio
      };
      this.$emit('AIGenerate', obj);
    },
    chengong(epath, bpath) {
      this.originalUrl = epath;
      this.cutdownUrl = bpath;
    },
    selectRadio(value) {
      if(this.paidui){
        this.$message.warning('排队中~请等待2秒再继续操作');
        return  false;
      }
      this.radio = value;
    },
    handleImage(group, item) {
      if(this.paidui){
        this.$message.warning('排队中~请等待2秒再继续操作');
        return  false;
      }
      this.currentSelected = item;
      if (group === '1' || group === '2') {
        this.saveMote = item;
      }
      if (group === '3') {
        this.saveFaxing = item;
      }
      if (group === '4') {
        this.saveBiaoqing = item;
      }
      if (group === '5' || group === '6' || group === '7') {
        this.saveChangjing = item;
      }
    },
    handleMore(group) {
      this.currentGroup = group;
      // 处理依次点击更多时不能关闭的BUG
      this.closeAllMore();
      if (group === '1') {
        this.imgMoreVisible = true;
        this.currentSelected = this.saveMote;
        this.moreTitle = {
          bigTitle: this.$t('suijimote'),
          remark: this.$t('suijimoteshuoming'),
          name: [''],
          qvisible: true,
        }
      }
      if (group === '2') {
        this.imgMoreVisible2 = true;
        this.currentSelected = this.saveMote;
        this.moreTitle = {
          bigTitle: this.$t('gudingmote'),
          remark: this.$t('gudingmoteshuoming'),
          name: ['青年'],
          qvisible: false,
        }
      }
      if (group === '3') {
        this.imgMoreVisible3 = true;
        this.currentSelected = this.saveFaxing;
        this.moreTitle = {
          bigTitle: this.$t('faxing'),
          remark: '',
          name: [''],
          qvisible: false,
        }
      }
      if (group === '4') {
        this.imgMoreVisible4 = true;
        this.currentSelected = this.saveBiaoqing;
        this.moreTitle = {
          bigTitle: this.$t('biaoqing'),
          remark: '',
          name: [],
          qvisible: false,
        }
      }
      if (group === '5') {
        this.imgMoreVisible5 = true;
        this.currentSelected = this.saveChangjing;
        this.moreTitle = {
          bigTitle: this.$t('changjing'),
          remark: '',
          name: [],
          qvisible: false,
        }
      }
      // 商品图场景
      if (group === '6') {
        this.imgMoreVisible6 = true;
        this.currentSelected = this.saveChangjing;
        this.moreTitle = {
          bigTitle: this.$t('gudingchangjing'),
          remark: this.$t('gudingchangjingshuoming'),
          name: [this.$t('ziran')],
          qvisible: false,
        }
      }
      if (group === '7') {
        this.imgMoreVisible7 = true;
        this.currentSelected = this.saveChangjing;
        this.moreTitle = {
          bigTitle: this.$t('suijichangjing'),
          remark: this.$t('suijichangjingshuoming'),
          name: [this.$t('ziran')],
          qvisible: false
        }
      }
    },
    closeAllMore() {
      this.imgMoreVisible = false;
      this.imgMoreVisible2 = false;
      this.imgMoreVisible3 = false;
      this.imgMoreVisible4 = false;
      this.imgMoreVisible5 = false;
      this.imgMoreVisible6 = false;
      this.imgMoreVisible7 = false;
    },
    handleCloseMore() {
      if (this.currentGroup === '1') {
        this.imgMoreVisible = false;
      }
      if (this.currentGroup === '2') {
        this.imgMoreVisible2 = false;
      }
      if (this.currentGroup === '3') {
        this.imgMoreVisible3 = false;
      }
      if (this.currentGroup === '4') {
        this.imgMoreVisible4 = false;
      }
      if (this.currentGroup === '5') {
        this.imgMoreVisible5 = false;
      }
      if (this.currentGroup === '6') {
        this.imgMoreVisible6 = false;
      }
      if (this.currentGroup === '7') {
        this.imgMoreVisible7 = false;
      }
    },
    childSelected(value) {
      this.currentSelected = value;
      if (this.currentGroup === '1') {
        this.saveMote = value;
      }
      if (this.currentGroup === '2') {
        this.saveMote = value;
        var eindex = 0
        if (this.saveMote.indexOf('p810525') > -1) {
          eindex = 0
        }
        if (this.saveMote.indexOf('p810527') > -1) {
          eindex = 1
        }
        if (this.saveMote.indexOf('p810528') > -1) {
          eindex = 2
        }
        if (this.saveMote.indexOf('p810526') > -1) {
          eindex = 3
        }
        if (this.saveMote.indexOf('p810522') > -1) {
          eindex = 4
        }
        if (this.saveMote.indexOf('p810524') > -1) {
          eindex = 5

        }
      }
      if (this.currentGroup === '3') {
        this.saveFaxing = value;
      }
      if (this.currentGroup === '4') {
        this.saveBiaoqing = value;
        var eindex = 0
        if (this.saveBiaoqing.indexOf('biaoqing1') > -1) {
          eindex = 0
        }
        if (this.saveBiaoqing.indexOf('biaoqing2') > -1) {
          eindex = 1
        }
        if (this.saveBiaoqing.indexOf('biaoqing3') > -1) {
          eindex = 2
        }
        if (this.saveBiaoqing.indexOf('biaoqing4') > -1) {
          eindex = 3
        }
        if (this.saveBiaoqing.indexOf('biaoqing5') > -1) {
          eindex = 4
        }
        if (this.saveBiaoqing.indexOf('biaoqing6') > -1) {
          eindex = 5
        }
      }
      if (this.currentGroup === '5' || this.currentGroup === '6' || this.currentGroup === '7') {
        this.saveChangjing = value;
        var eindex = 0
        if (this.saveChangjing.indexOf('changjing1') > -1) {
          eindex = 0
        }
        if (this.saveChangjing.indexOf('changjing2') > -1) {
          eindex = 1
        }
        if (this.saveChangjing.indexOf('changjing3') > -1) {
          eindex = 2
        }
        if (this.saveChangjing.indexOf('changjing4') > -1) {
          eindex = 3
        }
        if (this.saveChangjing.indexOf('changjing5') > -1) {
          eindex = 4
        }
        if (this.saveChangjing.indexOf('changjing6') > -1) {
          eindex = 5
        }
        if (this.saveChangjing.indexOf('changjing7') > -1) {
          eindex = 6
        }
      }
    },
  },
}
</script>

<style scoped>
.imgcol {
  margin-bottom: 5px;
}
.selected {
  border: 2px solid #2352d8;
  padding: 1px;
}
.image {
  width: 87px;
  height: 87px;
  box-sizing: border-box;
  border-radius: 4px;
}
.image /deep/.el-image__inner {
  width: 100%;
  height: auto;
}
.image :hover {
  cursor: pointer;
}
.more {
  position: relative;
  width: 87px;
  height: 87px;
}
.more-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
}
.image-wrapper {
  position: relative;
  display: inline-block;
  width: 87px;
  height: 87px;
  cursor: pointer;
}
.imgtext {
  width: 100%;
  position: absolute;
  text-align: center;
  bottom: 0;
  left: 0;
  color: #fff;
  font-weight: bold;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 0 0 4px 4px;
  font-size: 14px;
  padding: 2px 0;
}
.scale {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 100%;
  border-radius: 0;
  border: none;
}
.radioSelected {
  border: 1px solid #2352d8;
}
.generatenum {
  margin-top: 10px;
  margin-bottom: 100px;
}
.generatenum /deep/ .el-input__inner {
  text-align: center;
}
.generate-footer {
  width: 290px;
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  height: 100px;
}
.xiaohaodiv {
  display: flex;
  align-items: center;
}
.jinbi_icon {
  width: 33px;
  height: auto;
  margin-left: 10px;
}
.generatebtn {
  width: 160px;
  height: 48px;
  border-radius: 10px;
  background: linear-gradient(0deg, #A835F2, #3E93F0);
  color: #fff;
}
.generatebtn:hover {
  background: linear-gradient(0deg, #A835F2, #3E93F0);
  box-shadow: 0 0 0 1000px rgba(255, 255, 255, 0.2) inset !important;
}
</style>
